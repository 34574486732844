import { sum } from 'lodash';

import { CurrencySymbolsEnum } from '@amal-ia/ext/iso-4217';
import { type CurrencyValue } from '@amal-ia/kernel/monetary/types';
import { type UserComputed } from '@amal-ia/tenants/users/shared/types';

export interface DataLeaderboard {
  stats: Record<string, CurrencyValue>;
  user?: UserComputed;
}

export interface DataBenchmarkByRule {
  stats: Record<string, CurrencyValue>;
  label?: string;
}

export const getUserAmounts = (dataLeaderBoard: DataBenchmarkByRule | DataLeaderboard): number[] =>
  Object.values(dataLeaderBoard.stats).map((s) => s.value);

export const sumTotalForUser = (dataLeaderBoard: DataBenchmarkByRule | DataLeaderboard): number =>
  sum(getUserAmounts(dataLeaderBoard));

export const getUserCurrency = (dataBenchmarkByRule: DataBenchmarkByRule | DataLeaderboard): CurrencySymbolsEnum =>
  Object.values(dataBenchmarkByRule.stats)?.[0]?.symbol || CurrencySymbolsEnum.EUR;
