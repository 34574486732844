import { http } from '@amal-ia/frontend/kernel/http';
import { type PaymentCategory, type PaymentContract } from '@amal-ia/lib-types';

const apiEndpoint = 'statements';

export async function getStatementPayments(statementId: string): Promise<PaymentContract[]> {
  const { data } = await http.get<PaymentContract[]>(`${apiEndpoint}/${statementId}/payments`);
  return data;
}

export async function getStatementPaymentsPerCategory(
  statementId: string,
): Promise<Record<PaymentCategory, Partial<PaymentContract>[]>> {
  const { data } = await http.get(`${apiEndpoint}/${statementId}/paymentsPerCategory`);
  return data;
}

export async function getStatementAmounts(statementId: string): Promise<Record<PaymentCategory, number>> {
  const { data } = await http.get(`${apiEndpoint}/${statementId}/amounts`);
  return data;
}
