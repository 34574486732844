import { TypographyVariant } from '@amal-ia/frontend/design-system/meta';

export enum LabelVariant {
  DEFAULT = 'DEFAULT',
  ACCENTUATED = 'ACCENTUATED',
  DIMMED = 'DIMMED',
  BOLD = 'BOLD',
}

export enum LabelDisplay {
  INLINE = 'INLINE',
  BLOCK = 'BLOCK',
}

export const TypographyVariantMap: Record<LabelVariant, TypographyVariant> = {
  [LabelVariant.DEFAULT]: TypographyVariant.BODY_BASE_REGULAR,
  [LabelVariant.DIMMED]: TypographyVariant.BODY_BASE_REGULAR,
  [LabelVariant.ACCENTUATED]: TypographyVariant.BODY_BASE_MEDIUM,
  [LabelVariant.BOLD]: TypographyVariant.BODY_BASE_MEDIUM,
} as const;
