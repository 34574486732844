import { http } from '@amal-ia/frontend/kernel/http';
import { type WorkflowDefinition } from '@amal-ia/lib-types';

export class WorkflowsApiClient {
  public static async list(): Promise<WorkflowDefinition[]> {
    const { data } = await http.get<WorkflowDefinition[]>('/workflows');
    return data;
  }

  public static async create(workflow: WorkflowDefinition): Promise<WorkflowDefinition> {
    const { data } = await http.post<WorkflowDefinition>('/workflows', workflow);
    return data;
  }

  public static async update(workflowId: string, workflow: WorkflowDefinition): Promise<WorkflowDefinition> {
    const { data } = await http.put<WorkflowDefinition>(`/workflows/${encodeURIComponent(workflowId)}`, workflow);
    return data;
  }

  public static async delete(workflowId: string): Promise<void> {
    await http.delete(`/workflows/${encodeURIComponent(workflowId)}`);
  }
}
