import { type Theme, css } from '@emotion/react';

export const IS_UNREAD_CLASSNAME = 'unread';

export const notification = (theme: Theme) => css`
  text-decoration: none;

  padding: 12px;
  border-radius: ${theme.ds.borderRadiuses.squared};
  background-color: ${theme.ds.colors.gray[0]};

  transition: ${theme.ds.transitions.default('background-color', 'box-shadow')};

  background-color: var(--notification-background-color);

  &.${IS_UNREAD_CLASSNAME} {
    --notification-background-color: ${theme.ds.colors.graySecondary[50]};
  }
`;

export const notificationInteractive = (theme: Theme) => css`
  &:hover {
    --notification-background-color: ${theme.ds.colors.gray[50]};
  }

  &.${IS_UNREAD_CLASSNAME} {
    &:hover {
      --notification-background-color: ${theme.ds.colors.graySecondary[100]};
    }
  }

  &:focus-visible {
    position: relative; // To make the box-shadow appear above the next element background.
    outline: 1px solid ${theme.ds.colors.secondary[200]};
    outline-offset: -1px;
    box-shadow: ${theme.ds.shadows.focusMedium(theme.ds.colors.secondary[50])};
  }
`;

export const content = css`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const textContainer = css`
  flex: 1;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
`;

export const text = (theme: Theme) => css`
  color: ${theme.ds.colors.gray[800]};
`;

export const date = (theme: Theme) => css`
  color: ${theme.ds.colors.gray[600]};
`;

export const avatarContainer = css`
  position: relative;
`;

export const dotContainer = (theme: Theme) => css`
  position: absolute;
  top: -4px;
  left: -6px;
  height: 12px;
  width: 12px;

  border-radius: ${theme.ds.borderRadiuses.round};
  transition: ${theme.ds.transitions.default('background-color')};

  background-color: var(--notification-background-color);
`;

export const dot = (theme: Theme) => css`
  width: 8px;
  height: 8px;
  position: absolute;
  top: 2px;
  left: 2px;

  border-radius: ${theme.ds.borderRadiuses.round};
  background-color: ${theme.ds.hues.red[500]};
`;
