import { uniq } from 'lodash';
import { type ActionCreator } from 'redux';

import { type ReduxAction, type ThunkResult } from '@amal-ia/lib-types';
import { UserApiClient } from '@amal-ia/tenants/users/shared/api-client';
import { type UserContract } from '@amal-ia/tenants/users/shared/types';

import { USERS_ACTIONS } from './constants';
import { selectUsersList, selectUsersMap } from './selectors';

const usersStart: ActionCreator<ReduxAction> = () => ({
  type: USERS_ACTIONS.START,
});

const usersError: ActionCreator<ReduxAction> = (error: Error) => ({
  type: USERS_ACTIONS.ERROR,
  error,
});

export const setCurrentUser: ActionCreator<ReduxAction> = (user: UserContract | null) => ({
  type: USERS_ACTIONS.SET_CURRENT_USER,
  payload: { user },
});

export const setUsers: ActionCreator<ReduxAction> = (
  users: UserContract[],
  allUsers: boolean,
  activeUsers: boolean,
) => ({
  type: USERS_ACTIONS.SET_USERS,
  payload: { users, allUsers, activeUsers },
});

export const fetchUsers =
  (userIds?: string[]): ThunkResult<Promise<ReduxAction>> =>
  async (dispatch, getState) => {
    const currentUsersMap = selectUsersMap(getState());
    const cachedUserIds = Object.keys(currentUsersMap);

    const usersToFetch = userIds.filter((userId) => !cachedUserIds.includes(userId));

    if (usersToFetch.length === 0 && cachedUserIds.length > 0) {
      return setUsers(currentUsersMap);
    }

    dispatch(usersStart());

    try {
      const fetchedUsers = await UserApiClient.fetchUsersByIds(uniq(usersToFetch));
      // We need to pass already fetched users as some components need the whole list
      return dispatch(setUsers([...Object.values(currentUsersMap), ...fetchedUsers]));
    } catch (error) {
      return dispatch(usersError(error));
    }
  };

export const fetchAllUsers =
  (onlyActive: boolean): ThunkResult<Promise<ReduxAction>> =>
  async (dispatch, getState) => {
    const state = getState();

    // Check the cache before query.
    if (
      // If all users are already loaded.
      state.users.allUsersLoaded ||
      // Or if you're querying for active, but they're all there.
      (onlyActive && state.users.allActiveUsersLoaded)
    ) {
      return setUsers(selectUsersList(state));
    }

    dispatch(usersStart());

    try {
      if (onlyActive) {
        const activeUsers = await UserApiClient.fetchActiveUsers();
        return dispatch(setUsers(activeUsers, false, true));
      }

      const allUsers = await UserApiClient.getEmployees();
      return dispatch(setUsers(allUsers, true, false));
    } catch (error) {
      return dispatch(usersError(error));
    }
  };

export const flushUsers: ActionCreator<ReduxAction> = () => ({
  type: USERS_ACTIONS.FLUSH_USERS,
});
