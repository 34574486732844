import { IconPlus } from '@tabler/icons-react';
import { memo, useContext } from 'react';
import { FormattedMessage } from 'react-intl';

import { Button } from '@amal-ia/frontend/design-system/components';

import { rowsTableMessages } from './RowsTable.messages';
import RowsTableContext from './RowsTableContext';

export const RowsTableAddRecordButton = memo(function RowsTableAddRecordButton() {
  const { toggleAddRecordModal } = useContext(RowsTableContext);

  return (
    <Button
      icon={<IconPlus />}
      variant={Button.Variant.SECONDARY}
      onClick={toggleAddRecordModal}
    >
      <FormattedMessage {...rowsTableMessages.ADD_RECORDS} />
    </Button>
  );
});
