import { type ColumnChooser } from '@devexpress/dx-react-grid';
import { IconEyeOff } from '@tabler/icons-react';
import { useIntl } from 'react-intl';

import { IconButton } from '@amal-ia/frontend/design-system/components';

import { dataGridShowColumnsButtonMessages } from './DataGridComponents.messages';

export const DataGridShowColumnsButton = function DataGridShowColumnsButton({
  buttonRef,
  onToggle,
}: ColumnChooser.ToggleButtonProps) {
  const { formatMessage } = useIntl();
  return (
    <IconButton
      ref={buttonRef}
      icon={<IconEyeOff />}
      label={formatMessage(dataGridShowColumnsButtonMessages.SHOW_COLUMN_CHOOSER)}
      onClick={onToggle}
    />
  );
};
