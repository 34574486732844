import { type ActionCreator } from 'redux';

import { type ReduxAction } from '@amal-ia/lib-types';

import { SNACKBAR_ACTIONS, type SnackbarDefinition } from './constants';

export const addSnackbar: ActionCreator<ReduxAction> = (snackbar: SnackbarDefinition) => ({
  type: SNACKBAR_ACTIONS.ADD,
  payload: { snackbar },
});
