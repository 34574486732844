export const VARIABLES_ACTIONS = {
  START: 'VARIABLES/START',
  ERROR: 'VARIABLES/ERROR',
  SUCCESS: 'VARIABLES/SUCCESS',

  SET_VARIABLES: 'VARIABLES/SET_VARIABLES',
  CLEAR_VARIABLES: 'VARIABLES/CLEAR_VARIABLES',

  SET_VARIABLE_VALUES: 'VARIABLES/SET_VARIABLE_VALUES',
  CLEAR_VARIABLE_VALUES: 'VARIABLES/CLEAR_VARIABLE_VALUES',
  SET_VARIABLE_VALUE: 'VARIABLES/SET_VARIABLE_VALUE',

  CLEAR_USER_VARIABLE_VALUES: 'VARIABLES/CLEAR_USER_VARIABLE_VALUES',
  CLEAR_PLAN_VARIABLE_VALUES: 'VARIABLES/CLEAR_PLAN_VARIABLE_VALUES',
  CLEAR_TEAM_VARIABLE_VALUES: 'VARIABLES/CLEAR_TEAM_VARIABLE_VALUES',
} as const;
