import { last } from 'lodash';

import {
  type CustomReport,
  type CustomReportColumn,
  type CustomReportDataSourceManifest,
  type CustomReportManifestsMap,
} from '@amal-ia/lib-types';

export const getCustomReportFieldDefinition = (
  customReport: CustomReport,
  manifestsMap: CustomReportManifestsMap,
  field: { identifier: string; joins?: string[] },
): CustomReportDataSourceManifest['fields'][0] => {
  const manifest = manifestsMap[field.joins?.length > 0 ? last(field.joins) : customReport.source];

  // Manifest may not be loaded yet.
  if (!manifest) {
    return null;
  }

  return manifest.fields.find((fd) => fd.identifier === field.identifier) || null;
};

export const getCustomReportColumnsDefinitions = (
  customReport: CustomReport,
  manifestsMap: CustomReportManifestsMap,
) => {
  if (!customReport) {
    return [];
  }

  return customReport.configuration.fields
    .map((f) => {
      const fieldDefinition = getCustomReportFieldDefinition(customReport, manifestsMap, f);

      // Some fields may have disappeared from the manifest. Remove them to avoid crashes.
      if (!fieldDefinition) {
        return null;
      }

      const tableAlias = f.joins?.length ? last(f.joins) : customReport.source;

      return {
        fieldDefinition,
        field: f,
        identifier: `${tableAlias}__${fieldDefinition.identifier}`,
      };
    })
    .filter(Boolean);
};

export function buildCustomReportColumns(
  customReport: CustomReport,
  manifestsMap: CustomReportManifestsMap,
): CustomReportColumn[] {
  return getCustomReportColumnsDefinitions(customReport, manifestsMap).map(
    ({ fieldDefinition, field, identifier }) => ({
      identifier,
      label: field.alias || fieldDefinition.label,
      format: fieldDefinition.format,
    }),
  );
}

// PSQL max identifier length is 63 characters.
// We need to truncate the identifier to 63 characters to avoid errors.
export const formatMaxLengthIdentifierPsql = (identifier: string): string => identifier.substring(0, 63);
