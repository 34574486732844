import { UserRole } from '@amal-ia/tenants/users/shared/types';

export enum ChangelogRole {
  SALESREP = 'Sales Rep',
  MANAGER = 'Manager',
  ADMIN = 'Admin',
}

export enum ChangelogReleaseType {
  NEW_RELEASE = 'New Release',
  IMPROVEMENT = 'Improvement',
}

export enum ChangelogImportance {
  HIGH = 'High',
  MEDIUM = 'Medium',
  LOW = 'Low',
}

export interface ChangelogEntry {
  id: string;
  name: string;
  content: string;
  date: string;
  roles: ChangelogRole[];
  releaseType?: ChangelogReleaseType;
  importance: ChangelogImportance;
  topic?: string;
  imageUrl?: string;
  url?: string;
  urlLabel?: string;
}

export interface ChangelogEntryResponse {
  cursor: string | null;
  hasMore: boolean;
  entries: ChangelogEntry[];
}

export const CHANGELOG_VISIBILITY_PER_ROLE: Record<UserRole, ChangelogRole[]> = {
  [UserRole.ADMIN]: [ChangelogRole.ADMIN, ChangelogRole.MANAGER, ChangelogRole.SALESREP],
  [UserRole.READ_ONLY_ADMIN]: [ChangelogRole.ADMIN, ChangelogRole.MANAGER, ChangelogRole.SALESREP],
  [UserRole.FINANCE]: [ChangelogRole.ADMIN, ChangelogRole.MANAGER, ChangelogRole.SALESREP],

  [UserRole.MANAGER]: [ChangelogRole.MANAGER, ChangelogRole.SALESREP],
  [UserRole.READ_ONLY_MANAGER]: [ChangelogRole.MANAGER, ChangelogRole.SALESREP],

  [UserRole.EMPLOYEE]: [ChangelogRole.SALESREP],
  [UserRole.READ_ONLY_EMPLOYEE]: [ChangelogRole.SALESREP],
};
