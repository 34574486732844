import { keyBy } from 'lodash';
import { combineReducers } from 'redux';

import { type CustomObjectDefinitionsMap } from '@amal-ia/data-capture/models/types';
import { type ReduxAction } from '@amal-ia/lib-types';

import { type RelationshipsMap } from '../../types/relationships';

import { CUSTOM_OBJECT_DEFINITIONS_ACTIONS } from './constants';

export default combineReducers({
  isLoading: (state: number = 0, action: ReduxAction) => {
    switch (action.type) {
      case CUSTOM_OBJECT_DEFINITIONS_ACTIONS.START:
        return state + 1;
      case CUSTOM_OBJECT_DEFINITIONS_ACTIONS.ERROR:
      case CUSTOM_OBJECT_DEFINITIONS_ACTIONS.SET_CUSTOM_OBJECT_DEFINITIONS:
      case CUSTOM_OBJECT_DEFINITIONS_ACTIONS.SET_RELATIONSHIPS:
        return state - 1;
      default:
        return state;
    }
  },
  map: (state: CustomObjectDefinitionsMap | null = null, action: ReduxAction) => {
    switch (action.type) {
      case CUSTOM_OBJECT_DEFINITIONS_ACTIONS.SET_CUSTOM_OBJECT_DEFINITIONS:
        return keyBy(action.payload.customObjectDefinitions, 'machineName');
      default:
        return state;
    }
  },
  relationshipsMap: (state: RelationshipsMap | null = null, action: ReduxAction) => {
    switch (action.type) {
      case CUSTOM_OBJECT_DEFINITIONS_ACTIONS.SET_RELATIONSHIPS:
        return keyBy(action.payload.relationships, 'name');
      default:
        return state;
    }
  },
});
