import { type ReactNode, memo, forwardRef, type ForwardedRef, type ComponentPropsWithoutRef } from 'react';

import { type MergeAll } from '@amal-ia/ext/typescript';

import * as styles from './FormLayoutGroup.styles';

export type FormLayoutGroupProps = MergeAll<
  [
    ComponentPropsWithoutRef<'div'>,
    {
      title?: ReactNode;
      description?: ReactNode;
    },
  ]
>;

export const FormLayoutGroupForwardRef = forwardRef(function FormLayoutGroup(
  { title, description, children, ...props }: FormLayoutGroupProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  return (
    <div
      {...props}
      ref={ref}
      css={styles.formLayoutGroup}
      role="group"
    >
      {!!(title || description) && (
        <legend css={styles.formLayoutGroupHeader}>
          {!!title && <h3 css={styles.formLayoutGroupTitle}>{title}</h3>}
          {!!description && <div css={styles.formLayoutGroupDescription}>{description}</div>}
        </legend>
      )}

      <div css={styles.formLayoutGroupFields}>{children}</div>
    </div>
  );
});

export const FormLayoutGroup = memo(FormLayoutGroupForwardRef);
