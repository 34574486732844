import { defineMessages, type MessageDescriptor } from 'react-intl';

import { UserHrisIdSource } from '@amal-ia/tenants/users/shared/types';

export const userHrisIdSourceLabels: Readonly<Record<UserHrisIdSource, MessageDescriptor>> = defineMessages({
  [UserHrisIdSource.NONE]: {
    defaultMessage: 'No integrations',
  },
  [UserHrisIdSource.OTHERSOURCE]: {
    defaultMessage: 'Other HRIS source',
  },
  [UserHrisIdSource.PERSONIO]: {
    defaultMessage: 'Personio',
  },
  [UserHrisIdSource.BAMBOOHR]: {
    defaultMessage: 'BambooHR',
  },
  [UserHrisIdSource.WORKDAY]: {
    defaultMessage: 'Workday',
  },
});
