import { isNil } from 'lodash';

import { type Payment } from '@amal-ia/lib-types';

export const filterPaidPayments = (payments: Payment[], periodStartDate?: number) => {
  const startDate = periodStartDate || new Date().getDate();
  return payments.filter((p) => p.paymentPeriod && p.paymentPeriod.startDate === startDate);
};

export const filterHoldPayments = (payments: Payment[], periodStartDate?: number) => {
  const startDate = periodStartDate || new Date().getDate();
  return payments.filter((p) => !p.paymentPeriod || p.paymentPeriod.startDate > startDate);
};

export const isSplit = (payment: Pick<Payment, 'masterPaymentId' | 'totalValue' | 'value'>) =>
  !!payment.masterPaymentId || (!isNil(payment.totalValue) && payment.totalValue !== payment.value);
