import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  ACTIONS_ON_COLUMN: { defaultMessage: 'Actions on column {columnName}' },
  INSERT_COLUMN_LEFT: { defaultMessage: 'Insert column left' },
  INSERT_COLUMN_RIGHT: { defaultMessage: 'Insert column right' },
  INSERT_COLUMN_LEFT_NAME: { defaultMessage: 'Insert column left to column {columnName}' },
  INSERT_COLUMN_RIGHT_NAME: { defaultMessage: 'Insert column right to column {columnName}' },
  RENAME_COLUMN: { defaultMessage: 'Rename column' },
  RENAME_COLUMN_NAME: { defaultMessage: 'Rename column {columnName}' },
  DELETE_COLUMN: { defaultMessage: 'Delete column' },
  DELETE_COLUMN_NAME: { defaultMessage: 'Delete column {columnName}' },
} as const);
