export const notionFullTextToReact = (contents: any) => {
  const nodes = [];
  for (const content of contents || []) {
    nodes.push(
      <span
        style={{
          // Converts \n to line breaks.
          whiteSpace: 'pre-wrap',
          // Bold.
          fontWeight: content.annotations.bold ? 'bold' : undefined,
        }}
      >
        {content.plain_text}
      </span>,
    );
  }
  return nodes;
};
