import { css, type Theme } from '@emotion/react';

export const overwritten = (theme: Theme) => css`
  color: ${theme.ds.colors.secondary[500]};
`;

export const forecasted = (theme: Theme) => css`
  background-color: ${theme.ds.hues.blue[50]};
`;
export const warning = (theme: Theme) => css`
  background-color: ${theme.ds.colors.warning[50]};
`;
export const red = (theme: Theme) => css`
  color: ${theme.ds.colors.danger[500]};
`;

export const hidden = css`
  visibility: hidden;
`;
