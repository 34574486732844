import { isEmpty } from 'lodash';

import { http } from '@amal-ia/frontend/kernel/http';
import { type DataExport, type DataExportParams, type DataExportRequest, DownloadType } from '@amal-ia/lib-types';

import { downloadFileViaToken } from '../downloads/downloads.repository';

const apiEndpoint = '/data_exports';

export async function createDataExport(dataExport: DataExportRequest): Promise<DataExport> {
  const { data } = await http.post<DataExport>(`${apiEndpoint}`, {
    ...dataExport,
    params: {
      ...dataExport.params,
      // Do not send params if selection is empty.
      periodIds: isEmpty(dataExport.params.periodIds) ? undefined : dataExport.params.periodIds,
      planIds: isEmpty(dataExport.params.planIds) ? undefined : dataExport.params.planIds,
      ruleIds: isEmpty(dataExport.params.ruleIds) ? undefined : dataExport.params.ruleIds,
      teamIds: isEmpty(dataExport.params.teamIds) ? undefined : dataExport.params.teamIds,
      userIds: isEmpty(dataExport.params.userIds) ? undefined : dataExport.params.userIds,
      paymentPeriodIds: isEmpty(dataExport.params.paymentPeriodIds) ? undefined : dataExport.params.paymentPeriodIds,
      currencies: isEmpty(dataExport.params.currencies) ? undefined : dataExport.params.currencies,
      ruleTypes: isEmpty(dataExport.params.ruleTypes) ? undefined : dataExport.params.ruleTypes,
    },
  });
  return data;
}

export async function getInError(dataExportParams: DataExportParams): Promise<any[]> {
  const { data } = await http.post<any[]>(`${apiEndpoint}/in-error`, {
    periodIds: isEmpty(dataExportParams.periodIds) ? undefined : dataExportParams.periodIds,
    planIds: isEmpty(dataExportParams.planIds) ? undefined : dataExportParams.planIds,
    teamIds: isEmpty(dataExportParams.teamIds) ? undefined : dataExportParams.teamIds,
    userIds: isEmpty(dataExportParams.userIds) ? undefined : dataExportParams.userIds,
  });
  return data;
}

/**
 * Download data export file.
 * @param exportId
 */
export async function downloadDataExportFile(exportId: string) {
  return downloadFileViaToken({
    type: DownloadType.DATA_EXPORT,
    dataId: exportId,
  });
}
