import { css } from '@emotion/react';
import { Box } from '@mui/material';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { amaliaTheme } from '@amal-ia/ext/mui/theme';
import { Typography } from '@amal-ia/frontend/design-system/components';
import { DrawingSearchRecordsEmpty } from '@amal-ia/lib-ui';

import { addRecordsModalMessages } from './AddRecordsModal.messages';

interface EmptyModalProps {
  readonly search: string;
}

export const EmptyModal = memo(function EmptyModal({ search }: EmptyModalProps) {
  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="row"
      justifyContent="center"
      py={amaliaTheme.spacing(1)}
    >
      <Box px={amaliaTheme.spacing(3)}>
        {search ? (
          <Typography
            variant={Typography.Variant.HEADING_2_BOLD}
            css={(theme) => css`
              color: ${theme.ds.colors.gray[700]};
            `}
          >
            <FormattedMessage {...addRecordsModalMessages.NO_RECORD_FOUND} />
          </Typography>
        ) : null}

        <Typography
          variant={Typography.Variant.BODY_LARGE_REGULAR}
          css={(theme) => css`
            color: ${theme.ds.colors.gray[600]};
          `}
        >
          <FormattedMessage
            {...addRecordsModalMessages.SEARCH_RECORDS}
            values={{
              strong: (chunks) => <Typography variant={Typography.Variant.BODY_LARGE_BOLD}>{chunks}</Typography>,
            }}
          />
        </Typography>
      </Box>
      <Box px={amaliaTheme.spacing(1)}>
        <DrawingSearchRecordsEmpty />
      </Box>
    </Box>
  );
});
