import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  ACTIONS_ON_ROW: { defaultMessage: 'Actions on row {rowId}' },
  INSERT_ROW_ABOVE_ID: { defaultMessage: 'Insert row above row {rowId}' },
  INSERT_ROW_BELOW_ID: { defaultMessage: 'Insert row below row {rowId}' },
  DELETE_ROW_ID: { defaultMessage: 'Delete row {rowId}' },
  INSERT_ROW_ABOVE: { defaultMessage: 'Insert row above' },
  INSERT_ROW_BELOW: { defaultMessage: 'Insert row below' },
  DELETE_ROW: { defaultMessage: 'Delete row' },
} as const);
