import { css, type Theme } from '@emotion/react';

export const HAS_ERROR_CLASSNAME = 'has-error';
export const IS_DISABLED_CLASSNAME = 'is-disabled';

export const cellTextField = (theme: Theme) => css`
  display: flex;
  align-items: center;
  gap: 8px;

  transition: ${theme.ds.transitions.default('background-color', 'box-shadow')};

  &:not(.${IS_DISABLED_CLASSNAME}) {
    cursor: text;
  }

  &:not(.${IS_DISABLED_CLASSNAME}):hover,
  &:focus-within {
    outline: 1px solid ${theme.ds.colors.secondary[200]};

    &.${HAS_ERROR_CLASSNAME} {
      outline-color: ${theme.ds.colors.danger[500]};
    }
  }

  &:focus-within {
    position: relative;
    z-index: 1;
    box-shadow: ${theme.ds.shadows.focusMedium(theme.ds.colors.secondary[50])};
    background-color: ${theme.ds.colors.gray[0]};

    &.${HAS_ERROR_CLASSNAME} {
      box-shadow: ${theme.ds.shadows.focusMedium(theme.ds.colors.danger[100])};
    }
  }
`;

export const input = (theme: Theme) => css`
  all: unset;

  ${theme.ds.typographies.bodyBaseRegular};

  flex: 1;
  min-width: 0;

  &::placeholder {
    color: ${theme.ds.colors.gray[400]};
  }
`;

export const indicatorsContainer = css`
  display: flex;
  align-items: center;
  gap: 8px;
  flex: none;

  > * {
    flex: none;
  }
`;

export const readonly = css`
  flex: 1;
`;

export const disabledPlaceholder = (theme: Theme) => css`
  color: ${theme.ds.colors.gray[300]};
`;
