import { http } from '@amal-ia/frontend/kernel/http';
import { type Flow, type FlowActions } from '@amal-ia/lib-types';

export async function getFlowById(id: string): Promise<Flow> {
  const { data } = await http.get(`/flows/${id}`);
  return data;
}

export async function listFlows(): Promise<Flow[]> {
  const { data } = await http.get('/flows');
  return data;
}

export async function updateFlow(flowToUpdate: Flow): Promise<Flow> {
  const { data } = await http.put(`/flows/${flowToUpdate.id}`, flowToUpdate);
  return data;
}

export async function addFlow(flowToAdd: Flow): Promise<Flow> {
  const { data } = await http.post('/flows', flowToAdd);
  return data;
}

export async function triggerFlowAction(flow: Flow, action: FlowActions): Promise<void> {
  return http.get(`/flows/${flow.id}/${action}`);
}
