import { AvatarGroup as MuiAvatarGroup } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { memo } from 'react';

import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';

import { Avatar } from './Avatar';
import { type AvatarGroupProps } from './AvatarGroup';

const avatarGroupStyles = makeStyles((theme: AmaliaThemeType) => ({
  avatar: {
    width: '24px',
    height: '24px',
    marginLeft: '-3px',
    color: theme.palette.common.white,
    border: 'none',
    '&:first-child': {
      backgroundColor: theme.palette.primary.main,
    },

    '&:last-child': {
      color: theme.palette.grey[700],
    },
  },
  avatarRoot: {
    width: '24px',
    height: '24px',
    marginLeft: '-3px',

    '&:first-child': {
      marginLeft: '0',
    },
  },
}));

export const SmallAvatarGroup = memo(function SmallAvatarGroup({ users, ...props }: AvatarGroupProps) {
  const classes = avatarGroupStyles();
  return (
    <MuiAvatarGroup
      {...props}
      classes={{ avatar: classes.avatar }}
    >
      {(users || []).map((user) => (
        <Avatar
          key={user.id}
          className={classes.avatar}
          parentClassName={classes.avatarRoot}
          user={user}
        />
      ))}
    </MuiAvatarGroup>
  );
});
