import { useMemo } from 'react';

import { type AnyRecord } from '@amal-ia/ext/typescript';

/**
 * Memoize an object based on the first depth of properties.
 * **Properties must be static.**
 *
 * @param bundle - Object whose properties will be memoized.
 * @returns The bundle.
 */
export const useShallowObjectMemo = <TRecord extends AnyRecord>(bundle: TRecord): TRecord =>
  useMemo(
    () => bundle,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    Object.values(bundle),
  );
