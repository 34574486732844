import { css } from '@emotion/react';
import { isNil, round } from 'lodash';
import { memo, useMemo } from 'react';

import { FormatsEnum } from '@amal-ia/data-capture/fields/types';
import { Typography } from '@amal-ia/frontend/design-system/components';
import { TypographyVariant } from '@amal-ia/frontend/design-system/meta';
import { formatValue } from '@amal-ia/lib-types';

import { KpiLegend } from './KpiLegend';

type KpiPillProps = Readonly<{
  label: string;
  value: number;
  color: string;
}>;
export const KpiPill = memo(function KpiPill({ label, value, color }: KpiPillProps) {
  const kpiValue = useMemo(() => (!isNil(value) ? formatValue(round(value, 2), FormatsEnum.percent) : '- %'), [value]);

  return (
    <div
      aria-label={label}
      css={(theme) => css`
        display: flex;
        padding: 3px 16px;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        border-radius: 24px;
        border: 1px solid ${theme.ds.colors.gray[100]};
        min-width: 200px;
        max-width: 360px;
        opacity: ${isNil(value) ? 0.5 : 1};
      `}
    >
      <div
        css={css`
          gap: 8px;
          align-items: center;
          display: flex;
          width: 100%;
        `}
      >
        <KpiLegend color={color} />
        <Typography
          title={label}
          variant={TypographyVariant.BODY_BASE_REGULAR}
          css={css`
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 200px;
          `}
        >
          {label}
        </Typography>
        <div
          css={css`
            margin-left: auto;
            min-width: 30px;
          `}
        >
          <Typography variant={TypographyVariant.BODY_BASE_MEDIUM}>{kpiValue}</Typography>
        </div>
      </div>
    </div>
  );
});
