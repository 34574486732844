import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  OLD_VALUE: {
    defaultMessage: 'Old value',
  },
  NEW_VALUE: {
    defaultMessage: 'New value',
  },
  NEW_VALUE_PERCENTAGE: {
    defaultMessage: 'New value (%)',
  },
  NONE: {
    defaultMessage: 'None',
  },
} as const);
