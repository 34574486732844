import { type ActionCreator } from 'redux';

import { type ReduxAction, type ThunkResult } from '@amal-ia/lib-types';

import * as PlanAssignmentsRepository from '../../services/planAssignments/planAssignment.repository';
import { type PlanAssignmentWithUser } from '../../types/planAssignments';

import { PLAN_ASSIGNMENTS_ACTIONS } from './constants';

const planAssignmentsStart: ActionCreator<ReduxAction> = () => ({
  type: PLAN_ASSIGNMENTS_ACTIONS.START,
});

const planAssignmentsError: ActionCreator<ReduxAction> = (error: Error) => ({
  type: PLAN_ASSIGNMENTS_ACTIONS.ERROR,
  error,
});

const setPlanAssignments: ActionCreator<ReduxAction> = (planAssignments: PlanAssignmentWithUser[]) => ({
  type: PLAN_ASSIGNMENTS_ACTIONS.SET_PLAN_ASSIGNMENTS,
  payload: { planAssignments },
});

export const fetchPlanAssignments =
  (planIds: string[]): ThunkResult<Promise<ReduxAction>> =>
  async (dispatch) => {
    dispatch(planAssignmentsStart());

    try {
      const planAssignments = await PlanAssignmentsRepository.listPlanAssignmentsForPlans(planIds);
      return dispatch(setPlanAssignments(planAssignments));
    } catch (error) {
      return dispatch(planAssignmentsError(error));
    }
  };
