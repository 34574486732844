import { IconPlug, IconPlugOff } from '@tabler/icons-react';

import { DataConnectorTypes } from '@amal-ia/data-capture/connectors/types';
import { UserHrisIdSource } from '@amal-ia/tenants/users/shared/types';

import { type IntegrationLogos, VENDOR_LOGOS } from '../../../connectors.logos';

export const userHrisIdSourceLogos: Readonly<Record<UserHrisIdSource, IntegrationLogos>> = {
  [UserHrisIdSource.NONE]: {
    Logo: IconPlugOff,
    CompactLogo: IconPlugOff,
  },
  [UserHrisIdSource.OTHERSOURCE]: {
    Logo: IconPlug,
    CompactLogo: IconPlug,
  },
  [UserHrisIdSource.PERSONIO]: {
    ...VENDOR_LOGOS[DataConnectorTypes.PERSONIO],
  },
  [UserHrisIdSource.BAMBOOHR]: {
    ...VENDOR_LOGOS[DataConnectorTypes.BAMBOOHR],
  },
  [UserHrisIdSource.WORKDAY]: {
    ...VENDOR_LOGOS[DataConnectorTypes.WORKDAY],
  },
};
