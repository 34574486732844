import { lazy, memo } from 'react';

import { Layout } from '@amal-ia/frontend/connected-components/layout';

const DashboardPage = lazy(() => import('./HomePage'));

export const DashboardView = memo(function DashboardView() {
  return (
    <Layout currentPage="dashboard">
      <DashboardPage />
    </Layout>
  );
});
