import { uniq, without } from 'lodash';
import { type Ref, useCallback, useMemo, useState } from 'react';

import { useResizeObserver } from '@amal-ia/ext/react/hooks';

import { getRowKey } from '../helpers/getRowKey';
import { type TableContextValue } from '../Table.context';
import { type RowData, type RowKey } from '../Table.types';

export type UseTableMultiSelectionValue<TKey extends RowKey> = {
  isSelectionEnabled: boolean;
  isSelectionActive: boolean;
  setIsSelectionActive: (value: boolean) => void;
  selectionColumnRef: Ref<HTMLTableCellElement>;
  selectionColumnWidth: number;
  handleSelectAllRows: (isSelected: boolean) => void;
  handleSelectRow: (isSelected: boolean, key: TKey) => void;
  hasSomeRowsSelected: boolean;
  hasAllRowsSelected: boolean;
};

export const useTableMultiSelection = <TData extends RowData = RowData, TKey extends RowKey = RowKey>({
  data,
  rowKey,
  selectedRows,
  onChangeSelectedRows,
  enabled,
}: {
  data: TData[];
  rowKey: TableContextValue<TData, TKey>['rowKey'];
  enabled: boolean;
  selectedRows?: TKey[];
  onChangeSelectedRows?: (selectedRows: TKey[]) => void;
}): UseTableMultiSelectionValue<TKey> => {
  const [isSelectionActive, setIsSelectionActive] = useState(false);
  const [{ width: selectionColumnWidth }, setSelectionColumnDimensions] = useState({ width: 0 });
  const selectionColumnRef = useResizeObserver<HTMLTableCellElement>({ onResize: setSelectionColumnDimensions });

  const currentPageRowKeys = useMemo(() => data.map((row) => getRowKey(row, rowKey)), [data, rowKey]);

  const hasSomeRowsSelected = !!selectedRows && currentPageRowKeys.some((key) => selectedRows.includes(key));
  const hasAllRowsSelected = !!selectedRows && currentPageRowKeys.every((key) => selectedRows.includes(key));

  const handleSelectAllRows = useCallback(
    (isSelected: boolean) =>
      // There might be multiple pages, so we need to keep values that were already here.
      // When selecting, add all keys of current page.
      // When deselecting, remove all keys of current page.
      onChangeSelectedRows?.(
        isSelected
          ? uniq([...(selectedRows ?? []), ...currentPageRowKeys])
          : without(selectedRows, ...currentPageRowKeys),
      ),
    [onChangeSelectedRows, selectedRows, currentPageRowKeys],
  );

  const handleSelectRow = useCallback(
    (isSelected: boolean, key: TKey) =>
      onChangeSelectedRows?.(isSelected ? uniq([...(selectedRows ?? []), key]) : without(selectedRows, key)),
    [onChangeSelectedRows, selectedRows],
  );

  return {
    isSelectionEnabled: enabled,
    isSelectionActive,
    setIsSelectionActive,
    selectionColumnRef,
    selectionColumnWidth,
    handleSelectAllRows,
    handleSelectRow,
    hasSomeRowsSelected,
    hasAllRowsSelected,
  };
};
