import { defineMessages } from 'react-intl';

export const forecastedKpiTooltipMessages = defineMessages({
  FORECASTED_VALUE: { defaultMessage: 'Forecasted value' },
  ACHIEVED_VALUE: { defaultMessage: 'Achieved value' },
  SIMULATION_DETAILS: { defaultMessage: 'Simulation details' },
  FORECAST_DETAILS: { defaultMessage: 'Forecast details' },
  OVERWRITTEN_BY: { defaultMessage: 'Overwritten by <b>{author}</b>{br}on <b>{date}</b>' },
  CLEAR_SIMULATION: { defaultMessage: 'Clear simulation' },
  OLD_VALUE: { defaultMessage: 'Old value' },
  NEW_VALUE: { defaultMessage: 'New value' },
} as const);
