import moment from 'moment';

import { FormatsEnum } from '@amal-ia/data-capture/fields/types';
import { CurrencySymbolsEnum } from '@amal-ia/ext/iso-4217';
import { formatAmount } from '@amal-ia/lib-types';
import {
  isComputedCurrencyAmount,
  type ComputeEngineColumnResult,
  type ComputeEnginePrimitiveTypes,
} from '@amal-ia/payout-calculation/shared/types';

const MIN_AMOUNT = 0.01;
export const TRUNCATED_CONSTANT = 10_000_000_000;

export const roundNumber = (numberToRound: number | undefined, step = 0.01): number => {
  // If payment is next to 0, return 0
  if (!numberToRound || (numberToRound < MIN_AMOUNT && numberToRound > -1 * MIN_AMOUNT)) {
    return 0;
  }
  return Math.round(numberToRound * (1 / step)) / (1 / step);
};

const formatTotalForDate = (total: number | string): string => {
  const isUnixTimestamp = moment(total, 'X', true).isValid();
  if (isUnixTimestamp) {
    return moment(total, 'X').format('YYYY-MM-DD');
  }

  return `${total}`;
};

const formatTotalForPercent = (total: number, roundingConstant: number = 1): string =>
  `${roundNumber(total * 100 * roundingConstant) / roundingConstant} %`;

const formatTotalForNumber = (total: number, roundingConstant: number = 1): string => {
  const roundTotal = roundNumber(total * roundingConstant) / roundingConstant;
  return `${roundTotal}`;
};

export const formatTotal = (
  total: ComputeEnginePrimitiveTypes | undefined,
  format: FormatsEnum,
  currencySymbol: CurrencySymbolsEnum = CurrencySymbolsEnum.EUR,
  currencyRate: number = 1,
  roundingConstant: number = 1,
): number | string => {
  if (total === 'Infinity') {
    return Infinity;
  }
  if (total === null) {
    return 'null';
  }
  switch (format) {
    case FormatsEnum.table:
      return '';
    case FormatsEnum.text:
      return `${total}`;
    case FormatsEnum.date:
    case FormatsEnum['date-time']:
      return formatTotalForDate(total as number | string);
    case FormatsEnum.percent:
      return formatTotalForPercent(total as number, roundingConstant);
    case FormatsEnum.number:
      return formatTotalForNumber(total as number, roundingConstant);
    case FormatsEnum.boolean:
      return `${total}`;
    // Default usage for this function is to format as currency.
    case FormatsEnum.currency:
    default:
      return formatAmount(
        (roundNumber((total as number) * roundingConstant) / roundingConstant) * currencyRate,
        currencySymbol,
      );
  }
};

export const formatValueTotal = (value: ComputeEnginePrimitiveTypes | undefined): string | undefined =>
  value === null || value === undefined ? undefined : value.toString();

export const formatValueOrPrintRemovedLabel = (value: number | string | null | undefined): string | undefined =>
  value === null || value === undefined || value === '' ? 'deleted' : formatValueTotal(value);

export const formatDatasetCell = (
  cellValue: ComputeEngineColumnResult,
  forceFormat?: FormatsEnum,
  roundingConstant: number = 1,
): number | string | undefined => {
  // Only parse as date if it's a timestamp, otherwise show the exact value
  if (typeof cellValue === 'number' && forceFormat === FormatsEnum.date && cellValue > 10_000) {
    return cellValue ? moment.utc(cellValue, 'X').format('YYYY-MM-DD') : '';
  }

  if (forceFormat) {
    const value = (cellValue as { value: number })?.value || (cellValue as number);
    return formatTotal(value, forceFormat, undefined, undefined, roundingConstant);
  }

  // Case for currencies.
  if (isComputedCurrencyAmount(cellValue)) {
    const { value, symbol } = cellValue;
    return formatTotal(value, FormatsEnum.currency, symbol, 1, roundingConstant);
  }

  // Display as-is.
  return formatValueTotal(cellValue);
};
