import { isNaN, isNil } from 'lodash';

import { type CurrencyValue } from '@amal-ia/kernel/monetary/types';

export const getTheValueOfPercentage = (value: CurrencyValue | number, percentage: number) => {
  if (isNaN(percentage)) {
    return 0;
  }

  if (isNil(value)) {
    return 0;
  }

  if (typeof value === 'object') {
    return (percentage / value.value) * 100;
  }

  return (percentage / value) * 100;
};

export const getThePercentageOfValue = (oldValue: CurrencyValue | number, newValue: number) => {
  if (isNaN(newValue)) {
    return 0;
  }

  if (typeof oldValue === 'object') {
    return oldValue.value * (newValue / 100);
  }

  return oldValue * (newValue / 100);
};
