// Sumup of a plan for super admin: only return part of plan
import { type MaintenanceWindow } from '@amal-ia/kernel/maintenance-mode';

export interface SaPlanSumup {
  id: string;
  name: string;
  companyId: string;
  companyName: string;
  planId?: string;
  planTemplateGeneratedDate?: Date;
  buildTimeMs?: number;
  postBuildTimeMs?: number;
  recursionLevel?: number;
}

export interface MasterSettings {
  MAINTENANCE: MaintenanceWindow | null;
  IMPERSONATE: ImpersonateProps | null;
}

export type MasterSettingKey = keyof MasterSettings;
export type MasterSettingValue = MasterSettings[keyof MasterSettings];

export enum SaActions {
  RECOMPUTE_ALL_PLAN_TEMPLATES = 'RECOMPUTE_ALL_PLAN_TEMPLATES',
}

export type ImpersonateProps = Record<string, string>;
