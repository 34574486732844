import { defineMessages, type MessageDescriptor } from 'react-intl';

import { UserExternalIdSource } from '@amal-ia/tenants/users/shared/types';

export const userExternalIdSourceLabels: Readonly<Record<UserExternalIdSource, MessageDescriptor>> = defineMessages({
  [UserExternalIdSource.NONE]: {
    defaultMessage: 'No integrations',
  },
  [UserExternalIdSource.OTHERSOURCE]: {
    defaultMessage: 'Other external source',
  },
  [UserExternalIdSource.SALESFORCE]: {
    defaultMessage: 'Salesforce',
  },
  [UserExternalIdSource.HUBSPOT]: {
    defaultMessage: 'Hubspot',
  },
  [UserExternalIdSource.ZOHO]: {
    defaultMessage: 'Zoho',
  },
});
