import { ClassNames } from '@emotion/react';
import { IconSelector } from '@tabler/icons-react';
import clsx from 'clsx';
import {
  type ForwardedRef,
  type ReactNode,
  forwardRef,
  memo,
  cloneElement,
  type ComponentPropsWithoutRef,
} from 'react';

import { type MergeAll } from '@amal-ia/ext/typescript';

import { type TablerIconElement } from '../icons/types';
import { Typography, type TypographyProps } from '../typography/Typography';

import * as styles from './SelectorTrigger.styles';

export type SelectorTriggerProps = MergeAll<
  [
    Omit<ComponentPropsWithoutRef<'button'>, 'type'>,
    {
      /** Label Typography component `as` prop. */
      as?: TypographyProps['as'];
      /** Is currently active (i.e. its menu is open). */
      isActive?: boolean;
      /** Optional left icon. */
      icon?: TablerIconElement;
      /** Label. */
      children: ReactNode;
    },
  ]
>;

const SelectorTriggerForwardRef = forwardRef(function SelectorTrigger(
  {
    as = undefined,
    isActive = false,
    className = undefined,
    icon = undefined,
    children,
    ...props
  }: SelectorTriggerProps,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  return (
    <button
      {...props}
      ref={ref}
      css={styles.selectorTrigger}
      type="button"
      className={clsx(className, {
        [styles.IS_ACTIVE_CLASSNAME]: isActive,
      })}
    >
      <div css={styles.label}>
        {!!icon && (
          <ClassNames>
            {({ css }) =>
              cloneElement(icon, {
                color: 'currentColor',
                size: 16,
                className: clsx(
                  icon.props.className,
                  css`
                    flex: none;
                  `,
                ),
              })
            }
          </ClassNames>
        )}

        <Typography
          as={as}
          variant={Typography.Variant.BODY_BASE_MEDIUM}
        >
          {children}
        </Typography>
      </div>

      <IconSelector
        color="currentColor"
        css={styles.icon}
        size={18}
      />
    </button>
  );
});

export const SelectorTrigger = memo(SelectorTriggerForwardRef);
