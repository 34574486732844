import { makeStyles } from '@mui/styles';

import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';

export const useTracingStyles = makeStyles((theme: AmaliaThemeType) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(4),
  },
  card: {
    minHeight: 140,
    width: '100%',
    margin: `0 ${theme.spacing(1)} ${theme.spacing(2)} ${theme.spacing(1)}`,
  },
  total: {
    flex: 'none',
    whiteSpace: 'nowrap',
  },
  result: {
    flex: 1,
  },
  tracingInnerTable: {
    maxWidth: '100%',
    overflowX: 'auto',
    position: 'relative',
  },
  rawBtn: {
    clipPath: 'polygon(100% 0%, 100% 100%, 0% 100%)',
  },
  container: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  openTracingItemsButton: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.common.white,
    color: theme.palette.divider,
    padding: theme.spacing(1),
  },
}));
