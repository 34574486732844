import {
  Switch as MaterialSwitch,
  type SwitchClassKey,
  type SwitchProps as MaterialSwitchProps,
  type Theme,
} from '@mui/material';
import { withStyles } from '@mui/styles';

import { colors } from '@amal-ia/ext/mui/theme';

interface Styles extends Partial<Record<SwitchClassKey, string>> {
  focusVisible?: string;
}

const getThumbSize = (size: number) => size * 0.6;

interface SwitchProps extends MaterialSwitchProps {
  classes?: Styles;
  height?: number;
  noMargin?: boolean;
}

export const Switch = withStyles((theme: Theme) => ({
  root: {
    width: (props: SwitchProps) => (props?.height || 32) * 2,
    height: (props: SwitchProps) => props?.height || 32,
    padding: 0,
    display: 'flex',
    opacity: 1,
    margin: (props: SwitchProps) => (props.noMargin ? 0 : 10),
  },
  switchBase: {
    padding: (props: SwitchProps) => (props?.height || 32) * 0.2,
    border: 'none',
    '&$checked + $track': {
      opacity: 1,
      backgroundColor: (props: SwitchProps) => (props.disabled ? colors['primary-200'] : theme.palette.primary.main),
    },
  },
  thumb: {
    width: (props: SwitchProps) => getThumbSize(props?.height || 32),
    height: (props: SwitchProps) => getThumbSize(props?.height || 32),
    borderRadius: (props: SwitchProps) => getThumbSize(props?.height || 32) / 2,
    boxShadow: 'none',
    backgroundColor: theme.palette.common.white,
  },
  track: {
    border: 'none',
    borderRadius: (props: SwitchProps) => (props?.height || 32) / 2,
    opacity: 1,
    '&$checked': {
      opacity: 1,
    },
    backgroundColor: 'rgba(0, 0, 0, 0.54)',
  },
  checked: {
    transform: (props: SwitchProps) => `translateX(${props?.height || 32}px)!important`,
  },
  disabled: {
    cursor: 'not-allowed',
    backgroundColor: (props: SwitchProps) => (props.checked ? `${colors['primary-200']}` : 'transparent'),
  },
}))(({ classes, ...props }: SwitchProps) => (
  <MaterialSwitch
    disableRipple
    classes={{
      root: classes?.root,
      switchBase: classes?.switchBase,
      thumb: classes?.thumb,
      track: classes?.track,
      checked: classes?.checked,
      disabled: classes?.disabled,
    }}
    {...props}
  />
));
