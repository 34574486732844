import { defineMessages } from 'react-intl';

export const rowsTableMessages = defineMessages({
  ADD_RECORDS: { defaultMessage: 'Add record' },
  MORE_ACTIONS: { defaultMessage: 'More actions' },
  OPEN_TRACING: { defaultMessage: 'Open tracing' },
  OPEN_DEAL: { defaultMessage: 'View deal in {connector}' },
  REMOVE_THIS_SIMULATION_LINE: { defaultMessage: 'Remove deal added from simulation' },
  REMOVE_THIS_LINE_VIA_OVERWRITE: { defaultMessage: 'Remove this deal via an overwrite' },
});
