import { type Theme, css } from '@emotion/react';

import { RadioButtonGroupSize } from './RadioButtonGroup.types';

export const IS_DISABLED_CLASSNAME = 'disabled';
export const IS_FOCUS_VISIBLE_CLASSNAME = 'focus-visible';

export const radioButtonGroup = (theme: Theme) => css`
  transition: ${theme.ds.transitions.default('border-color', 'box-shadow')};

  display: inline-flex;
  align-items: stretch;
  align-self: flex-start;

  border-radius: ${theme.ds.borderRadiuses.squared};

  border: 1px solid ${theme.ds.colors.gray[100]};

  &.disabled {
    border-color: ${theme.ds.colors.gray[200]};
  }

  &.focus-visible {
    outline: 1px solid ${theme.ds.colors.secondary[200]};
    outline-offset: -1px;

    &.${RadioButtonGroupSize.SMALL} {
      box-shadow: ${theme.ds.shadows.focusSmall(theme.ds.colors.secondary[50])};
    }

    &.${RadioButtonGroupSize.MEDIUM} {
      box-shadow: ${theme.ds.shadows.focusMedium(theme.ds.colors.secondary[50])};
    }
  }
`;
