import { type UserRole } from '@amal-ia/tenants/users/shared/types';

export enum WorkflowType {
  PLAN = 'PLAN',
}

export enum WorkflowConditionType {
  ROLE = 'ROLE',
  USER = 'USER',
  OWNER = 'OWNER',
}

export interface WorkflowCondition {
  conditionType: WorkflowConditionType;
  role?: UserRole;
  userId?: string;
}

export interface WorkflowStep {
  // A human readable name for the step, like `Manager Validation`
  // or `DAF validation`.
  stepName: string;
  // List of conditions that can validate the step.
  // At least one of them should be valid (OR) to go to the next step.
  conditions: WorkflowCondition[];
}

export interface WorkflowOptions {
  adminCanSkip: boolean;
  reviewsReminder: boolean;
}

export interface WorkflowDefinition {
  id?: string;
  updatedAt?: Date;
  name: string;
  machineName: string;
  type: WorkflowType;
  steps: WorkflowStep[];
  workflowOptions: WorkflowOptions;
}

// STATEMENT

export interface WorkflowStatementState {
  stepName: string;
  from: number;
  to: number;
  date: Date;
  userId: string;
}

export enum WorkflowStatementStateAction {
  FORCE = 'FORCE',
  NEXT = 'NEXT',
  PREVIOUS = 'PREVIOUS',
  RESET = 'RESET',
  UNAUTHORIZED = 'UNAUTHORIZED',
}

export interface WorkflowCondensedStep {
  stepName: string;
  date?: Date;
  userId?: string;
  numberOfStatementsLabel?: string;
  completed: boolean;
}

export type WorkflowMap = Record<string, WorkflowDefinition>;
