import {
  type AmaliaFormula,
  type FormulaBuilderFunctionBlockDate,
  type FormulaBuilderFunctionBlockString,
} from '@amal-ia/amalia-lang/formula/shared/types';

import { type FormulaBuilderFunctionBlockBoolean } from './formulaBuilderBooleanFunctions';
import { type FormulaBuilderFunctionBlockCurrency } from './formulaBuilderCurrencyFunctions';
import { type FormulaBuilderFunctionBlockNumber } from './formulaBuilderNumberFunctions';
import { type FormulaBuilderFunctionBlockPercent } from './formulaBuilderPercentFunctions';
import { type FormulaBuilderFunctionBlockUser } from './formulaBuilderUserFunctions';

export enum FormulaBuilderLogicalOperatorType {
  AND = 'AND',
  OR = 'OR',
}

export enum FormulaBuilderBlockType {
  LOGICAL_OPERATOR = 'LOGICAL_OPERATOR',
  FORMULA = 'FORMULA',
  FUNCTION = 'FUNCTION',
}

export type FormulaBuilderBaseBlock = {
  id: string;
  type: FormulaBuilderBlockType;
};

export type FormulaBuilderFormulaBlock = FormulaBuilderBaseBlock & {
  label: string;
  type: FormulaBuilderBlockType.FORMULA;
  formula: AmaliaFormula;
};

export type FormulaBuilderFunctionBaseBlock = FormulaBuilderBaseBlock & {
  type: FormulaBuilderBlockType.FUNCTION;
};

export type FormulaBuilderFunctionBlock =
  | FormulaBuilderFunctionBlockBoolean
  | FormulaBuilderFunctionBlockCurrency
  | FormulaBuilderFunctionBlockDate
  | FormulaBuilderFunctionBlockNumber
  | FormulaBuilderFunctionBlockPercent
  | FormulaBuilderFunctionBlockString
  | FormulaBuilderFunctionBlockUser;

export type FormulaBuilderLogicalOperatorBlock = FormulaBuilderBaseBlock & {
  type: FormulaBuilderBlockType.LOGICAL_OPERATOR;
  logicalOperator: FormulaBuilderLogicalOperatorType;
  operands: (FormulaBuilderFormulaBlock | FormulaBuilderFunctionBlock | FormulaBuilderLogicalOperatorBlock)[];
};

export type FormulaBuilderRootBlock = Omit<FormulaBuilderLogicalOperatorBlock, 'operands'> & {
  operands: FormulaBuilderLogicalOperatorBlock[];
};

export type FormulaBuilder = {
  root: FormulaBuilderRootBlock;
};
