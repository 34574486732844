import { makeStyles } from '@mui/styles';

import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';

export const useMiniCardStyles = makeStyles((theme: AmaliaThemeType) => ({
  root: {
    padding: theme.spacing(1),
    display: 'inline-flex',
    top: 0,
    flexDirection: 'row',
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '1rem',
    alignItems: 'center',

    '& $root': {
      marginLeft: theme.spacing(1),
    },
  },
  cardDetails: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
}));
