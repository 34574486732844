import { type PlanAgreementAssignment, PlanAgreementAssignmentStatus } from '../types/planAgreementAssignments';
import { type PlanAgreement, PlanAgreementStatus, PlanAgreementConfirmationMethod } from '../types/planAgreements';

/**
 * Check if plan agreement is archiveable
 */
export const isPlanAgreementArchiveable = (planAgreement: PlanAgreement): boolean =>
  [PlanAgreementStatus.DONE, PlanAgreementStatus.FINALIZED].includes(planAgreement.status);

/**
 * Check if plan agreement is bulk downloadable for all assignments
 */
export const isPlanAgreementBulkDownloadable = (planAgreement: PlanAgreement): boolean =>
  planAgreement.planAgreementAssignments.every((assignment) =>
    [
      PlanAgreementAssignmentStatus.AGREEMENT_GENERATED,
      PlanAgreementAssignmentStatus.AGREEMENT_SENT,
      PlanAgreementAssignmentStatus.CONFIRMED,
    ].includes(assignment.status),
  );

/**
 * Check if plan agreement confirmation is required in the settings
 */
export const isPlanAgreementConfirmationRequired = (planAgreement: PlanAgreement): boolean =>
  PlanAgreementConfirmationMethod.NO_CONFIRMATION !== planAgreement.confirmationMethod;

/**
 * Check if plan agreement is confirmable
 */
export const isPlanAgreementConfirmable = (
  planAgreement: PlanAgreement,
  planAgreementAssignment: PlanAgreementAssignment,
): boolean => {
  if (PlanAgreementStatus.FINALIZED !== planAgreement.status) return false;

  return PlanAgreementAssignmentStatus.AGREEMENT_SENT === planAgreementAssignment.status;
};

/**
 * Check if plan agreement is deletable
 */
export const isPlanAgreementDeletable = (planAgreement: PlanAgreement): boolean =>
  PlanAgreementStatus.DRAFT === planAgreement.status;

/**
 * Check if a plan agreement is finalazable
 */
export const isPlanAgreementFinalizable = (planAgreement: PlanAgreement): boolean =>
  PlanAgreementStatus.DRAFT === planAgreement.status &&
  [
    planAgreement.internalGoogleDocId,
    planAgreement.name,
    planAgreement.plan,
    planAgreement.year,
    !planAgreement.error,
    !planAgreement.plan?.archived,
  ].every(Boolean);

/**
 * Check if plan agreement is modifiable
 */
export const isPlanAgreementModifiable = (planAgreement: PlanAgreement): boolean =>
  PlanAgreementStatus.DRAFT === planAgreement.status;

/**
 * Check if plan agreement is previewable
 */
export const isPlanAgreementPreviewable = (planAgreement: PlanAgreement): boolean =>
  PlanAgreementStatus.DRAFT === planAgreement.status &&
  [
    planAgreement.internalGoogleDocId,
    planAgreement.name,
    planAgreement.plan,
    planAgreement.year,
    !planAgreement.error,
  ].every(Boolean);

/**
 * Check if plan agreement is shippable (can be sent to the sales reps)
 */
export const isPlanAgreementShippable = (planAgreement: PlanAgreement): boolean =>
  PlanAgreementStatus.FINALIZED === planAgreement.status &&
  planAgreement.planAgreementAssignments?.some(
    (assignment) => assignment.status === PlanAgreementAssignmentStatus.AGREEMENT_GENERATED,
  );

/**
 * Check if plan agreement is shippable (can be sent to the sales reps)
 */
export const isPlanAgreementTemplateRefreshable = (planAgreement: PlanAgreement): boolean =>
  PlanAgreementStatus.DRAFT === planAgreement.status && !!planAgreement.externalGoogleDocId;
