import { defineMessages } from 'react-intl';

export const payoutAndPerformanceChartMessages = defineMessages({
  COMMISSION: {
    defaultMessage: 'Commission',
  },
  COMMISSION_AND_PERFORMANCE_EVOLUTION: {
    defaultMessage: 'Commission & performance evolution',
  },
  LAST_YEAR: {
    defaultMessage: 'Last year',
  },
  YTD: {
    defaultMessage: 'YTD',
  },
});
