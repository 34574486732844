import {
  type DataConnectorTypes,
  type ConnectorResponse,
  type CreateDataConnectorRequest,
  DataConnectorCategories,
  type DataConnectorObject,
  type DataConnectorObjectField,
  type PatchDataConnectorRequest,
} from '@amal-ia/data-capture/connectors/types';
import { http } from '@amal-ia/frontend/kernel/http';

const apiEndpoint = '/connectors';

export class DataConnectorsRepository {
  /**
   * connector OAuth Authorize.
   * @param connectorType
   * @param query all query params from redirect url
   */
  public static async authorize(query: string, connectorType: DataConnectorTypes): Promise<void> {
    const { data } = await http.get(`${apiEndpoint}/${connectorType}/authorize${query}`);

    localStorage.setItem(`${connectorType}_oauth`, data[`${connectorType}_oauth`]);
  }

  public static async getAuthorizationUrl(id: string): Promise<string> {
    const { data } = await http.get<{ url: string }>(`${apiEndpoint}/${id}/authorizationUrl`);
    return data.url;
  }

  /**
   * Create data connector.
   * @param dataConnector
   */
  public static async create(dataConnector: CreateDataConnectorRequest): Promise<ConnectorResponse> {
    const token = localStorage.getItem(`${dataConnector.type}_oauth`);
    const response = await http.post<ConnectorResponse>(`${apiEndpoint}`, dataConnector, {
      headers: {
        OAuthAuthorization: token || '',
      },
    });
    return response.data;
  }

  /**
   * Update data connector.
   * @param id
   * @param dataConnector
   */
  public static async patch(id: string, dataConnector: PatchDataConnectorRequest): Promise<ConnectorResponse> {
    const token = localStorage.getItem(`${dataConnector.type}_oauth`);
    const response = await http.patch<ConnectorResponse>(`${apiEndpoint}/${id}`, dataConnector, {
      headers: {
        OAuthAuthorization: token || '',
      },
    });

    return response.data;
  }

  /**
   * Logouts a connector
   * @param id
   * @returns dataConnector
   */
  public static async logout(id: string): Promise<void> {
    return http.patch(`${apiEndpoint}/${id}/logout`);
  }

  /**
   * List data connectors.
   */
  public static async list(): Promise<ConnectorResponse[]> {
    const response = await http.get<ConnectorResponse[]>(`${apiEndpoint}`);
    return response.data;
  }

  /**
   * Get connector by type.
   * @param type
   * @param category
   */
  public static async getConnector(
    type: string,
    category: DataConnectorCategories = DataConnectorCategories.DATA,
  ): Promise<ConnectorResponse | undefined> {
    const connectors = await DataConnectorsRepository.list();
    return connectors.find((connector) => connector.type === type && connector.category === category);
  }

  /**
   * List connector supported objects.
   */
  public static async listObjects(id: string): Promise<DataConnectorObject[]> {
    const response = await http.get<DataConnectorObject[]>(`${apiEndpoint}/${id}/objects`);
    return response.data;
  }

  /**
   * List object fields.
   * @param id
   * @param objectName
   */
  public static async listObjectFields(id: string, objectName: string): Promise<DataConnectorObjectField[]> {
    const response = await http.get<DataConnectorObjectField[]>(`${apiEndpoint}/${id}/objects/${objectName}/fields`);
    return response.data;
  }
}
