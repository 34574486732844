import { type FormikFieldProps, useFormikFieldAdapter } from '@amal-ia/ext/formik';

import { type RowData } from '../../../Table.types';
import { CellQuickSwitch, type CellQuickSwitchProps } from '../CellQuickSwitch';
import { type CellQuickSwitchOption } from '../CellQuickSwitch.types';

export type FormikCellQuickSwitchProps<
  TOption extends CellQuickSwitchOption = CellQuickSwitchOption,
  TRowData extends RowData = RowData,
> = FormikFieldProps<CellQuickSwitchProps<TOption, TRowData>>;

export const FormikCellQuickSwitch = function FormikCellQuickSwitch<
  TOption extends CellQuickSwitchOption = CellQuickSwitchOption,
  TRowData extends RowData = RowData,
>({ validate, ...props }: FormikCellQuickSwitchProps<TOption, TRowData>) {
  const { ...formikFieldProps } = useFormikFieldAdapter<CellQuickSwitchProps<TOption, TRowData>['value']>({
    validate,
    ...props,
  });

  return (
    <CellQuickSwitch
      {...props}
      {...formikFieldProps}
    />
  );
};
