export const AUDIT_ACTIONS = {
  START: 'AUDIT/START',
  START_FILTERS: 'AUDIT/START_FILTERS',
  START_DATA: 'AUDIT/START_DATA',
  START_COUNT: 'AUDIT/START_COUNT',
  ERROR: 'AUDIT/ERROR',

  CLEAR_RECORDS: 'AUDIT/CLEAR_RECORDS',

  SET_FILTERS: 'AUDIT/SET_FILTERS',
  SET_RECORDS: 'AUDIT/SET_RECORDS',
  SET_RECORDS_COUNT: 'AUDIT/SET_RECORDS_COUNT',
} as const;
