import { combineReducers } from 'redux';

import { INITIAL_REDUX_PAGINATION, type PaginationStoredInRedux, type TodoDefinition } from '@amal-ia/lib-types';

import { TODOS_ACTIONS } from './constants';

export default combineReducers({
  isLoading: (state: number = 0, action) => {
    switch (action.type) {
      case TODOS_ACTIONS.START:
        return state + 1;
      case TODOS_ACTIONS.ERROR:
      case TODOS_ACTIONS.SET_TODOS_COUNT:
      case TODOS_ACTIONS.SET_TODOS:
        return state - 1;
      default:
        return state;
    }
  },
  count: (state: number | null = null, action) => {
    switch (action.type) {
      case TODOS_ACTIONS.SET_TODOS_COUNT:
        return action.payload.todosCount;
      default:
        return state;
    }
  },
  list: (state: PaginationStoredInRedux<Partial<TodoDefinition>> = INITIAL_REDUX_PAGINATION, action) => {
    switch (action.type) {
      case TODOS_ACTIONS.SET_TODOS:
        return action.payload.todos;
      default:
        return state;
    }
  },
});
