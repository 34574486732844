import { type Theme, css } from '@emotion/react';

export const backButton = (theme: Theme) => css`
  padding: 0 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${theme.ds.colors.gray[0]};
  box-shadow: ${theme.ds.shadows.soft};

  border: 1px solid ${theme.ds.colors.gray[100]};
  border-radius: ${theme.ds.borderRadiuses.round};
  height: 100%;
  width: 50px;

  transition: ${theme.ds.transitions.default('background-color', 'box-shadow', 'color')};

  cursor: pointer;

  color: ${theme.ds.colors.gray[800]};

  &:hover,
  &:active {
    background-color: ${theme.ds.colors.secondary[50]};
  }

  &:active {
    color: ${theme.ds.colors.secondary[500]};
  }

  &:focus-visible {
    box-shadow: ${theme.ds.shadows.focusMedium(theme.ds.colors.secondary[50])};

    outline: 1px solid ${theme.ds.colors.secondary[200]};
    outline-offset: -1px;
  }
`;
