import { useQuery } from '@tanstack/react-query';

import { findStatementByCriteria } from '@amal-ia/frontend/web-data-layers';

enum STATEMENT_QUERY_KEYS {
  STATEMENT = 'STATEMENT',
}

export const useStatementByPlanPeriodUser = (planId: string, userId: string, periodId: string) =>
  useQuery({
    enabled: !!planId && !!userId && !!periodId,
    queryKey: [STATEMENT_QUERY_KEYS.STATEMENT, planId, userId, periodId],
    queryFn: async () => findStatementByCriteria(periodId, planId, userId),
  });
