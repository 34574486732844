import { type ActionCreator } from 'redux';

import { type ReduxAction, type SlackChannel, type ThunkResult } from '@amal-ia/lib-types';

import { getAllSlackChannels } from '../../services/flows/flowSlack.repository';

import { SLACK_ACTIONS } from './constants';

const slackStart: ActionCreator<ReduxAction> = () => ({
  type: SLACK_ACTIONS.START,
});

const slackError: ActionCreator<ReduxAction> = (error: Error) => ({
  type: SLACK_ACTIONS.ERROR,
  error,
});

const setChannels: ActionCreator<ReduxAction> = (channels: SlackChannel[]) => ({
  type: SLACK_ACTIONS.SET_CHANNELS,
  payload: channels,
});

export const fetchChannels = (): ThunkResult<Promise<ReduxAction>> => async (dispatch) => {
  dispatch(slackStart());

  try {
    const channels = await getAllSlackChannels();
    return dispatch(setChannels(channels));
  } catch (error) {
    return dispatch(slackError(error));
  }
};
