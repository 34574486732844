/**
 * Theme color categories are agnostic of their hues.
 * For example, when using the theme, you want to use the primary color, regardless of the fact that
 * the primary color can be yellow, orange, purple or else.
 */
export enum ColorCategory {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
  DANGER = 'danger',
  GRAY = 'gray',
  GRAY_SECONDARY = 'graySecondary',
}

export type ColorValue = string;

export type ThemeColors = {
  [ColorCategory.PRIMARY]: {
    50: ColorValue;
    100: ColorValue;
    200: ColorValue;
    300: ColorValue;
    400: ColorValue;
    500: ColorValue;
    700: ColorValue;
  };

  [ColorCategory.SECONDARY]: {
    50: ColorValue;
    100: ColorValue;
    200: ColorValue;
    300: ColorValue;
    400: ColorValue;
    500: ColorValue;
    700: ColorValue;
  };

  [ColorCategory.INFO]: {
    50: ColorValue;
    100: ColorValue;
    300: ColorValue;
    500: ColorValue;
    700: ColorValue;
    900: ColorValue;
  };

  [ColorCategory.SUCCESS]: {
    50: ColorValue;
    100: ColorValue;
    300: ColorValue;
    500: ColorValue;
    700: ColorValue;
    900: ColorValue;
  };

  [ColorCategory.WARNING]: {
    50: ColorValue;
    100: ColorValue;
    300: ColorValue;
    500: ColorValue;
    700: ColorValue;
    900: ColorValue;
  };

  [ColorCategory.DANGER]: {
    50: ColorValue;
    100: ColorValue;
    300: ColorValue;
    500: ColorValue;
    700: ColorValue;
    900: ColorValue;
  };

  [ColorCategory.GRAY]: {
    0: ColorValue;
    50: ColorValue;
    100: ColorValue;
    200: ColorValue;
    300: ColorValue;
    400: ColorValue;
    500: ColorValue;
    600: ColorValue;
    700: ColorValue;
    800: ColorValue;
    900: ColorValue;
  };

  [ColorCategory.GRAY_SECONDARY]: {
    50: ColorValue;
    100: ColorValue;
  };
};

/**
 * In some occasions, it can be useful to use the actual color you want.
 */
export enum HueCategory {
  BLUE = 'blue',
  GREEN = 'green',
  ORANGE = 'orange',
  RED = 'red',
  CYAN = 'cyan',
  MAGENTA = 'magenta',
  BROWN = 'brown',
  YELLOW = 'yellow',
  PURPLE = 'purple',
}

export type ThemeHues = {
  [HueCategory.BLUE]: {
    50: ColorValue;
    100: ColorValue;
    300: ColorValue;
    500: ColorValue;
    700: ColorValue;
    900: ColorValue;
  };

  [HueCategory.GREEN]: {
    50: ColorValue;
    100: ColorValue;
    300: ColorValue;
    500: ColorValue;
    700: ColorValue;
    900: ColorValue;
  };

  [HueCategory.ORANGE]: {
    50: ColorValue;
    100: ColorValue;
    300: ColorValue;
    500: ColorValue;
    700: ColorValue;
    900: ColorValue;
  };

  [HueCategory.RED]: {
    50: ColorValue;
    100: ColorValue;
    300: ColorValue;
    500: ColorValue;
    700: ColorValue;
    900: ColorValue;
  };

  [HueCategory.CYAN]: {
    50: ColorValue;
    100: ColorValue;
    300: ColorValue;
    700: ColorValue;
  };

  [HueCategory.MAGENTA]: {
    50: ColorValue;
    100: ColorValue;
    300: ColorValue;
    700: ColorValue;
  };

  [HueCategory.BROWN]: {
    50: ColorValue;
    100: ColorValue;
    300: ColorValue;
    700: ColorValue;
  };

  [HueCategory.YELLOW]: {
    50: ColorValue;
    100: ColorValue;
    200: ColorValue;
    300: ColorValue;
    400: ColorValue;
    500: ColorValue;
    700: ColorValue;
  };

  [HueCategory.PURPLE]: {
    50: ColorValue;
    100: ColorValue;
    200: ColorValue;
    300: ColorValue;
    400: ColorValue;
    500: ColorValue;
    700: ColorValue;
  };
};
