import { type Theme, css } from '@emotion/react';

export const computeButtonTooltip = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const calculationProgress = (theme: Theme) => css`
  margin: 0;
  margin-right: 8px;
  color: ${theme.ds.colors.gray[300]};
`;
