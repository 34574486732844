import { combineReducers } from 'redux';

import {
  type Overwrite,
  INITIAL_REDUX_PAGINATION,
  type PaginationStoredInRedux,
  type ReduxAction,
} from '@amal-ia/lib-types';

import { OVERWRITES_ACTIONS } from './constants';

export default combineReducers({
  isLoading: (state: number = 0, action) => {
    switch (action.type) {
      case OVERWRITES_ACTIONS.START:
        return state + 1;
      case OVERWRITES_ACTIONS.ERROR:
      case OVERWRITES_ACTIONS.CLEAR_OVERWRITE:
      case OVERWRITES_ACTIONS.SET_OVERWRITES:
        return state - 1;
      default:
        return state;
    }
  },
  listDetails: (state: PaginationStoredInRedux<Partial<Overwrite>> = INITIAL_REDUX_PAGINATION, action: ReduxAction) => {
    switch (action.type) {
      case OVERWRITES_ACTIONS.SET_OVERWRITES:
        return action.payload.list;
      default:
        return state;
    }
  },
  pageOptions: (state: PaginationStoredInRedux<Partial<Overwrite>> = INITIAL_REDUX_PAGINATION, action: ReduxAction) => {
    switch (action.type) {
      case OVERWRITES_ACTIONS.SET_OVERWRITES:
        return action.payload.pageOptions;
      default:
        return state;
    }
  },
});
