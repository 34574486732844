import { type ActionCreator } from 'redux';

import {
  type PaymentAggregatedList,
  type PaymentCategory,
  type ReduxAction,
  type ThunkResult,
} from '@amal-ia/lib-types';

import * as PaymentsRepository from '../../services/payments/payments.repository';
import {
  getStatementPaymentsPerCategory,
  getStatementAmounts,
  getStatementPayments,
} from '../../services/statements/statementPayments.repository';

import { PAYMENTS_ACTIONS } from './constants';

const paymentsStart: ActionCreator<ReduxAction> = () => ({
  type: PAYMENTS_ACTIONS.START,
});

const paymentsError: ActionCreator<ReduxAction> = (error: Error) => ({
  type: PAYMENTS_ACTIONS.ERROR,
  error,
});

const setPaymentsForCurrentStatement: ActionCreator<ReduxAction> = (payments: Record<PaymentCategory, any[]>) => ({
  type: PAYMENTS_ACTIONS.SET_STATEMENT_PAYMENTS,
  payload: { payments },
});

export const clearPayments: ActionCreator<ReduxAction> = () => ({
  type: PAYMENTS_ACTIONS.CLEAR_PAYMENTS,
});

export const fetchPaymentsForStatement =
  (statementId: string): ThunkResult<Promise<ReduxAction>> =>
  async (dispatch) => {
    dispatch(paymentsStart());
    try {
      const payments = await getStatementPaymentsPerCategory(statementId);
      return dispatch(setPaymentsForCurrentStatement(payments));
    } catch (error) {
      return dispatch(paymentsError(error));
    }
  };

const setContextualizedPaymentsForCurrentStatement: ActionCreator<ReduxAction> = (payments: any[]) => ({
  type: PAYMENTS_ACTIONS.SET_CONTEXT_PAYMENTS,
  payload: { payments },
});

export const fetchContextualizedPaymentsForStatement =
  (statementId: string): ThunkResult<Promise<ReduxAction>> =>
  async (dispatch) => {
    dispatch(paymentsStart());
    try {
      const payments = await getStatementPayments(statementId);
      return dispatch(setContextualizedPaymentsForCurrentStatement(payments));
    } catch (error) {
      return dispatch(paymentsError(error));
    }
  };

const setPaymentAmountsForStatement: ActionCreator<ReduxAction> = (
  paymentAmounts: Record<PaymentCategory, number>,
) => ({
  type: PAYMENTS_ACTIONS.SET_STATEMENT_AMOUNTS,
  payload: { paymentAmounts },
});

export const fetchPaymentAmountsForStatement =
  (statementId: string): ThunkResult<Promise<ReduxAction>> =>
  async (dispatch) => {
    dispatch(paymentsStart());
    try {
      const amount = await getStatementAmounts(statementId);
      return dispatch(setPaymentAmountsForStatement(amount));
    } catch (error) {
      return dispatch(paymentsError(error));
    }
  };

const setPaymentAmountsForCompany: ActionCreator<ReduxAction> = (companyAmounts: PaymentAggregatedList) => ({
  type: PAYMENTS_ACTIONS.SET_COMPANY_AMOUNTS,
  payload: { companyAmounts },
});

export const fetchPaymentAmountsForCompany =
  (
    currentPeriodId?: string,
    periodIds?: string[] | null,
    teamId?: string | null,
    planIds?: string[] | null,
    userIds?: string[] | null,
    ruleIds?: string[] | null,
    paymentPeriodIds?: string[],
    type?: 'commissionReport' | null,
  ): ThunkResult<Promise<ReduxAction>> =>
  async (dispatch) => {
    dispatch(paymentsStart());
    try {
      const companyAmounts = await PaymentsRepository.getPaymentsForPeriod(
        currentPeriodId,
        periodIds,
        teamId,
        planIds,
        userIds,
        ruleIds,
        paymentPeriodIds,
        type,
      );
      return dispatch(setPaymentAmountsForCompany(companyAmounts));
    } catch (error) {
      return dispatch(paymentsError(error));
    }
  };

export const setPaymentCategory: ActionCreator<ReduxAction> = (category: PaymentCategory) => ({
  type: PAYMENTS_ACTIONS.SET_PAYMENT_CATEGORY,
  payload: { category },
});
