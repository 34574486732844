import { type EmptyObject } from 'type-fest';

export enum NotificationsTypes {
  NEW_COMMENT_ON_STATEMENT = 'NEW_COMMENT_ON_STATEMENT',
  NEW_EXPORT_AVAILABLE = 'NEW_EXPORT_AVAILABLE',
  NEW_PLAN_AGREEMENT = 'NEW_PLAN_AGREEMENT',
  REVIEW_STATEMENT = 'REVIEW_STATEMENT',
  WORKFLOW_PROCESS_FINISHED = 'WORKFLOW_PROCESS_FINISHED',
}

export interface ExportNotificationPayload {
  exportId: string;
}

export interface ReviewStatementPayload {
  statementId: string;
}

// CONTENT METADATA
export interface NewCommentOnStatementMetadata {
  statementId: string;
  statementUserFullName?: string;
  commentAuthorFullName: string;
}

export interface NewExportAvailableMetadata {
  dataExportId: string;
  isInError: boolean;
}

export interface ReviewStatementMetadata {
  statementId: string;
  statementPeriodName: string;
  reviewerFullName: string;
}

export type WorkflowProcessFinishedMetadata = EmptyObject;

export interface PlanAgreementToConfirmMetadata {
  planAgreementId: string;
  planAgreementName: string;
}

export type NotificationContentMetadata =
  | NewCommentOnStatementMetadata
  | NewExportAvailableMetadata
  | PlanAgreementToConfirmMetadata
  | ReviewStatementMetadata
  | WorkflowProcessFinishedMetadata;

export interface Notification {
  id: string;
  createdAt?: Date;
  content: string;
  contentMetadata?: NotificationContentMetadata;
  type: NotificationsTypes;
  link: string | null;
  payload?: ExportNotificationPayload;
  read: boolean;
  authorId: string | null;
}
