import { IconArrowLeft } from '@tabler/icons-react';
import { type ForwardedRef, forwardRef, memo, type ReactNode, type ComponentPropsWithoutRef } from 'react';

import { type MergeAll } from '@amal-ia/ext/typescript';

import { Tooltip } from '../../../overlays/tooltip/Tooltip';

import * as styles from './BreadcrumbsBackButton.styles';

export type BreadcrumbsBackButtonProps = MergeAll<
  [
    Omit<ComponentPropsWithoutRef<'button'>, 'children' | 'type'>,
    {
      label: ReactNode;
    },
  ]
>;

const BreadcrumbsBackButtonForwardRef = forwardRef(function BreadcrumbsBackButton(
  props: BreadcrumbsBackButtonProps,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  return (
    <Tooltip content={props.label}>
      <button
        {...props}
        ref={ref}
        css={styles.backButton}
        type="button"
      >
        <IconArrowLeft
          color="currentColor"
          size={19.2}
        />
      </button>
    </Tooltip>
  );
});

export const BreadcrumbsBackButton = memo(BreadcrumbsBackButtonForwardRef);
