import styled from '@emotion/styled';
import { IconPlug, IconPlugOff } from '@tabler/icons-react';

import { DataConnectorTypes } from '@amal-ia/data-capture/connectors/types';
import { type Asset } from '@amal-ia/ext/react/types';
import { HueCategory } from '@amal-ia/frontend/design-system/meta';
import { UserExternalIdSource } from '@amal-ia/tenants/users/shared/types';

import { type IntegrationLogos, VENDOR_LOGOS } from '../../../connectors.logos';

const OtherSourceLogo = styled(IconPlug)`
  color: ${({ theme }) => theme.ds.hues[HueCategory.BLUE][700]};
` as Asset;

export const userExternalIdSourceLogos: Readonly<Record<UserExternalIdSource, IntegrationLogos>> = {
  [UserExternalIdSource.NONE]: {
    Logo: IconPlugOff,
    CompactLogo: IconPlugOff,
  },
  [UserExternalIdSource.OTHERSOURCE]: {
    Logo: OtherSourceLogo,
    CompactLogo: OtherSourceLogo,
  },
  [UserExternalIdSource.SALESFORCE]: {
    ...VENDOR_LOGOS[DataConnectorTypes.SALESFORCE],
  },
  [UserExternalIdSource.HUBSPOT]: {
    ...VENDOR_LOGOS[DataConnectorTypes.HUBSPOT],
  },
  [UserExternalIdSource.ZOHO]: {
    ...VENDOR_LOGOS[DataConnectorTypes.ZOHO],
  },
};
