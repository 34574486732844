import { createSelector } from 'reselect';

import { type RootState } from '@amal-ia/lib-types';
import { type UserContract, type UsersMap } from '@amal-ia/tenants/users/shared/types';

import { isUserActive, sortUsers } from '../../services/users/users.service';

export const selectUsersMap = (state: RootState): UsersMap => state.users.map;

export const selectUsersList = createSelector([selectUsersMap], (map) => sortUsers(Object.values(map)));

export const selectActiveUsers = createSelector([selectUsersList], (users): UserContract[] =>
  users.filter((u) => isUserActive(u)),
);

export const selectIsUsersLoading = (state: RootState): boolean => !!state.users.isLoading;
