import styled from '@emotion/styled';

import { ModalSize } from '../Modal.types';

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;

  flex: 1;
  overflow-y: auto;

  transition: ${({ theme }) => theme.ds.transitions.default('padding', 'gap')};

  .${ModalSize.SMALL} & {
    padding: 16px 0;
    gap: 24px;
  }

  .${ModalSize.MEDIUM} & {
    padding: 24px 0;
    gap: 32px;
  }

  .${ModalSize.LARGE} & {
    padding: 32px 0;
    gap: 32px;
  }
`;
