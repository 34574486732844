import { type ActionCreator } from 'redux';

import { type Flow, type ReduxAction, type ThunkResult } from '@amal-ia/lib-types';

import * as FlowsRepository from '../../services/flows/flows.repository';
import { addSnackbar } from '../snackbars/actions';

import { FLOWS_ACTIONS } from './constants';
import { selectFlows } from './selectors';

const flowStart: ActionCreator<ReduxAction> = () => ({
  type: FLOWS_ACTIONS.START,
});

const flowError: ActionCreator<ReduxAction> = (error: Error) => ({
  type: FLOWS_ACTIONS.ERROR,
  error,
});

const setFlows: ActionCreator<ReduxAction> = (flows: Flow[]) => ({
  type: FLOWS_ACTIONS.SET_FLOWS,
  payload: { flows },
});

export const fetchFlowsAction =
  (force: boolean = false): ThunkResult<Promise<ReduxAction>> =>
  async (dispatch, getState) => {
    dispatch(flowStart());

    const oldState = selectFlows(getState());
    if ((oldState || []).length > 0 && !force) {
      return dispatch(setFlows(oldState));
    }

    try {
      const flows = await FlowsRepository.listFlows();
      return dispatch(setFlows(flows));
    } catch (error) {
      return dispatch(flowError(error));
    }
  };

const setFlow: ActionCreator<ReduxAction> = (flow: Flow) => ({
  type: FLOWS_ACTIONS.SET_FLOW,
  payload: { flow },
});

export const createFlowAction =
  (flow: Flow): ThunkResult<Promise<ReduxAction>> =>
  async (dispatch) => {
    dispatch(flowStart());

    try {
      const insertedFlow = await FlowsRepository.addFlow(flow);
      dispatch(addSnackbar({ message: 'Flow created!', options: { variant: 'success' } }));
      return dispatch(setFlow(insertedFlow));
    } catch (error) {
      return dispatch(flowError(error));
    }
  };

export const updateFlowAction =
  (flow: Flow): ThunkResult<Promise<ReduxAction>> =>
  async (dispatch) => {
    dispatch(flowStart());

    try {
      const updatedFlow = await FlowsRepository.updateFlow(flow);
      dispatch(addSnackbar({ message: 'Flow updated!', options: { variant: 'success' } }));
      return dispatch(setFlow(updatedFlow));
    } catch (error) {
      return dispatch(flowError(error));
    }
  };
