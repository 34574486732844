import { useMemo } from 'react';

import { fuzzyFilter } from '@amal-ia/frontend/kernel/search/filters';

import { type ColumnDefinitions } from '../../../table/Table.types';
import { getMenuColumnName } from '../../columns';

/**
 * Filter out columns that don't have a header, otherwise they have no name in the dropdown.
 * Filter out columns that are not sortable.
 */
export const useSortableColumns = (columns: ColumnDefinitions, searchText?: string) =>
  useMemo(
    () =>
      columns.filter((column) => {
        const columnName = getMenuColumnName(column);
        return !!columnName && !!(column.isSortable ?? column.sortingFn) && fuzzyFilter(columnName, searchText);
      }),
    [columns, searchText],
  );
