export const PLAN_AGREEMENTS_ACTIONS = {
  START: 'PLAN_AGREEMENTS/START',
  ERROR: 'PLAN_AGREEMENTS/ERROR',

  ARCHIVE_PLAN_AGREEMENT: 'PLAN_AGREEMENTS/ARCHIVE_PLAN_AGREEMENT',
  CONFIRM_PLAN_AGREEMENT: 'PLAN_AGREEMENTS/CONFIRM_PLAN_AGREEMENT',
  CREATE_PLAN_AGREEMENT: 'PLAN_AGREEMENTS/CREATE_PLAN_AGREEMENT',
  DELETE_PLAN_AGREEMENT: 'PLAN_AGREEMENTS/DELETE_PLAN_AGREEMENT',
  FINALIZE_PLAN_AGREEMENT: 'PLAN_AGREEMENTS/FINALIZE_PLAN_AGREEMENT',
  PATCH_PLAN_AGREEMENT: 'PLAN_AGREEMENTS/PATCH_PLAN_AGREEMENT',
  REGENERATE_AGREEMENTS: 'PLAN_AGREEMENTS/REGENERATE_AGREEMENTS',
  SEND_AGREEMENT: 'PLAN_AGREEMENTS/SEND_AGREEMENT',
  SEND_AGREEMENTS_NOT_SEND_YET: 'PLAN_AGREEMENTS/SEND_AGREEMENTS_NOT_SEND_YET',

  RESET_CURRENT_PLAN_AGREEMENT: 'PLAN_AGREEMENTS/RESET_CURRENT_PLAN_AGREEMENT',

  REFRESH_TEMPLATE: 'PLAN_AGREEMENTS/REFRESH_TEMPLATE',

  SET_CURRENT_PLAN_AGREEMENT: 'PLAN_AGREEMENTS/SET_CURRENT_PLAN_AGREEMENT',
  SET_PLAN_AGREEMENTS: 'PLAN_AGREEMENTS/SET_PLAN_AGREEMENTS',
} as const;

export type ActionTypes = typeof PLAN_AGREEMENTS_ACTIONS;
