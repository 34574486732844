import { useFormikContext } from 'formik';
import { memo, useCallback, useMemo } from 'react';

import { BooleanSelector } from '@amal-ia/data-capture/fields/components';

import { OverwriteFormField, type OverwriteFormValuesShape } from '../../overwrite.types';

import { type CommonOverwriteSelectorProps } from './selectors.types';

type BooleanStringType = '' | 'false' | 'true';

export const BooleanString: Record<'EMPTY' | 'FALSE' | 'TRUE', BooleanStringType> = {
  TRUE: 'true',
  FALSE: 'false',
  EMPTY: '',
} as const;

export type BooleanOverwriteSelectorProps = CommonOverwriteSelectorProps;

export const BooleanOverwriteSelector = memo(function BooleanOverwriteSelector({
  currentObjectDetails,
}: BooleanOverwriteSelectorProps) {
  const { values, setFieldValue } = useFormikContext<OverwriteFormValuesShape>();

  const selectedValue = useMemo(() => {
    const valueInForm = values.newValue as BooleanStringType;

    // Handle the case where the user has selected an overwritten value
    if ([BooleanString.TRUE, BooleanString.FALSE].includes(valueInForm)) return valueInForm;

    // Handle the case where the old value is not a boolean (undefined, null, etc.)
    if (typeof currentObjectDetails.oldValue !== 'boolean') return BooleanString.EMPTY;

    return currentObjectDetails.oldValue ? BooleanString.TRUE : BooleanString.FALSE;
  }, [currentObjectDetails.oldValue, values.newValue]);

  const handleChange = useCallback(
    (newValue: BooleanStringType) => {
      setFieldValue(OverwriteFormField.NEW_VALUE, newValue);
    },
    [setFieldValue],
  );

  return (
    <BooleanSelector
      name={OverwriteFormField.NEW_VALUE}
      value={selectedValue}
      onChange={handleChange}
    />
  );
});
