export const TEAMS_ACTIONS = {
  START: 'TEAMS/START',
  ERROR: 'TEAMS/ERROR',

  SET_TEAMS: 'TEAMS/SET_TEAMS',
  SET_TEAM: 'TEAMS/SET_TEAM',
  DELETE_TEAM: 'TEAMS/DELETE_TEAM',

  SET_TEAM_HIERARCHY: 'TEAMS/SET_TEAM_HIERARCHY',

  SET_TEAMS_ASSIGNMENTS: 'TEAMS/SET_TEAMS_ASSIGNMENTS',
  SET_TEAM_ASSIGNMENTS: 'TEAMS/SET_TEAM_ASSIGNMENTS',
  SET_TEAM_ASSIGNMENT: 'TEAMS/SET_TEAM_ASSIGNMENT',
  DELETE_TEAM_ASSIGNMENT: 'TEAMS/DELETE_TEAM_ASSIGNMENT',

  SET_TEAM_PLAN_ASSIGNMENTS: 'TEAMS/SET_TEAM_PLAN_ASSIGNMENTS',
  SET_TEAM_PLAN_ASSIGNMENT: 'TEAMS/SET_TEAM_PLAN_ASSIGNMENT',
  DELETE_TEAM_PLAN_ASSIGNMENT: 'TEAMS/DELETE_TEAM_PLAN_ASSIGNMENT',
} as const;
