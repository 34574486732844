import { Box, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { differenceBy, isEmpty } from 'lodash';
import { memo, type SetStateAction, useCallback, useMemo, type Dispatch, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

import { amaliaTheme, type AmaliaThemeType } from '@amal-ia/ext/mui/theme';
import { type Option } from '@amal-ia/lib-types';
import { CheckboxFieldBase, Spinner, SpinnerBoundary, Text, TextType } from '@amal-ia/lib-ui';

import { addRecordsModalMessages } from './AddRecordsModal.messages';

const styles = makeStyles((theme: AmaliaThemeType) => ({
  loadingContainer: {
    padding: theme.spacing(2),
    minHeight: 100,
  },
  drawerItem: {
    position: 'relative',

    '&, &$listElement': {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: theme.palette.grey['100'],
      padding: theme.spacing(1, 1.5),
      margin: theme.spacing(0.5, 0),

      '&:hover $drawerItemActions, &:hover $drawerItemAction': {
        opacity: 1,
      },
    },
  },
}));

type SearchRecordResultsProps = Readonly<{
  foundRecords: Option[];
  isSearchRecordsLoading: boolean;
  selectedRecords: Option[];

  setSelectedRecords: Dispatch<SetStateAction<Option[]>>;
}>;

export const SearchRecordResults = memo(function SearchRecordResults({
  foundRecords,
  isSearchRecordsLoading,
  selectedRecords,
  setSelectedRecords,
}: SearchRecordResultsProps) {
  const classes = styles();

  const availableRecords = useMemo(
    () => differenceBy(foundRecords, selectedRecords, 'value'),
    [foundRecords, selectedRecords],
  );

  /**
   * Select record from search results. Record will appear in selected records.
   */
  const selectRecord = useCallback(
    (record: Option) => {
      setSelectedRecords((currentSelectedRecords) => [...currentSelectedRecords, record]);
    },
    [setSelectedRecords],
  );

  /**
   * Unselect record from selected records.
   */
  const unSelectRecord = useCallback(
    (record: Option) => {
      setSelectedRecords((currentSelectedRecords) => differenceBy(currentSelectedRecords, [record], 'value'));
    },
    [setSelectedRecords],
  );

  return (
    <Fragment>
      {isSearchRecordsLoading ? (
        <Box className={classes.loadingContainer}>
          <SpinnerBoundary>
            <Spinner />
          </SpinnerBoundary>
        </Box>
      ) : (
        <Box py={amaliaTheme.spacing(1)}>
          {availableRecords.map((record) => (
            <div
              key={record.value}
              className={classes.drawerItem}
            >
              <CheckboxFieldBase
                color="primary"
                label={record.label}
                onChange={() => selectRecord(record)}
              />
            </div>
          ))}
        </Box>
      )}

      {!isEmpty(selectedRecords) && (
        <Fragment>
          <Box py={amaliaTheme.spacing(1)}>
            <Divider />
          </Box>
          <Box py={amaliaTheme.spacing(1)}>
            <Text type={TextType.MODAL_TITLE}>
              <FormattedMessage {...addRecordsModalMessages.SELECTED_RECORDS} />
            </Text>
          </Box>
          <Box py={amaliaTheme.spacing(1)}>
            {selectedRecords.map((record) => (
              <div
                key={record.value}
                className={classes.drawerItem}
              >
                <CheckboxFieldBase
                  checked
                  color="primary"
                  label={record.label}
                  onChange={() => unSelectRecord(record)}
                />
              </div>
            ))}
          </Box>
        </Fragment>
      )}
    </Fragment>
  );
});
