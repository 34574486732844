import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  USER_STATEMENTS: { defaultMessage: '{user} statements' },
  COMMENT_THREAD_NOT_EXIST: { defaultMessage: 'This comment thread does not exist anymore' },
  WORKFLOW_DEBUGGER: { defaultMessage: 'Workflow debugger' },
  NO_AUTHORIZATION_TO_VIEW_STATEMENT: { defaultMessage: "You don't have the authorization to view this statement" },
  SIMULATION_IS_AVAILABLE: { defaultMessage: 'Simulation is now available' },
  SIMULATION_DESCRIPTION: {
    defaultMessage: 'Learn what your payout will be by editing a value or by adding a new deal from your CRM.',
  },
  SIMULATION_LEARN_MORE: { defaultMessage: 'Learn more' },
} as const);
