import { http } from '@amal-ia/frontend/kernel/http';
import {
  type CreateDatasetOverwriteRequest,
  type AddDatasetRowsRequest,
  type DatasetRow,
  type DeleteDatasetOverwriteRequest,
  type PaginatedQuery,
  type PaginatedResponse,
  type StatementDataset,
  type Overwrite,
} from '@amal-ia/lib-types';

const apiEndpoint = '/statements';

/**
 * Fetch all datasets of the statement without pagination.
 *
 * Please don't use it elsewhere than in the tracing or God will kill a kitten.
 * I struggled a lot to make the paginated endpoint performant, use that one instead,
 * and delete this method and the endpoint when the tracing is in v2.
 *
 * @deprecated
 */
export const fetchDatasets = async (statementId: string): Promise<StatementDataset[]> => {
  const { data } = await http.get<StatementDataset[]>(`${apiEndpoint}/${statementId}/datasets`);

  return data;
};

export const fetchPaginatedDatasetRows = async (
  statementId: string,
  datasetId: string,
  paginationOptions: PaginatedQuery,
  forecasted?: boolean,
): Promise<PaginatedResponse<DatasetRow>> => {
  const { data } = await http.get<PaginatedResponse<DatasetRow>>(
    `${apiEndpoint}/${statementId}/datasets/${datasetId}/rows`,
    {
      params: {
        ...paginationOptions,
        forecasted,
      },
    },
  );

  return data;
};

export const deleteDatasetRow = async (
  statementId: string,
  datasetId: string,
  rowExternalId: string,
  deleteDatasetOverwriteRequest: DeleteDatasetOverwriteRequest,
): Promise<void> => {
  await http.delete(`${apiEndpoint}/${statementId}/datasets/${datasetId}/rows/${rowExternalId}`, {
    data: deleteDatasetOverwriteRequest,
  });
};

/**
 * Add rows to a dataset.
 * @param statementId
 * @param datasetId
 * @param addDatasetRowsRequest
 */
export const addDatasetRows = async (
  statementId: string,
  datasetId: string,
  addDatasetRowsRequest: AddDatasetRowsRequest,
): Promise<DatasetRow[]> => {
  const { data } = await http.post<DatasetRow[]>(
    `${apiEndpoint}/${statementId}/datasets/${datasetId}/rows`,
    addDatasetRowsRequest,
  );

  return data;
};

export const createStatementDatasetOverwrite = async (
  statementId: string,
  datasetId: string,
  createStatementOverwriteRequest: CreateDatasetOverwriteRequest,
): Promise<Overwrite> => {
  const { data } = await http.post<Overwrite>(
    `${apiEndpoint}/${statementId}/datasets/${datasetId}/overwrites`,
    createStatementOverwriteRequest,
  );
  return data;
};
