import { IconCheck, IconPlayerPause, IconPlayerPlay, IconPlayerStop, IconX } from '@tabler/icons-react';

import { type TablerIconElement } from '@amal-ia/frontend/design-system/components';
import { CalculationStatus } from '@amal-ia/lib-types';

export const iconForCalculationStatus: Record<string, TablerIconElement> = {
  [CalculationStatus.PENDING]: <IconPlayerPause />,
  [CalculationStatus.STARTED]: <IconPlayerPlay />,
  [CalculationStatus.STOPPING]: <IconPlayerStop />,
  [CalculationStatus.STOPPED]: <IconPlayerStop />,
  [CalculationStatus.SUCCESS]: <IconCheck />,
  [CalculationStatus.ERROR]: <IconX />,
  [CalculationStatus.INCOMPLETE]: <IconX />,
};
