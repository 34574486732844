import { combineReducers } from 'redux';

import { type ReduxAction, type SlackChannel } from '@amal-ia/lib-types';

import { SLACK_ACTIONS } from './constants';

export default combineReducers({
  isLoading: (state: number = 0, action: ReduxAction) => {
    switch (action.type) {
      case SLACK_ACTIONS.START:
        return state + 1;
      case SLACK_ACTIONS.ERROR:
      case SLACK_ACTIONS.SET_CHANNELS:
        return state - 1;
      default:
        return state;
    }
  },
  channels: (state: SlackChannel[] = [], action: ReduxAction) => {
    switch (action.type) {
      case SLACK_ACTIONS.SET_CHANNELS:
        return action.payload;
      default:
        return state;
    }
  },
});
