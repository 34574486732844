import { useTheme } from '@emotion/react';
import {
  IconAlertCircleFilled,
  IconAlertTriangleFilled,
  IconCircleCheckFilled,
  IconInfoCircleFilled,
  IconX,
} from '@tabler/icons-react';
import { useSnackbar, type CustomContentProps, SnackbarContent } from 'notistack';
import { type ForwardedRef, forwardRef, memo, cloneElement, useCallback } from 'react';

import { type TablerIconElement, Typography, UnstyledButton } from '@amal-ia/frontend/design-system/components';

import * as styles from './Snackbar.styles';

const VARIANT_ICON_MAPPING: Record<CustomContentProps['variant'], TablerIconElement> = {
  default: <IconInfoCircleFilled />,
  info: <IconInfoCircleFilled />,
  error: <IconAlertCircleFilled />,
  warning: <IconAlertTriangleFilled />,
  success: <IconCircleCheckFilled />,
};

export type SnackbarProps = CustomContentProps;

const SnackbarForwardRef = forwardRef(function Snackbar(
  { message, variant, id, hideIconVariant }: SnackbarProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const { closeSnackbar } = useSnackbar();
  const theme = useTheme();

  const handleClose = useCallback(() => {
    closeSnackbar(id);
  }, [closeSnackbar, id]);

  return (
    <SnackbarContent ref={ref}>
      <div
        className={variant}
        css={styles.snackbar}
      >
        <div css={styles.snackbarContent}>
          {!hideIconVariant &&
            cloneElement(VARIANT_ICON_MAPPING[variant], {
              size: 24,
              color: 'currentColor',
              className: styles.ICON_CLASSNAME,
            })}

          <Typography
            css={styles.text}
            variant={Typography.Variant.BODY_LARGE_MEDIUM}
          >
            {message}
          </Typography>
        </div>

        <UnstyledButton
          css={styles.closeButton}
          onClick={handleClose}
        >
          <IconX
            color={theme.ds.colors.gray[800]}
            size={16}
          />
        </UnstyledButton>
      </div>
    </SnackbarContent>
  );
});

export const Snackbar = memo(SnackbarForwardRef);
