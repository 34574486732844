import { isEmpty, lowerCase, trim, upperCase } from 'lodash';

import { CurrencySymbolsEnum } from '@amal-ia/ext/iso-4217';
import { LanguagesEnum } from '@amal-ia/kernel/intl/types';
import { formatUserFullName } from '@amal-ia/lib-types';
import { type MinUserType, type UserContract, UserRole } from '@amal-ia/tenants/users/shared/types';

export function isUserHaveCompany(user: UserContract): boolean {
  return !!(user?.company?.id && typeof user.company.id !== 'undefined');
}

export const sortUsersComparator = (a: MinUserType, b: MinUserType) =>
  formatUserFullName(a).localeCompare(formatUserFullName(b));

export const sortUsers = <T extends MinUserType>(users: T[]): T[] => [...users].sort(sortUsersComparator);

export const isUserActive = (user: UserContract) => !!user && !user.clearedAt;

const mapUserLanguage = (value: string): string => {
  const language = lowerCase(value);

  if (!Object.keys(LanguagesEnum).includes(language)) {
    throw new Error(`Unknown language ${value}`);
  }

  return language;
};

const mapUserRole = (value: string): string => {
  const role = upperCase(value);

  if (!Object.keys(UserRole).includes(role)) {
    throw new Error(`Unknown role ${value}`);
  }

  return role;
};

const mapCurrencyCode = (value: string) => {
  const currencyCode = upperCase(value);

  if (!Object.keys(CurrencySymbolsEnum).includes(currencyCode)) {
    throw new Error(`Unknown currency ${value}`);
  }

  return currencyCode;
};

/**
 * Validate and Map user field value from csv.
 * @param value
 * @param field
 */
export const mapCsvUserField = (value: string, field: number | string): any => {
  const sanitizedValue = trim(value);

  if (isEmpty(sanitizedValue)) {
    return undefined;
  }

  switch (field) {
    case 'language':
      return mapUserLanguage(sanitizedValue);
    case 'role':
      return mapUserRole(sanitizedValue);
    case 'currency':
      return mapCurrencyCode(sanitizedValue);
    default:
      return sanitizedValue;
  }
};
