import { memo } from 'react';

import { type KeysOfUserWithStringValues } from '@amal-ia/tenants/users/shared/types';

import { UserFetchContainer, type UserFetchContainerProps } from '../fetch-container/UserFetchContainer';
import { UserNullValue } from '../null-value/UserNullValue';
import { USER_NULL_VALUE } from '../types';

export type UserContainerProps<TProperty extends KeysOfUserWithStringValues> = UserFetchContainerProps<TProperty>;

export const UserContainerBase = function UserContainerBase<TProperty extends KeysOfUserWithStringValues>(
  props: UserContainerProps<TProperty>,
) {
  const { value } = props;

  if (value.toLowerCase() === USER_NULL_VALUE) {
    return <UserNullValue {...props} />;
  }

  return <UserFetchContainer {...props} />;
};

export const UserContainer = memo(UserContainerBase) as typeof UserContainerBase;
