import { Box } from '@mui/system';
import { Fragment, memo, useCallback } from 'react';
import { FormattedList, FormattedMessage } from 'react-intl';

import { AlertBanner } from '@amal-ia/frontend/design-system/components';
import { ConfirmationModal } from '@amal-ia/lib-ui';

import { useWorkflowListContext } from '../WorkflowList/useWorkflowListContext';

export const DeleteWorkflowConfirmationModal = memo(function DeleteWorkflowConfirmationModal() {
  const {
    selectedWorkflowPlans,
    isLoadingStatementsUnderReview,
    isWorkflowToDelete,
    areStatementsBeingReview,
    setWorkflowToDelete,
    actions,
  } = useWorkflowListContext();

  const handleDeleteModalClose = useCallback(
    () => setWorkflowToDelete({ rowId: null, name: isWorkflowToDelete?.name }),
    [isWorkflowToDelete?.name, setWorkflowToDelete],
  );

  const onClickDeleteCallback = useCallback(() => {
    actions.onClickDelete(isWorkflowToDelete?.rowId);
    setWorkflowToDelete({ rowId: null, name: isWorkflowToDelete?.name });
  }, [actions, isWorkflowToDelete?.name, isWorkflowToDelete?.rowId, setWorkflowToDelete]);

  return (
    <ConfirmationModal
      isDeleteAction
      cancelText={<FormattedMessage defaultMessage="Cancel" />}
      confirmDataTestId="delete"
      confirmText={<FormattedMessage defaultMessage="Delete" />}
      handleClose={handleDeleteModalClose}
      handleConfirm={onClickDeleteCallback}
      isDisabled={areStatementsBeingReview}
      isLoading={isLoadingStatementsUnderReview}
      isOpened={!!isWorkflowToDelete?.rowId}
      title={<FormattedMessage defaultMessage="Delete workflow" />}
      contentText={
        <Fragment>
          {areStatementsBeingReview ? (
            <Box marginBottom={5}>
              <AlertBanner variant={AlertBanner.Variant.WARNING}>
                <FormattedMessage
                  defaultMessage="This workflow is set on plans {plans}.{br}Some of these plans statements are currently being reviewed. Please, either unreview or review them all before."
                  values={{
                    plans: (
                      <FormattedList
                        style="short"
                        type="unit"
                        value={(selectedWorkflowPlans || []).map((p) => p.name)}
                      />
                    ),
                  }}
                />
              </AlertBanner>
            </Box>
          ) : null}
          <FormattedMessage
            defaultMessage="Are you sure you want to delete the workflow “<b>{workflowName}</b>”?"
            values={{ workflowName: isWorkflowToDelete?.name, b: (chunks) => <b>{chunks}</b> }}
          />
        </Fragment>
      }
    />
  );
});
