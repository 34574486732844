import { memo, useCallback } from 'react';

import { type MergeAll } from '@amal-ia/ext/typescript';

import { DropdownGroup, type DropdownGroupProps } from '../../dropdown/dropdown-group/DropdownGroup';
import { useHasEveryOptionSelected } from '../hooks/useHasEveryOptionSelected';
import { useHasSomeOptionSelected } from '../hooks/useHasSomeOptionSelected';
import { PaginatedSelectDropdownItemsList } from '../paginated-select-dropdown-items-list/PaginatedSelectDropdownItemsList';
import { type SelectDropdownItemProps } from '../select-dropdown-item/SelectDropdownItem';
import {
  SelectDropdownSelectAllItem,
  type SelectDropdownSelectAllItemProps,
} from '../select-dropdown-select-all-item/SelectDropdownSelectAllItem';
import { type SelectDropdownOption, type SelectOptionGroup } from '../SelectDropdown.types';

export type SelectDropdownGroupProps<
  TOption extends SelectDropdownOption = SelectDropdownOption,
  TIsMultiple extends boolean | undefined = undefined,
  TGroup extends SelectOptionGroup<TOption> = SelectOptionGroup<TOption>,
> = MergeAll<
  [
    Omit<DropdownGroupProps, 'children' | 'countBadge' | 'initialIsOpen' | 'label'>,
    {
      /** Is is a multi-select. */
      isMultiple?: TIsMultiple;
      /** Group to render. */
      group: TGroup;
      /** Values of checked options. */
      checkedOptionsValues: TOption['value'][];
      /** Select all checked change handler. */
      onSelectAllChange: (group: TGroup, selectAllChecked: boolean) => void;
      /** Option checked change handler. */
      onOptionChange: SelectDropdownItemProps<TOption, TIsMultiple>['onChange'];
      /** Should hide the select all. */
      hideSelectAll?: boolean;
    },
  ]
>;

const SelectDropdownGroupBase = function SelectDropdownGroup<
  TOption extends SelectDropdownOption = SelectDropdownOption,
  TIsMultiple extends boolean | undefined = undefined,
  TGroup extends SelectOptionGroup<TOption> = SelectOptionGroup<TOption>,
>({
  group,
  checkedOptionsValues,
  isMultiple,
  hideSelectAll = false,
  onOptionChange,
  onSelectAllChange,
  ...props
}: SelectDropdownGroupProps<TOption, TIsMultiple, TGroup>) {
  const hasSomeOptionSelected = useHasSomeOptionSelected(group.options, checkedOptionsValues);
  const hasEveryOptionSelected = useHasEveryOptionSelected(group.options, checkedOptionsValues);

  const handleSelectAllChange: Required<SelectDropdownSelectAllItemProps>['onChange'] = useCallback(
    (selectAllChecked) => onSelectAllChange(group, selectAllChecked),
    [group, onSelectAllChange],
  );

  return (
    <DropdownGroup
      {...props}
      countBadge={group.countBadge}
      initialIsOpen={group.initialIsOpen}
      label={group.label}
    >
      {!hideSelectAll && (
        <SelectDropdownSelectAllItem
          checked={hasEveryOptionSelected}
          indeterminate={!hasEveryOptionSelected && hasSomeOptionSelected}
          onChange={handleSelectAllChange}
        />
      )}

      <PaginatedSelectDropdownItemsList<TOption, TIsMultiple>
        checkedOptionsValues={checkedOptionsValues}
        isMultiple={isMultiple}
        options={group.options}
        onOptionChange={onOptionChange}
      />
    </DropdownGroup>
  );
};

export const SelectDropdownGroup = memo(SelectDropdownGroupBase) as typeof SelectDropdownGroupBase;
