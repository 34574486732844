import { combineReducers } from 'redux';

import {
  PaymentCategory,
  type Payment,
  type ReduxAction,
  type PaymentAggregatedListWithFlag,
} from '@amal-ia/lib-types';

import { PAYMENTS_ACTIONS } from './constants';

export default combineReducers({
  isLoading: (state: number = 0, action) => {
    switch (action.type) {
      case PAYMENTS_ACTIONS.START:
        return state + 1;
      case PAYMENTS_ACTIONS.ERROR:
      case PAYMENTS_ACTIONS.SET_STATEMENT_PAYMENTS:
      case PAYMENTS_ACTIONS.SET_CONTEXT_PAYMENTS:
      case PAYMENTS_ACTIONS.SET_STATEMENT_AMOUNTS:
      case PAYMENTS_ACTIONS.SET_COMPANY_AMOUNTS:
        return state - 1;
      default:
        return state;
    }
  },
  listByCategoryForCurrentStatement: (
    state: Record<PaymentCategory, Payment[]> = {
      achievement: [],
      hold: [],
      paid: [],
      adjustments: [],
    },
    action: ReduxAction,
  ) => {
    switch (action.type) {
      case PAYMENTS_ACTIONS.SET_STATEMENT_PAYMENTS:
        return action.payload.payments;
      case PAYMENTS_ACTIONS.CLEAR_PAYMENTS:
        return {
          achievement: [],
          hold: [],
          paid: [],
          adjustments: [],
        };
      default:
        return state;
    }
  },
  contextualizedPayments: (state: Payment[] = [], action: ReduxAction) => {
    switch (action.type) {
      case PAYMENTS_ACTIONS.SET_CONTEXT_PAYMENTS:
        return action.payload.payments;
      case PAYMENTS_ACTIONS.CLEAR_PAYMENTS:
        return [];
      default:
        return state;
    }
  },
  amountByCategoryForCurrentStatement: (
    state: Record<PaymentCategory, number | undefined> = {
      achievement: undefined,
      hold: undefined,
      paid: undefined,
      adjustments: undefined,
    },
    action: ReduxAction,
  ) => {
    switch (action.type) {
      case PAYMENTS_ACTIONS.SET_STATEMENT_AMOUNTS:
        return action.payload.paymentAmounts;
      case PAYMENTS_ACTIONS.CLEAR_PAYMENTS:
        return {
          achievement: undefined,
          hold: undefined,
          paid: undefined,
          adjustments: undefined,
        };
      default:
        return state;
    }
  },
  amountsByCategoryForCompany: (
    state: PaymentAggregatedListWithFlag = {
      achievement: [],
      hold: [],
      paid: [],
      adjustments: [],
      containsHoldRules: false,
    },
    action: ReduxAction,
  ) => {
    switch (action.type) {
      case PAYMENTS_ACTIONS.SET_COMPANY_AMOUNTS:
        return action.payload.companyAmounts;
      case PAYMENTS_ACTIONS.CLEAR_PAYMENTS:
        return {
          achievement: [],
          hold: [],
          paid: [],
          adjustments: [],
          containsHoldRules: false,
        };
      default:
        return state;
    }
  },
  currentPaymentCategory: (state: PaymentCategory = PaymentCategory.achievement, action: ReduxAction) => {
    switch (action.type) {
      case PAYMENTS_ACTIONS.SET_PAYMENT_CATEGORY:
        return action.payload.category;
      default:
        return state;
    }
  },
});
