import { css } from '@emotion/react';
import { Form } from 'formik';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { type MergeAll } from '@amal-ia/ext/typescript';
import { IconLoading, Modal, type ModalProps } from '@amal-ia/frontend/design-system/components';

import { CreatePlanFormFields } from '../create-plan-form-fields/CreatePlanFormFields';
import { CreatePlanForm, type CreatePlanFormProps } from '../CreatePlanForm';

export type CreatePlanFormDialogProps = MergeAll<[CreatePlanFormProps, Pick<ModalProps, 'isOpen' | 'onClose'>]>;

export const CreatePlanFormDialog = memo(function CreatePlanFormDialog({
  isOpen,
  onClose,
  ...props
}: CreatePlanFormDialogProps) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
    >
      <CreatePlanForm {...props}>
        {({ isValid, isSubmitting }) => (
          <Form
            css={css`
              display: contents;
            `}
          >
            <Modal.Content>
              <Modal.Header>
                <Modal.Title>
                  <FormattedMessage defaultMessage="Create a plan" />
                </Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <CreatePlanFormFields />
              </Modal.Body>
            </Modal.Content>

            <Modal.Actions>
              <Modal.CancelAction />
              <Modal.MainAction
                disabled={!isValid || isSubmitting}
                icon={isSubmitting ? <IconLoading /> : undefined}
                isLoading={isSubmitting}
                type="submit"
              >
                <FormattedMessage defaultMessage="Create plan" />
              </Modal.MainAction>
            </Modal.Actions>
          </Form>
        )}
      </CreatePlanForm>
    </Modal>
  );
});
