import { lazy } from 'react';

import { Layout } from '@amal-ia/frontend/connected-components/layout';

const PaymentContainer = lazy(() => import('./PaymentContainer'));

export const PaymentView = function PaymentView() {
  return (
    <Layout currentPage="reporting">
      <PaymentContainer />
    </Layout>
  );
};
