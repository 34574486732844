import { css, type Theme } from '@emotion/react';
import '@amal-ia/frontend/design-system/components';

export const container = css`
  min-width: 400px;
  max-width: 576px;
`;

export const titleContainer = (theme: Theme) => css`
  padding: 8px 24px;
  text-align: center;

  border-bottom: 1px solid ${theme.ds.colors.gray[100]};
`;

export const title = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const body = css`
  padding: 16px 32px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
`;

export const valueContainer = (theme: Theme) => css`
  align-self: flex-start;

  display: flex;
  align-items: baseline;
  gap: 48px;
  padding: 16px 24px;

  border: 1px solid ${theme.ds.colors.gray[100]};
  border-radius: ${theme.ds.borderRadiuses.squared};
`;

export const value = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  gap: 8px;
`;

export const footer = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;

  width: 100%;
`;
