import { useCallback, useState } from 'react';

import { type OverwriteCreationRequestDetails } from '@amal-ia/data-capture/overwrites/components';
import { OverwriteScopeEnum } from '@amal-ia/data-capture/overwrites/shared/types';
import { useBoolState } from '@amal-ia/ext/react/hooks';
import {
  STATEMENTS_ACTIONS,
  clearStatementOverwriteThunkAction,
  createKpiOverwriteThunkAction,
  useThunkDispatch,
} from '@amal-ia/frontend/web-data-layers';
import { type ComputedVariable } from '@amal-ia/lib-types';
import { type StatementDetailContextInterface } from '@amal-ia/lib-ui';

/**
 * A hook to handle everything related to KPI overwrites (modal opening, saving, clearing, etc.)
 */
export const useKPIOverwrite = (statement: StatementDetailContextInterface) => {
  const dispatch = useThunkDispatch();

  const { isOverwriteModalOpen, setOverwriteModalOpenTrue, setOverwriteModalOpenFalse } = useBoolState(
    false,
    'overwriteModalOpen',
  );
  const [overwriteObjectDetails, setOverwriteObjectDetails] = useState<OverwriteCreationRequestDetails>(null);

  const saveOverwrite = useCallback(
    async ({ changed }: { changed: Record<string, string> & { isApplyToOverall: boolean } }) => {
      const variableDefinition = statement.results.definitions.variables[overwriteObjectDetails.machineName];

      const scope = statement.isForecastedView
        ? OverwriteScopeEnum.FORECAST
        : changed.isApplyToOverall
          ? OverwriteScopeEnum.GLOBAL
          : OverwriteScopeEnum.STATEMENT;

      const action = await dispatch(
        createKpiOverwriteThunkAction(statement.id, {
          variableId: variableDefinition.id,
          field: overwriteObjectDetails.machineName,
          overwriteValue: changed[overwriteObjectDetails.field],
          scope,
        }),
      );

      if ([STATEMENTS_ACTIONS.CREATE_OVERWRITE, STATEMENTS_ACTIONS.CREATE_SIMULATED_OVERWRITE].includes(action.type)) {
        await statement.launchCalculation();
      }
    },
    [dispatch, overwriteObjectDetails, statement],
  );

  const clearOverwrite = useCallback(
    async (variable: ComputedVariable) => {
      if (variable.overwrite) {
        const action = await dispatch(clearStatementOverwriteThunkAction(statement.id, variable.overwrite));

        if ([STATEMENTS_ACTIONS.CLEAR_OVERWRITE, STATEMENTS_ACTIONS.CLEAR_SIMULATED_OVERWRITE].includes(action.type)) {
          await statement.launchCalculation();
        }
      }
    },
    [dispatch, statement],
  );

  const closeOverwriteModal = useCallback(() => {
    setOverwriteModalOpenFalse();
    setOverwriteObjectDetails(null);
  }, [setOverwriteModalOpenFalse]);

  return {
    clearOverwrite,
    closeOverwriteModal,
    isOverwriteModalOpen,
    openOverwriteModal: setOverwriteModalOpenTrue,
    overwriteObjectDetails,
    saveOverwrite,
    setOverwriteObjectDetails,
  };
};
