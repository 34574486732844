import { type ForwardedRef, forwardRef, memo, useCallback } from 'react';

import { type MergeAll } from '@amal-ia/ext/typescript';

import { IconAction, type IconActionProps } from '../../../general/icon-action/IconAction';
import { IconActionSize, IconActionVariant } from '../../../general/icon-action/IconAction.types';
import { InputSize } from '../Input.types';

const INPUT_SIZE_ICON_ACTION_SIZE_MAPPING: Record<InputSize, IconActionSize> = {
  [InputSize.SMALL]: IconActionSize.SMALL,
  [InputSize.MEDIUM]: IconActionSize.MEDIUM,
} as const;

export type InputActionProps = MergeAll<
  [
    IconActionProps,
    {
      /** Input size. Don't pass it, it is handled by Input directly. */
      size?: InputSize;
    },
  ]
>;

const InputActionForwardRef = forwardRef(function InputAction(
  { size = InputSize.MEDIUM, onClick, ...props }: InputActionProps,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  const onClickProxy: Required<IconActionProps>['onClick'] = useCallback(
    (event) => {
      event.stopPropagation();
      onClick?.(event);
    },
    [onClick],
  );

  return (
    <IconAction
      {...props}
      ref={ref}
      size={INPUT_SIZE_ICON_ACTION_SIZE_MAPPING[size]}
      onClick={onClickProxy}
    />
  );
});

export const InputAction = Object.assign(memo(InputActionForwardRef), {
  Variant: IconActionVariant,
});
