import { type ActionCreator } from 'redux';

import {
  type CreatePlanAgreement,
  type PatchPlanAgreement,
  type PlanAgreement,
  type ReduxAction,
  type ThunkResult,
} from '@amal-ia/lib-types';

import * as PlanAgreementsRepository from '../../services/planAgreements/planAgreements.repository';
import { addSnackbar } from '../snackbars/actions';

import { PLAN_AGREEMENTS_ACTIONS } from './constants';

const planAgreementsStartAction: ActionCreator<ReduxAction> = () => ({
  type: PLAN_AGREEMENTS_ACTIONS.START,
});

const planAgreementsErrorAction: ActionCreator<ReduxAction> = (error: Error) => ({
  type: PLAN_AGREEMENTS_ACTIONS.ERROR,
  error,
});

const planAgreementArchiveAction: ActionCreator<ReduxAction> = (planAgreement: PlanAgreement) => ({
  type: PLAN_AGREEMENTS_ACTIONS.ARCHIVE_PLAN_AGREEMENT,
  payload: { planAgreement },
});

const planAgreementConfirmAction: ActionCreator<ReduxAction> = (planAgreement: PlanAgreement) => ({
  type: PLAN_AGREEMENTS_ACTIONS.CONFIRM_PLAN_AGREEMENT,
  payload: { planAgreement },
});

const planAgreementsCreateAction: ActionCreator<ReduxAction> = (planAgreement: PlanAgreement) => ({
  type: PLAN_AGREEMENTS_ACTIONS.CREATE_PLAN_AGREEMENT,
  payload: { planAgreement },
});

const planAgreementDeleteAction: ActionCreator<ReduxAction> = (planAgreementId: string) => ({
  type: PLAN_AGREEMENTS_ACTIONS.DELETE_PLAN_AGREEMENT,
  payload: { planAgreementId },
});

const planAgreementFinalizeAction: ActionCreator<ReduxAction> = (planAgreement: PlanAgreement) => ({
  type: PLAN_AGREEMENTS_ACTIONS.FINALIZE_PLAN_AGREEMENT,
  payload: { planAgreement },
});

const planAgreementPatchAction: ActionCreator<ReduxAction> = (planAgreement: PlanAgreement) => ({
  type: PLAN_AGREEMENTS_ACTIONS.PATCH_PLAN_AGREEMENT,
  payload: { planAgreement },
});

const planAgreementRegenerateAgreementsAction: ActionCreator<ReduxAction> = (planAgreement: PlanAgreement) => ({
  type: PLAN_AGREEMENTS_ACTIONS.REGENERATE_AGREEMENTS,
  payload: { planAgreement },
});

const planAgreementSendAgreementAction: ActionCreator<ReduxAction> = (planAgreement: PlanAgreement) => ({
  type: PLAN_AGREEMENTS_ACTIONS.SEND_AGREEMENT,
  payload: { planAgreement },
});

const planAgreementSendAgreementsNotSendYetAction: ActionCreator<ReduxAction> = (planAgreement: PlanAgreement) => ({
  type: PLAN_AGREEMENTS_ACTIONS.SEND_AGREEMENTS_NOT_SEND_YET,
  payload: { planAgreement },
});

const planAgreementRefreshTemplateAction: ActionCreator<ReduxAction> = (planAgreement: PlanAgreement) => ({
  type: PLAN_AGREEMENTS_ACTIONS.REFRESH_TEMPLATE,
  payload: { planAgreement },
});

const resetCurrentPlanAgreementAction: ActionCreator<ReduxAction> = () => ({
  type: PLAN_AGREEMENTS_ACTIONS.RESET_CURRENT_PLAN_AGREEMENT,
});

const setCurrentPlanAgreementAction: ActionCreator<ReduxAction> = (planAgreement: PlanAgreement) => ({
  type: PLAN_AGREEMENTS_ACTIONS.SET_CURRENT_PLAN_AGREEMENT,
  payload: { planAgreement },
});

const setPlanAgreementsAction: ActionCreator<ReduxAction> = (planAgreements: PlanAgreement[]) => ({
  type: PLAN_AGREEMENTS_ACTIONS.SET_PLAN_AGREEMENTS,
  payload: { planAgreements },
});

export const archivePlanAgreementAction =
  (planAgreementId: string): ThunkResult<Promise<ReduxAction>> =>
  async (dispatch) => {
    dispatch(planAgreementsStartAction());

    try {
      const planAgreement = await PlanAgreementsRepository.archivePlanAgreement(planAgreementId);
      dispatch(addSnackbar({ message: 'Plan agreement archived', options: { variant: 'success' } }));

      return dispatch(planAgreementArchiveAction(planAgreement));
    } catch (error) {
      return dispatch(planAgreementsErrorAction(error));
    }
  };

export const confirmPlanAgreementAction =
  (planAgreementId: string): ThunkResult<Promise<ReduxAction>> =>
  async (dispatch) => {
    dispatch(planAgreementsStartAction());

    try {
      const planAgreement = await PlanAgreementsRepository.confirmPlanAgreement(planAgreementId);
      dispatch(addSnackbar({ message: 'Plan agreement confirmed', options: { variant: 'success' } }));

      return dispatch(planAgreementConfirmAction(planAgreement));
    } catch (error) {
      return dispatch(planAgreementsErrorAction(error));
    }
  };

export const createPlanAgreementAction =
  (createPlanAgreementArgs: CreatePlanAgreement): ThunkResult<Promise<ReduxAction>> =>
  async (dispatch) => {
    dispatch(planAgreementsStartAction());

    try {
      const planAgreement = await PlanAgreementsRepository.createPlanAgreement(createPlanAgreementArgs);
      dispatch(addSnackbar({ message: 'Plan agreement created', options: { variant: 'success' } }));

      return dispatch(planAgreementsCreateAction(planAgreement));
    } catch (error) {
      return dispatch(planAgreementsErrorAction(error));
    }
  };

export const deletePlanAgreementAction =
  (planAgreementId: string): ThunkResult<Promise<ReduxAction>> =>
  async (dispatch) => {
    dispatch(planAgreementsStartAction());

    try {
      await PlanAgreementsRepository.deletePlanAgreement(planAgreementId);
      dispatch(addSnackbar({ message: 'Plan agreement deleted', options: { variant: 'success' } }));

      return dispatch(planAgreementDeleteAction(planAgreementId));
    } catch (error) {
      return dispatch(planAgreementsErrorAction(error));
    }
  };

export const fetchPlanAgreementAction =
  (planAgreementId: string): ThunkResult<Promise<ReduxAction>> =>
  async (dispatch) => {
    dispatch(planAgreementsStartAction());

    try {
      const planAgreement = await PlanAgreementsRepository.getPlanAgreement(planAgreementId);

      return dispatch(setCurrentPlanAgreementAction(planAgreement));
    } catch (error) {
      return dispatch(planAgreementsErrorAction(error));
    }
  };

export const fetchPlanAgreementsAction = (): ThunkResult<Promise<ReduxAction>> => async (dispatch) => {
  dispatch(planAgreementsStartAction());

  try {
    const planAgreements = await PlanAgreementsRepository.getPlanAgreements();

    return dispatch(setPlanAgreementsAction(planAgreements));
  } catch (error) {
    return dispatch(planAgreementsErrorAction(error));
  }
};

export const finalizePlanAgreementAction =
  (planAgreementId: string): ThunkResult<Promise<ReduxAction>> =>
  async (dispatch) => {
    dispatch(planAgreementsStartAction());

    try {
      const planAgreement = await PlanAgreementsRepository.finalizePlanAgreement(planAgreementId);
      dispatch(
        addSnackbar({
          message: 'Plan agreement finalized, the documents are being generated and will be available soon',
          options: { variant: 'success' },
        }),
      );

      return dispatch(planAgreementFinalizeAction(planAgreement));
    } catch (error) {
      return dispatch(planAgreementsErrorAction(error));
    }
  };

export const patchPlanAgreementAction =
  (planAgreementId: string, patchedArgs: PatchPlanAgreement): ThunkResult<Promise<ReduxAction>> =>
  async (dispatch) => {
    dispatch(planAgreementsStartAction());

    try {
      const planAgreement = await PlanAgreementsRepository.patchPlanAgreement(planAgreementId, patchedArgs);
      dispatch(addSnackbar({ message: 'Plan agreement updated', options: { variant: 'success' } }));

      return dispatch(planAgreementPatchAction(planAgreement));
    } catch (error) {
      return dispatch(planAgreementsErrorAction(error));
    }
  };

export const regenerateAgreementsAction =
  (planAgreementId: string, assignmentIds: string[]): ThunkResult<Promise<ReduxAction>> =>
  async (dispatch) => {
    dispatch(planAgreementsStartAction());

    try {
      const planAgreement = await PlanAgreementsRepository.regenerateAgreements(planAgreementId, assignmentIds);
      dispatch(
        addSnackbar({ message: 'Plan agreements in error will be regenerated', options: { variant: 'success' } }),
      );

      return dispatch(planAgreementRegenerateAgreementsAction(planAgreement));
    } catch (error) {
      return dispatch(planAgreementsErrorAction(error));
    }
  };

export const sendAgreementAction =
  (planAgreementId: string, assignmentId: string): ThunkResult<Promise<ReduxAction>> =>
  async (dispatch) => {
    dispatch(planAgreementsStartAction());

    try {
      const planAgreement = await PlanAgreementsRepository.sendAgreement(planAgreementId, assignmentId);
      dispatch(addSnackbar({ message: 'Plan agreement sent', options: { variant: 'success' } }));

      return dispatch(planAgreementSendAgreementAction(planAgreement));
    } catch (error) {
      return dispatch(planAgreementsErrorAction(error));
    }
  };

export const sendAgreementsNotSendYetAction =
  (planAgreementId: string): ThunkResult<Promise<ReduxAction>> =>
  async (dispatch) => {
    dispatch(planAgreementsStartAction());

    try {
      const planAgreement = await PlanAgreementsRepository.sendAgreementsNotSendYet(planAgreementId);
      dispatch(addSnackbar({ message: 'Plan agreements sent', options: { variant: 'success' } }));

      return dispatch(planAgreementSendAgreementsNotSendYetAction(planAgreement));
    } catch (error) {
      return dispatch(planAgreementsErrorAction(error));
    }
  };

export const refreshAgreementTemplateAction =
  (planAgreementId: string): ThunkResult<Promise<ReduxAction>> =>
  async (dispatch) => {
    dispatch(planAgreementsStartAction());

    try {
      const planAgreement = await PlanAgreementsRepository.refreshAgreementTemplate(planAgreementId);
      dispatch(addSnackbar({ message: 'Plan agreement template refreshed', options: { variant: 'success' } }));

      return dispatch(planAgreementRefreshTemplateAction(planAgreement));
    } catch (error) {
      return dispatch(planAgreementsErrorAction(error));
    }
  };

export const resetCurrentPlanAgreementActionProxy = (): ThunkResult<ReduxAction> => (dispatch) =>
  dispatch(resetCurrentPlanAgreementAction());

export const setCurrentPlanAgreementActionProxy =
  (planAgreement: PlanAgreement): ThunkResult<Promise<ReduxAction>> =>
  (dispatch) => {
    dispatch(planAgreementsStartAction());
    return Promise.resolve(dispatch(setCurrentPlanAgreementAction(planAgreement)));
  };
