import { type UserContract } from '@amal-ia/tenants/users/shared/types';

type MinUserType = Pick<UserContract, 'firstName' | 'lastName'>;

/**
 * Format a user full name.
 * Machine names don't have a firstName so it is ignored.
 *
 * @param user - User.
 * @returns User full name.
 */
export const formatUserFullName = (user: MinUserType | null): string =>
  [user?.firstName, user?.lastName].filter(Boolean).join(' ');
