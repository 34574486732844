import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  TRUE: {
    defaultMessage: 'True',
  },
  FALSE: {
    defaultMessage: 'False',
  },
} as const);
