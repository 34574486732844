import { http } from '@amal-ia/frontend/kernel/http';
import {
  type CreatePlanAgreement,
  type PatchPlanAgreement,
  type PlanAgreement,
  PlanAgreementActionType,
} from '@amal-ia/lib-types';

const apiEndpoint = '/plan-agreements';

export const archivePlanAgreement = async (planAgreementId: string): Promise<PlanAgreement> => {
  const { data } = await http.post<PlanAgreement>(`${apiEndpoint}/${planAgreementId}/actions`, {
    type: PlanAgreementActionType.ARCHIVE,
  });
  return data;
};

export const confirmPlanAgreement = async (planAgreementId: string): Promise<PlanAgreement> => {
  const { data } = await http.post<PlanAgreement>(`${apiEndpoint}/${planAgreementId}/actions`, {
    type: PlanAgreementActionType.CONFIRM_AGREEMENT,
  });
  return data;
};

export const createPlanAgreement = async (createPlanAgreementArgs: CreatePlanAgreement): Promise<PlanAgreement> => {
  const { data } = await http.post<PlanAgreement>(`${apiEndpoint}`, createPlanAgreementArgs);
  return data;
};

export const deletePlanAgreement = async (planAgreementId: string): Promise<void> => {
  await http.delete(`${apiEndpoint}/${planAgreementId}`);
};

export const downloadAgreements = async (planAgreementId: string): Promise<Blob> => {
  const { data } = await http.get(`${apiEndpoint}/${planAgreementId}/downloadAgreements`, { responseType: 'blob' });
  return data;
};

export const finalizePlanAgreement = async (planAgreementId: string): Promise<PlanAgreement> => {
  const { data } = await http.post<PlanAgreement>(`${apiEndpoint}/${planAgreementId}/actions`, {
    type: PlanAgreementActionType.FINALIZE,
  });
  return data;
};

export const getPlanAgreement = async (planAgreementId: string): Promise<PlanAgreement> => {
  const { data } = await http.get<PlanAgreement>(`${apiEndpoint}/${planAgreementId}`);
  return data;
};

export const getPlanAgreements = async (): Promise<PlanAgreement[]> => {
  const { data } = await http.get<PlanAgreement[]>(`${apiEndpoint}`);
  return data;
};

export const patchPlanAgreement = async (
  planAgreementId: string,
  patchedArgs: PatchPlanAgreement,
): Promise<PlanAgreement> => {
  const { data } = await http.patch<PlanAgreement>(`${apiEndpoint}/${planAgreementId}`, patchedArgs);
  return data;
};

export const previewAgreement = async (planAgreementId: string): Promise<Blob> => {
  const { data } = await http.get(`${apiEndpoint}/${planAgreementId}/previewDocument`, { responseType: 'blob' });
  return data;
};

export const regenerateAgreements = async (
  planAgreementId: string,
  assignmentIds: string[],
): Promise<PlanAgreement> => {
  const { data } = await http.post<PlanAgreement>(`${apiEndpoint}/${planAgreementId}/actions`, {
    type: PlanAgreementActionType.REGENERATE_AGREEMENTS,
    payload: { assignmentIds },
  });
  return data;
};

export const sendAgreement = async (planAgreementId: string, assignmentId: string): Promise<PlanAgreement> => {
  const { data } = await http.post<PlanAgreement>(`${apiEndpoint}/${planAgreementId}/actions`, {
    type: PlanAgreementActionType.SEND_AGREEMENT,
    payload: { assignmentId },
  });
  return data;
};

export const sendAgreementsNotSendYet = async (planAgreementId: string): Promise<PlanAgreement> => {
  const { data } = await http.post<PlanAgreement>(`${apiEndpoint}/${planAgreementId}/actions`, {
    type: PlanAgreementActionType.SEND_AGREEMENTS_NOT_SEND_YET,
  });
  return data;
};

export const refreshAgreementTemplate = async (planAgreementId: string): Promise<PlanAgreement> => {
  const { data } = await http.post<PlanAgreement>(`${apiEndpoint}/${planAgreementId}/actions`, {
    type: PlanAgreementActionType.REFRESH_TEMPLATE,
  });
  return data;
};

export const viewAgreement = async (planAgreementId: string, assignmentId: string): Promise<Blob> => {
  const { data } = await http.get(`${apiEndpoint}/${planAgreementId}/assignments/${assignmentId}/viewDocument`, {
    responseType: 'blob',
  });
  return data;
};
