import { type Property, FormatsEnum } from '@amal-ia/data-capture/fields/types';

import { ConditionsEnum, DateTypeCondition } from '../dataFilter';
import { type Flow, FlowFrequency, type JSONContent, PresetsFlowTemplate } from '../flow';
import { type Option } from '../options';

import { FlowMessageActionTypes, type LinkAction, type PushDateMessageAction } from './actions';

export const flowFrequencyOptions = [
  {
    id: FlowFrequency.SCHEDULED,
    value: FlowFrequency.SCHEDULED,
    label: 'Scheduled',
  },
  {
    id: FlowFrequency.INSTANT,
    value: FlowFrequency.INSTANT,
    label: 'Instant',
    disabled: true,
    tooltip: 'Coming soon...',
  },
  {
    id: FlowFrequency.WORKING_HOURS,
    value: FlowFrequency.WORKING_HOURS,
    label: 'Working Hours',
    disabled: true,
    tooltip: 'Coming soon...',
  },
];

export const flowTemplateOptions = [
  {
    id: PresetsFlowTemplate.BLANK,
    value: PresetsFlowTemplate.BLANK,
    label: 'Choose a template or start with a blank flow',
  },
  {
    id: PresetsFlowTemplate.NEXT_STEP_EMPTY,
    value: PresetsFlowTemplate.NEXT_STEP_EMPTY,
    label: 'Next Step Is Empty',
  },
  {
    id: PresetsFlowTemplate.CLOSE_DATE_OVERDUE,
    value: PresetsFlowTemplate.CLOSE_DATE_OVERDUE,
    label: 'Close Date Overdue',
  },
];

export const flowActionTypeOptions: Option[] = [
  { value: FlowMessageActionTypes.push_date, label: 'Push Date' },
  { value: FlowMessageActionTypes.view_in_sfdc, label: 'View in Salesforce' },
];

export const flowPushDateUnitOptions: Option[] = [
  { value: 'days', label: 'Days' },
  { value: 'weeks', label: 'Weeks' },
  { value: 'months', label: 'Months' },
];

const flowTitleNextStepEmpty: JSONContent = {
  type: 'doc',
  content: [
    {
      type: 'paragraph',
      content: [
        {
          type: 'text',
          text: 'Next Step for ',
        },
        {
          type: 'mention',
          attrs: {
            id: 'name',
            label: 'Nom',
          },
        },
        {
          type: 'text',
          text: ' is empty',
        },
      ],
    },
  ],
};

const flowTitleOverdue: JSONContent = {
  type: 'doc',
  content: [
    {
      type: 'paragraph',
      content: [
        {
          type: 'text',
          text: 'Close date for ',
        },
        {
          type: 'mention',
          attrs: {
            id: 'name',
            label: 'Nom',
          },
        },
        {
          type: 'text',
          text: ' is overdue',
        },
      ],
    },
  ],
};

export const flowDisplayTitleTemplates: Record<PresetsFlowTemplate, JSONContent> = {
  [PresetsFlowTemplate.BLANK]: null,
  [PresetsFlowTemplate.NEXT_STEP_EMPTY]: flowTitleNextStepEmpty,
  [PresetsFlowTemplate.CLOSE_DATE_OVERDUE]: flowTitleOverdue,
};

const flowDisplayFields: Property[] = [
  {
    name: 'Name',
    format: FormatsEnum.text,
    machineName: 'name',
  },
  {
    name: 'Stage',
    format: FormatsEnum.text,
    machineName: 'stageName',
  },
  {
    name: 'Amount',
    format: FormatsEnum.currency,
    machineName: 'amount',
  },
  {
    name: 'Close Date',
    format: FormatsEnum.text,
    machineName: 'closeDate',
  },
  {
    name: 'Next Step',
    format: FormatsEnum.text,
    machineName: 'nextStep',
  },
];

export const flowDisplayFieldsTemplates: Record<PresetsFlowTemplate, Property[]> = {
  [PresetsFlowTemplate.BLANK]: null,
  [PresetsFlowTemplate.NEXT_STEP_EMPTY]: flowDisplayFields,
  [PresetsFlowTemplate.CLOSE_DATE_OVERDUE]: flowDisplayFields,
};

export const flowFormTemplates: Record<PresetsFlowTemplate, Partial<Flow>> = {
  [PresetsFlowTemplate.NEXT_STEP_EMPTY]: {
    name: 'Next Step Is Empty',
    frequency: FlowFrequency.SCHEDULED,
    template: PresetsFlowTemplate.NEXT_STEP_EMPTY,
    displayTitle: flowDisplayTitleTemplates[PresetsFlowTemplate.NEXT_STEP_EMPTY],
    displayFields: flowDisplayFieldsTemplates[PresetsFlowTemplate.NEXT_STEP_EMPTY],
    filters: [
      {
        conditions: [{ property: 'nextStep', condition: ConditionsEnum.doesNotExist }],
      },
    ],
    actions: [
      {
        type: FlowMessageActionTypes.view_in_sfdc,
        label: 'View in SFDC',
        name: 'action-2',
      } as LinkAction,
    ],
  },
  [PresetsFlowTemplate.CLOSE_DATE_OVERDUE]: {
    name: 'Close Date Overdue',
    frequency: FlowFrequency.SCHEDULED,
    template: PresetsFlowTemplate.CLOSE_DATE_OVERDUE,
    displayTitle: flowDisplayTitleTemplates[PresetsFlowTemplate.CLOSE_DATE_OVERDUE],
    displayFields: flowDisplayFieldsTemplates[PresetsFlowTemplate.CLOSE_DATE_OVERDUE],
    filters: [
      {
        conditions: [
          {
            property: 'closeDate',
            condition: ConditionsEnum.dateTimeAfter,
            value: '1',
            dateTypeCondition: DateTypeCondition.TIME_BASED,
          },
        ],
      },
    ],
    actions: [
      {
        type: FlowMessageActionTypes.push_date,
        fieldToPush: 'CloseDate',
        offset: 1,
        unit: 'weeks',
        label: 'Push 1 Week',
        name: 'action-0',
      } as PushDateMessageAction,
      {
        type: FlowMessageActionTypes.push_date,
        fieldToPush: 'CloseDate',
        offset: 1,
        unit: 'months',
        label: 'Push 1 Month',
        name: 'action-1',
      } as PushDateMessageAction,
      {
        type: FlowMessageActionTypes.view_in_sfdc,
        label: 'View in SFDC',
        name: 'action-2',
      } as LinkAction,
    ],
  },
  [PresetsFlowTemplate.BLANK]: {
    name: '',
    frequency: FlowFrequency.SCHEDULED,
    template: PresetsFlowTemplate.BLANK,
    filters: [
      {
        conditions: [
          {
            property: 'name',
            condition: ConditionsEnum.textExactlyMatches,
            value: '',
          },
        ],
      },
    ],
  },
};
