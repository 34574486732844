import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  TITLE: { defaultMessage: 'Statement not found' },
  SUBTITLE: { defaultMessage: 'This statement does not exist.' },
  REASON: {
    defaultMessage: 'This user has probably been unassigned from this plan.',
  },
  HOMEPAGE_CTA: { defaultMessage: 'Go back to home page' },
} as const);
