import { css, type Theme } from '@emotion/react';
import { memo, type PropsWithChildren, useEffect } from 'react';

import { Portal } from '@amal-ia/ext/react/components';

import { RIGHT_SIDEBAR_WIDTH } from '../Layout.constants';
import { useLayoutContext } from '../Layout.context';

const rootContainer = (theme: Theme) => css`
  width: ${RIGHT_SIDEBAR_WIDTH}px;
  background-color: ${theme.ds.colors.gray[0]};
  overflow: auto;

  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1;

  display: flex;
  flex: 1;
`;

export const RightSidebarPortal = memo(function RightSidebarPortal({ children }: PropsWithChildren) {
  const { portalElement, setIsPortalActive } = useLayoutContext();

  useEffect(() => {
    setIsPortalActive(!!children);
  }, [children, setIsPortalActive]);

  useEffect(
    () =>
      /** When Portal is unmount, make sure we do not let the right padding in place */
      () =>
        setIsPortalActive(false),
    [setIsPortalActive],
  );

  if (!children) {
    return null;
  }

  return (
    <Portal element={portalElement}>
      <div
        css={rootContainer}
        id="right-sidebar-container"
      >
        {children}
      </div>
    </Portal>
  );
});
