import { memo, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { amaliaTheme } from '@amal-ia/ext/mui/theme';
import { formatCurrencyAmount, formatUserFullName } from '@amal-ia/lib-types';
import { Avatar, Text, TextType } from '@amal-ia/lib-ui';

import { useStatementHistogramContext } from './useStatementHistogram.context';

export const StatementHistogramCustomTooltip = memo(function StatementHistogramCustomTooltip({
  active,
  payload,
  label,
  dataKey,
}: any) {
  const { user, currency, planName } = useStatementHistogramContext();

  const memoedKey = useMemo(() => dataKey, [dataKey]);

  if (!(active && payload && payload.length && dataKey)) {
    return null;
  }

  const dataValue: number = payload[0].payload[`${memoedKey}`] || 0;
  const formattedValue = formatCurrencyAmount({
    amount: dataValue,
    currencySymbol: currency,
  });

  return (
    <div
      style={{
        width: user?.id ? 270 : 150,
        height: 140,
        borderRadius: 8,
        boxShadow: '0px 0px 12px 0px  rgba(0, 0, 0, 0.1)',
        display: 'flex',
        flexDirection: 'row',
        padding: '12px',
        backgroundColor: amaliaTheme.palette.common.white,
      }}
    >
      {user?.pictureURL ? (
        <div style={{ width: 40 }}>
          <Avatar user={user} />
        </div>
      ) : null}

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'left',
          alignContent: 'left',
          justifyContent: 'left',
          justifyItems: 'left',
          width: '100%',
          textAlign: 'left',
          paddingLeft: 10,
        }}
      >
        {user?.lastName || user?.firstName ? <Text type={TextType.TOOLTIP}>{formatUserFullName(user)}</Text> : null}
        <div style={{ textAlign: 'left' }}>{planName || <FormattedMessage defaultMessage="All plans" />}</div>
        <Text type={TextType.INPUT_NAME}>{label}</Text>
        <Text
          type={TextType.PAYOUT_IN_TOOLTIP}
          style={{
            color:
              payload.find((pay: any) => pay.dataKey === memoedKey).color === 'url(#colorUv)'
                ? amaliaTheme.palette.primary.main
                : payload.find((pay: any) => pay.dataKey === memoedKey).color,
          }}
        >
          {formattedValue}
        </Text>
      </div>
    </div>
  );
});
