export interface PlanRuleChart {
  id: string;
  name: string;
  type: PlanRuleChartEnumType;
  configuration: PlanRuleChartConfiguration;
}

export type PlanRuleChartConfiguration = {
  targetAchievementVariableId?: string;
  metricsFilterId?: string;
  metricsPropertyMachineName?: string;
};

export enum PlanRuleChartEnumType {
  TARGET_ACHIEVEMENT = 'TARGET_ACHIEVEMENT',
  STATEMENT_METRIC = 'STATEMENT_METRIC',
}
