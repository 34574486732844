import { combineReducers } from 'redux';

import { DESIGNER_ACTIONS } from './constants';

export default combineReducers({
  context: (state: string = '', action) => {
    switch (action.type) {
      case DESIGNER_ACTIONS.CHANGE_CONTEXT:
        return action.payload.context;
      default:
        return state;
    }
  },
});
