import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  DETAILS: {
    defaultMessage: 'Overwrite details',
  },
  COMMENT: {
    defaultMessage: 'Comment',
  },
  OVERWRITTEN_BY: {
    defaultMessage: 'Overwritten by <strong>{author}</strong>{br}on <strong>{date}</strong>',
  },
  CLEAR_OVERWRITE: {
    defaultMessage: 'Clear overwrite',
  },
} as const);
