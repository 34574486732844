import { type AmaliaFormula } from '@amal-ia/amalia-lang/formula/shared/types';
import { type CustomObjectDefinition } from '@amal-ia/data-capture/models/types';

import { type ComputedFunctionResult, type ComputedItem } from './computed-items';

export enum DatasetType {
  filter = 'filter',
  relation = 'relation',
  metrics = 'metrics',
  quota = 'quota',
}

export type TimeDebug = Record<string, number>;

export interface Dataset {
  type: DatasetType;
  // A unique id from the computation engine.
  id: string;
  startDate?: number;
  endDate?: number;
  customObjectDefinition: Pick<CustomObjectDefinition, 'machineName'>;
  // Ids of this item's parents.
  parentIds: string[];
  // Relations to fetch in the same time as the dataset.
  relationMachineNames?: string[];

  computedItems?: ComputedItem[];

  evaluations?: ComputedFunctionResult[];

  timeDebug?: TimeDebug;

  filterMachineName?: string;
}

export interface FilterDataset extends Dataset {
  type: DatasetType.filter;
  filterMachineName: string;
  // If the condition contains object variable, it could have been rewritten.
  overrideCondition?: AmaliaFormula;
}

export interface RelationDataset extends Dataset {
  type: DatasetType.relation;
  relationMachineName: string;
}

export interface MetricsDataset extends Dataset {
  type: DatasetType.metrics;
  filterMachineName: string;
}

export interface QuotaDataset extends Dataset {
  type: DatasetType.quota;
  filterMachineName: string;
}
