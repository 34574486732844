import { type ClassNamesContent, css, type Theme } from '@emotion/react';

export const container = css`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;

  div:empty {
    margin: 0 8px;
  }
`;

export const actionContainer = css`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const divider = css`
  margin-left: 8px;
`;

export const actionTooltip = (cssFromClassNames: ClassNamesContent['css'], theme: Theme) => cssFromClassNames`
  margin: 0;
  padding: 0;
  border-radius: ${theme.ds.borderRadiuses.squared};
  & > li:first-child {
    border-radius: ${theme.ds.borderRadiuses.squared} ${theme.ds.borderRadiuses.squared} 0px 0px;
  }
  & > li:last-child {
    border-radius: 0px 0px ${theme.ds.borderRadiuses.squared} ${theme.ds.borderRadiuses.squared};
  }

`;

export const paymentTotal = css`
  margin-right: 16px;
`;
