import { css, type Theme } from '@emotion/react';

export const radioButtonSingle = (theme: Theme) => css`
  transition: ${theme.ds.transitions.default()};
  border-radius: ${theme.ds.borderRadiuses.squared};
  cursor: pointer;
  border-color: transparent;
  padding: 0px 4px;
  color: ${theme.ds.colors.secondary[300]};
  background-color: ${theme.ds.colors.gray[50]};
  box-shadow: ${theme.ds.shadows.hard};

  &.disabled {
    cursor: not-allowed;

    color: ${theme.ds.colors.gray[300]};
    background-color: ${theme.ds.colors.gray[100]};
  }

  &:not(.disabled) {
    &:hover {
      color: ${theme.ds.colors.secondary[500]};
      background-color: ${theme.ds.colors.secondary[50]};
    }
  }

  &.isActive {
    color: ${theme.ds.colors.secondary[500]};
    background-color: ${theme.ds.colors.secondary[50]};
  }
`;
