import { type Adjustment } from '@amal-ia/lib-types';

export const formatAdjustmentLabelInPayments = (adjustment?: Adjustment): string => {
  if (!adjustment) {
    return '';
  }

  const output = `${adjustment.name}`;
  return adjustment.description ? output.concat(' - ').concat(adjustment.description) : output;
};
