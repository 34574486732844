import { memo, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { AlertBanner, AlertBannerVariant } from '@amal-ia/frontend/design-system/components';

import { payoutAndPerformanceChartAlertMessages } from './PayoutAndPerformanceChartAlert.messages';

type PayoutAndPerformanceChartAlertProps = Readonly<{
  recordsLength: number;
  isError: boolean;
  isPlanSelected: boolean;
  isKpiAvailable: boolean;
}>;
export const PayoutAndPerformanceChartAlert = memo(function PayoutAndPerformanceChartAlert({
  recordsLength,
  isError,
  isKpiAvailable,
  isPlanSelected,
}: PayoutAndPerformanceChartAlertProps) {
  const { formatMessage } = useIntl();

  const [alert, variant] = useMemo(() => {
    let alertVariant: AlertBannerVariant;
    let alertMessage: string;
    if (isError) {
      alertVariant = AlertBannerVariant.ERROR;
      alertMessage = formatMessage(payoutAndPerformanceChartAlertMessages.ALERT_ERROR_LOADING_DATA);
    } else if (recordsLength === 0) {
      alertVariant = AlertBannerVariant.WARNING;
      alertMessage = formatMessage(payoutAndPerformanceChartAlertMessages.ALERT_NO_DATA_AVAILABLE);
    } else if (isPlanSelected && !isKpiAvailable) {
      alertVariant = AlertBannerVariant.WARNING;
      alertMessage = formatMessage(payoutAndPerformanceChartAlertMessages.ALERT_NO_KPI_AVAILABLE);
    }

    return [alertMessage, alertVariant];
  }, [formatMessage, isError, isKpiAvailable, isPlanSelected, recordsLength]);

  return !!alert && <AlertBanner variant={variant}>{alert}</AlertBanner>;
});
