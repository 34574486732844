import { type Theme } from '@emotion/react';

import { TransitionVariant, makeThemeTransition } from '@amal-ia/frontend/design-system/meta';

export const DEFAULT_THEME_TRANSITIONS = {
  [TransitionVariant.DEFAULT]: makeThemeTransition({
    durationMs: 200,
    delayMs: 0,
    easing: 'cubic-bezier(.1,1.1,.76,.83)',
  }),
} as const satisfies Theme['ds']['transitions'];
