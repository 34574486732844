import { css, type Theme } from '@emotion/react';

import { styleForType, textStyleDetails } from '@amal-ia/lib-ui';
import { designerObjectsDetails } from '@amal-ia/lib-ui-business';

export const editorContentCss = (theme: Theme) => css`
  width: 100%;
  word-break: break-word;
  display: inline-flex;
  flex-wrap: nowrap;

  p,
  span {
    display: inline-block;
    font-family: ${textStyleDetails[styleForType.FORMULA].fontFamily};
    font-size: ${textStyleDetails[styleForType.FORMULA].fontSize};
  }

  .tiptap.ProseMirror {
    border: 1px solid ${theme.ds.colors.gray['200']};
    border-radius: 5px;
    padding: 12px 16px;
    height: 100%;
    min-height: 75px;
    width: 100%;
    span.amalia-function {
      color: ${designerObjectsDetails.FUNCTION.textColor};
    }
  }

  .tiptap.ProseMirror.ProseMirror-focused {
    outline: 1px solid ${theme.ds.colors.secondary[200]};
  }
`;
