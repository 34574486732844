import { type ForwardedRef, forwardRef, memo } from 'react';

import { type MergeAll } from '@amal-ia/ext/typescript';

import { DropdownItemButton, type DropdownItemButtonProps } from '../dropdown-item-button/DropdownItemButton';

export type DropdownItemOptionProps = MergeAll<
  [
    Omit<DropdownItemButtonProps, 'aria-disabled' | 'aria-selected' | 'isActive' | 'role'>,
    {
      checked?: boolean;
    },
  ]
>;

const DropdownItemOptionForwardRef = forwardRef(function DropdownItemOption(
  { checked, ...props }: DropdownItemOptionProps,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  return (
    <DropdownItemButton
      {...props}
      ref={ref}
      aria-disabled={props.disabled}
      aria-selected={checked}
      isActive={checked}
      role="option"
    />
  );
});

export const DropdownItemOption = memo(DropdownItemOptionForwardRef);
