import { defineMessages } from 'react-intl';

export const payoutAndPerformanceChartAlertMessages = defineMessages({
  ALERT_ERROR_LOADING_DATA: {
    defaultMessage:
      'Oops! Something went wrong while loading the data. Please try again later or contact support for assistance.',
  },
  ALERT_NO_DATA_AVAILABLE: {
    defaultMessage:
      'No data available for the selected view. Please make sure you have valid statements for the selected view',
  },
  ALERT_NO_KPI_AVAILABLE: {
    defaultMessage:
      'Payout data is available for the selected view, but KPIs are not currently accessible. Please contact your administrator for any doubt.',
  },
});
