import { type ActionCreator } from 'redux';

import { type ReduxAction } from '@amal-ia/lib-types';

import { DESIGNER_ACTIONS } from './constants';

export const changeContext: ActionCreator<ReduxAction> = (context: string) => ({
  type: DESIGNER_ACTIONS.CHANGE_CONTEXT,
  payload: { context },
});
