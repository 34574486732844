import { css } from '@emotion/react';
import { useCallback, useState } from 'react';

import { useResizeObserver } from '@amal-ia/ext/react/hooks';
import { type AddParameters, type MergeAll } from '@amal-ia/ext/typescript';

import { SelectDropdown, type SelectDropdownProps } from '../../../../overlays/select-dropdown/SelectDropdown';
import { type SelectOptionGroup } from '../../../../overlays/select-dropdown/SelectDropdown.types';
import { type RowData } from '../../Table.types';

import { CellSelectControl, type CellSelectControlProps } from './cell-select-control/CellSelectControl';
import { type CellSelectOption } from './CellSelect.types';

export type CellSelectProps<
  TOption extends CellSelectOption = CellSelectOption,
  TRowData extends RowData = RowData,
  TIsMultiple extends boolean | undefined = undefined,
  TUseOptionAsValue extends boolean | undefined = undefined,
  TIsClearable extends boolean | undefined = true,
  TGroup extends SelectOptionGroup<TOption> = SelectOptionGroup<TOption>,
> = MergeAll<
  [
    Omit<
      SelectDropdownProps<TOption, TIsMultiple, TUseOptionAsValue, TIsClearable, TGroup>,
      | 'children'
      | 'onChangeSearchText'
      | 'searchText'
      | 'shouldDismiss'
      | 'shouldToggleOnClick'
      | 'shouldTriggerOnFocus'
    >,
    {
      /** Error message. */
      error?: CellSelectControlProps<TOption, TIsMultiple, TIsClearable>['error'];
      /** Placeholder when not searching. */
      placeholder?: CellSelectControlProps<TOption, TIsMultiple, TIsClearable>['placeholder'];
      /** Component to render an option in single select mode. */
      SingleValueComponent?: CellSelectControlProps<TOption, TIsMultiple, TIsClearable>['SingleValueComponent'];
      /** Component to render an option in multi select mode. */
      MultiValueComponent?: CellSelectControlProps<TOption, TIsMultiple, TIsClearable>['MultiValueComponent'];
      /** Current row. */
      row: TRowData;
      /** Change handler. */
      onChange?: AddParameters<
        Required<SelectDropdownProps<TOption, TIsMultiple, TUseOptionAsValue, TIsClearable, TGroup>>['onChange'],
        [row: TRowData]
      >;
    },
  ]
>;

export const CellSelect = function CellSelect<
  TOption extends CellSelectOption = CellSelectOption,
  TRowData extends RowData = RowData,
  TIsMultiple extends boolean | undefined = undefined,
  TUseOptionAsValue extends boolean | undefined = undefined,
  TIsClearable extends boolean | undefined = true,
  TGroup extends SelectOptionGroup<TOption> = SelectOptionGroup<TOption>,
>({
  error,
  placeholder,
  onChange,
  row,
  isClearable = true as TIsClearable,
  SingleValueComponent,
  MultiValueComponent,
  ...props
}: CellSelectProps<TOption, TRowData, TIsMultiple, TUseOptionAsValue, TIsClearable, TGroup>) {
  const [searchText, setSearchText] = useState('');

  /** Resize dropdown based on the size of the control. */
  const [{ width }, setWidth] = useState({ width: 0 });
  const controlContainerRef = useResizeObserver<HTMLDivElement>({ onResize: setWidth });

  const handleChange: Required<
    SelectDropdownProps<TOption, TIsMultiple, TUseOptionAsValue, TIsClearable, TGroup>
  >['onChange'] = useCallback((value) => onChange?.(value, row), [onChange, row]);

  return (
    <SelectDropdown<TOption, TIsMultiple, TUseOptionAsValue, TIsClearable, TGroup>
      {...props}
      shouldToggleOnClick
      shouldTriggerOnFocus
      isClearable={isClearable}
      searchText={searchText}
      css={css`
        > div {
          width: ${width}px;
        }
      `}
      onChange={handleChange}
      onChangeSearchText={setSearchText}
    >
      {(controlProps) => (
        // Need to wrap with a div here otherwise floating-ui changes the references of onX callbacks.
        <div ref={controlContainerRef}>
          <CellSelectControl<TOption, TIsMultiple, TIsClearable>
            {...controlProps}
            disabled={props.disabled}
            error={error}
            isClearable={isClearable}
            isMultiple={props.isMultiple}
            MultiValueComponent={MultiValueComponent}
            placeholder={placeholder}
            SingleValueComponent={SingleValueComponent}
          />
        </div>
      )}
    </SelectDropdown>
  );
};
