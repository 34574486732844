import { css, type Theme } from '@emotion/react';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { Typography } from '@amal-ia/frontend/design-system/components';

import { NoCommentsIcon } from './no-comments-icon';

// When there is no comments in a Statement Thread
export const NoCommentsFound = memo(function NoCommentsFound() {
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 64px;
        gap: 42px;
      `}
    >
      <NoCommentsIcon />
      <div
        css={(theme: Theme) => css`
          color: ${theme.ds.colors.gray[400]};
          width: 160px;
          text-align: center;
        `}
      >
        <Typography variant={Typography.Variant.BODY_LARGE_MEDIUM}>
          <FormattedMessage defaultMessage="Anything to share about this statement?" />
        </Typography>
      </div>
    </div>
  );
});
