import { defineMessages } from 'react-intl';

import { PaymentCategory } from '@amal-ia/lib-types';

export const PAYMENT_CATEGORIES_LABELS = defineMessages({
  [PaymentCategory.paid]: { defaultMessage: 'Paid' },
  [PaymentCategory.hold]: { defaultMessage: 'Remaining' },
  [PaymentCategory.achievement]: { defaultMessage: 'Total commission' },
  [PaymentCategory.adjustments]: { defaultMessage: 'Adjustments' },
});

export const PAYMENT_CATEGORIES_TOOLTIPS = defineMessages({
  [PaymentCategory.achievement]: {
    defaultMessage: 'Total commissions achieved during this period.',
  },
  [PaymentCategory.paid]: {
    defaultMessage:
      "Total commission ready to be paid. It's usually paid on the next payslip, but it might take more time depending on your organisation.",
  },
  [PaymentCategory.hold]: {
    defaultMessage:
      'Total commission not ready to be paid on this period. Check the release condition on your commission plan (awaiting customer payment, split payment, etc.).',
  },
  [PaymentCategory.adjustments]: {
    defaultMessage: 'Manual adjustments made to the statement.',
  },
});

export const PAYMENT_CATEGORIES_YTD_TOOLTIPS = defineMessages({
  [PaymentCategory.achievement]: {
    defaultMessage: 'Total commission achieved year to date.',
  },
  [PaymentCategory.paid]: {
    defaultMessage: 'Total paid year to date.',
  },
  [PaymentCategory.hold]: {
    defaultMessage: 'Total commission not ready to be paid, year to date',
  },
  [PaymentCategory.adjustments]: {
    defaultMessage: 'Manual adjustments made to the statement.',
  },
});
