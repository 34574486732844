import { makeStyles } from '@mui/styles';

import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';

export const usePageHeaderStyles = makeStyles((theme: AmaliaThemeType) => ({
  container: {
    margin: `${theme.spacing(2)} 0 ${theme.spacing(3)} 0`,
    backgroundColor: theme.palette.common.white,
    padding: `${theme.spacing(2)} 0`,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    maxWidth: '100%',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    padding: `0 ${theme.spacing(3)}`,
    gap: theme.spacing(1),
  },
  headerContainerWithHeaderContent: {
    marginBottom: theme.spacing(2),
  },
  headerTitle: {
    display: 'flex',
    flexDirection: 'column',
    paddingRight: theme.spacing(3),
  },
  headerBorderRight: {
    borderRight: `1px solid ${theme.palette.grey[200]}`,
  },
  separator: {
    flex: 1,
  },
  actionButton: {
    marginLeft: theme.spacing(2),
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
  leftActions: {
    marginRight: theme.spacing(2),
  },
  rightActions: {
    marginLeft: theme.spacing(2),
  },
  linkAction: {
    textDecoration: 'none',
  },
}));

export const usePageSectionStyles = makeStyles((theme: AmaliaThemeType) => ({
  pageSectionContainer: {
    '&:not($noBg)': {
      backgroundColor: theme.palette.common.white,
    },
    '&$gutters': {
      padding: `0 ${theme.spacing(5)}`,
    },
  },
  noBg: {},
  gutters: {},
}));
