import { defineMessages } from 'react-intl';

import { CustomReportQueryStage } from '@amal-ia/lib-types';

export const customReportQueryStageMessages = defineMessages<CustomReportQueryStage>({
  [CustomReportQueryStage.GROUP_BY]: { defaultMessage: 'Group bys' },
  [CustomReportQueryStage.ORDER_BY]: { defaultMessage: 'Sorts' },
  [CustomReportQueryStage.SELECT]: { defaultMessage: 'Columns' },
  [CustomReportQueryStage.WHERE]: { defaultMessage: 'Filters' },
} as const);
