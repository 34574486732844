import { memo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Typography } from '@amal-ia/frontend/design-system/components';
import { TypographyVariant } from '@amal-ia/frontend/design-system/meta';
import { type StatementError, StatementErrorLevel } from '@amal-ia/lib-types';
import { COMMON_MESSAGES } from '@amal-ia/lib-ui';

import { StatementSummaryWidgetCalculationError } from '../statement-calculation-error/StatementSummaryWidgetCalculationError';
import { StatementSummaryWidgetNotAvailable } from '../statement-not-available/StatementSummaryWidgetNotAvailable';
import { StatementSummaryWidgetQuotaError } from '../statement-quota-error/StatementSummaryWidgetQuotaError';
import { StatementSummaryRow } from '../statement-summary-row/StatementSummaryRow';
import { messages } from '../StatementSummaryWidget.messages';
import * as styles from '../StatementSummaryWidget.styles';
import { type CategoryWithRulesSummary } from '../StatementSummaryWidget.types';

type StatementSummaryBodyProps = Readonly<{
  statementId: string;
  statementError?: StatementError;
  statementTotalAchievedFormatted?: string;
  adjustmentsFormatted?: CategoryWithRulesSummary;
  categoriesWithRulesSummary: CategoryWithRulesSummary[];
}>;
export const StatementSummaryBody = memo(function StatementSummaryBody({
  adjustmentsFormatted,
  categoriesWithRulesSummary,
  statementId,
  statementError,
  statementTotalAchievedFormatted,
}: StatementSummaryBodyProps) {
  const { formatMessage } = useIntl();

  if (!statementId) {
    return <StatementSummaryWidgetNotAvailable />;
  }

  if (statementError) {
    if (statementError.level === StatementErrorLevel.ERROR) {
      return <StatementSummaryWidgetCalculationError />;
    }

    if (statementError.level === StatementErrorLevel.WARNING) {
      return <StatementSummaryWidgetQuotaError />;
    }
  }

  return (
    <div>
      {categoriesWithRulesSummary.map(({ category, rules }, categoryIndex) =>
        rules.map((rule, ruleIndex) => (
          <StatementSummaryRow
            key={`${category}-${rule.name}`}
            category={category}
            categoryIndex={categoryIndex}
            isFirstRow={categoryIndex === 0 && ruleIndex === 0}
            rule={rule}
          />
        )),
      )}

      {!!adjustmentsFormatted && (
        <StatementSummaryRow
          key={adjustmentsFormatted.rules[0].name}
          category={adjustmentsFormatted.category}
          categoryIndex={0}
          isFirstRow={false}
          rule={{
            ...adjustmentsFormatted.rules[0],
            name: formatMessage(messages.ADJUSTMENTS),
          }}
        />
      )}

      <hr css={styles.rulesAndTotalSeparator} />

      <div css={styles.statementTotalRow}>
        <Typography variant={TypographyVariant.BODY_BASE_MEDIUM}>
          <FormattedMessage {...COMMON_MESSAGES.TOTAL} />
        </Typography>

        <div css={styles.statementTotalHighlight}>
          <Typography variant={TypographyVariant.BODY_BASE_BOLD}>{statementTotalAchievedFormatted}</Typography>
        </div>
      </div>
    </div>
  );
});
