import { type AmaliaFormula } from '@amal-ia/amalia-lang/formula/shared/types';

export interface Relationship {
  id?: string;

  label: string;

  name: string;

  fromDefinitionMachineName: string;

  fromDefinitionField?: string;

  toDefinitionMachineName: string;

  toDefinitionField?: string;

  condition?: AmaliaFormula;

  type: RelationshipType;

  postFetchOperation?: AmaliaFormula;

  joinType?: RelationshipJoinType;
}

export enum RelationshipType {
  ARRAY = 'array',
  OBJECT = 'object',
}

export enum RelationshipJoinType {
  INNER = 'inner',
  LEFT = 'left',
}
