import { http } from '@amal-ia/frontend/kernel/http';
import {
  type PaymentContract,
  type ClearOverwriteRequest,
  type Option,
  type PatchPaymentRequest,
  type Payment,
  type PaymentAggregatedList,
  type PaymentLock,
  type PaginatedResponse,
} from '@amal-ia/lib-types';

const apiEndpoint = '/payments';

export async function getPaymentsForPeriod(
  currentPeriodId?: string,
  periodIds?: string[] | null,
  teamId?: string | null,
  planIds?: string[] | null,
  userIds?: string[] | null,
  ruleIds?: string[] | null,
  paymentPeriodIds?: string[] | null,
  type?: 'commissionReport' | null,
): Promise<PaymentAggregatedList> {
  const { data } = await http.get(`${apiEndpoint}/amounts`, {
    params: {
      currentPeriodId,
      periodIds,
      planIds,
      teamId: teamId || undefined,
      userIds,
      ruleIds,
      paymentPeriodIds,
      type,
    },
  });
  return data;
}

export async function getPaymentPaginate(
  achievementPeriods?: string[] | null,
  users?: string[] | null,
  plans?: string[] | null,
  teamIds?: string[] | null,
  rules?: string[] | null,
  paymentPeriods?: string[] | null,
  page = 1,
  limit = 10,
  searchValue?: string,
  sortColumn?: string,
  sortOrder = 'asc',
): Promise<PaginatedResponse<PaymentContract>> {
  const { data } = await http.get(`${apiEndpoint}/paginate`, {
    params: {
      q: searchValue,
      page,
      limit,
      userIds: users,
      planIds: plans,
      teamIds,
      ruleIds: rules,
      paymentPeriodIds: paymentPeriods,
      periodIds: achievementPeriods,
      ...(sortColumn ? { sort: sortColumn, desc: sortOrder } : null),
    },
  });
  return data;
}

export async function getPaymentsFacets(
  periodIds?: string[] | null,
  userIds?: string[] | null,
  planIds?: string[] | null,
  teamIds?: string[] | null,
  ruleIds?: string[] | null,
  paymentPeriodIds?: string[] | null,
): Promise<{ plans: Option[] }> {
  const { data } = await http.get<{ plans: Option[] }>(`${apiEndpoint}/facets`, {
    params: {
      userIds,
      planIds,
      teamIds,
      ruleIds,
      paymentPeriodIds,
      periodIds,
    },
  });
  return data;
}

export async function patchPayment(paymentId: string, patchRequest: PatchPaymentRequest) {
  const { data } = await http.patch<Payment>(`${apiEndpoint}/${paymentId}`, patchRequest);
  return data;
}

export async function clearPaymentOverwrite(paymentId: string, clearOverwriteRequest: ClearOverwriteRequest) {
  const { data } = await http.post<Payment>(`${apiEndpoint}/${paymentId}/clear`, clearOverwriteRequest);
  return data;
}

export async function getPaymentLocks() {
  const { data } = await http.get<PaymentLock[]>(`${apiEndpoint}/locks`);
  return data;
}

export async function addPaymentLock(paymentLock: PaymentLock) {
  const { data } = await http.put<PaymentLock>(`${apiEndpoint}/locks/${paymentLock.periodId}`);
  return data;
}

export async function removePaymentLock(paymentLock: PaymentLock) {
  await http.delete(`${apiEndpoint}/locks/${paymentLock.periodId}`);
}
