export enum CurrencySymbolsEnum {
  AED = 'AED',
  AFN = 'AFN',
  ALL = 'ALL',
  AMD = 'AMD',
  ANG = 'ANG',
  AOA = 'AOA',
  ARS = 'ARS',
  AUD = 'AUD',
  AWG = 'AWG',
  AZN = 'AZN',
  BAM = 'BAM',
  BBD = 'BBD',
  BDT = 'BDT',
  BGN = 'BGN',
  BHD = 'BHD',
  BIF = 'BIF',
  BMD = 'BMD',
  BND = 'BND',
  BOB = 'BOB',
  BRL = 'BRL',
  BSD = 'BSD',
  BTC = 'BTC',
  BTN = 'BTN',
  BWP = 'BWP',
  BYN = 'BYN',
  BYR = 'BYR',
  BZD = 'BZD',
  CAD = 'CAD',
  CDF = 'CDF',
  CHF = 'CHF',
  CLF = 'CLF',
  CLP = 'CLP',
  CNY = 'CNY',
  COP = 'COP',
  CRC = 'CRC',
  CUC = 'CUC',
  CUP = 'CUP',
  CVE = 'CVE',
  CZK = 'CZK',
  DJF = 'DJF',
  DKK = 'DKK',
  DOP = 'DOP',
  DZD = 'DZD',
  EGP = 'EGP',
  ERN = 'ERN',
  ETB = 'ETB',
  EUR = 'EUR',
  FJD = 'FJD',
  FKP = 'FKP',
  GBP = 'GBP',
  GEL = 'GEL',
  GGP = 'GGP',
  GHS = 'GHS',
  GIP = 'GIP',
  GMD = 'GMD',
  GNF = 'GNF',
  GTQ = 'GTQ',
  GYD = 'GYD',
  HKD = 'HKD',
  HNL = 'HNL',
  HRK = 'HRK',
  HTG = 'HTG',
  HUF = 'HUF',
  IDR = 'IDR',
  ILS = 'ILS',
  IMP = 'IMP',
  INR = 'INR',
  IQD = 'IQD',
  IRR = 'IRR',
  ISK = 'ISK',
  JEP = 'JEP',
  JMD = 'JMD',
  JOD = 'JOD',
  JPY = 'JPY',
  KES = 'KES',
  KGS = 'KGS',
  KHR = 'KHR',
  KMF = 'KMF',
  KPW = 'KPW',
  KRW = 'KRW',
  KWD = 'KWD',
  KYD = 'KYD',
  KZT = 'KZT',
  LAK = 'LAK',
  LBP = 'LBP',
  LKR = 'LKR',
  LRD = 'LRD',
  LSL = 'LSL',
  LTL = 'LTL',
  LVL = 'LVL',
  LYD = 'LYD',
  MAD = 'MAD',
  MDL = 'MDL',
  MGA = 'MGA',
  MKD = 'MKD',
  MMK = 'MMK',
  MNT = 'MNT',
  MOP = 'MOP',
  MRO = 'MRO',
  MUR = 'MUR',
  MVR = 'MVR',
  MWK = 'MWK',
  MXN = 'MXN',
  MYR = 'MYR',
  MZN = 'MZN',
  NAD = 'NAD',
  NGN = 'NGN',
  NIO = 'NIO',
  NOK = 'NOK',
  NPR = 'NPR',
  NZD = 'NZD',
  OMR = 'OMR',
  PAB = 'PAB',
  PEN = 'PEN',
  PGK = 'PGK',
  PHP = 'PHP',
  PKR = 'PKR',
  PLN = 'PLN',
  PYG = 'PYG',
  QAR = 'QAR',
  RON = 'RON',
  RSD = 'RSD',
  RUB = 'RUB',
  RWF = 'RWF',
  SAR = 'SAR',
  SBD = 'SBD',
  SCR = 'SCR',
  SDG = 'SDG',
  SEK = 'SEK',
  SGD = 'SGD',
  SHP = 'SHP',
  SLL = 'SLL',
  SOS = 'SOS',
  SRD = 'SRD',
  STD = 'STD',
  SVC = 'SVC',
  SYP = 'SYP',
  SZL = 'SZL',
  THB = 'THB',
  TJS = 'TJS',
  TMT = 'TMT',
  TND = 'TND',
  TOP = 'TOP',
  TRY = 'TRY',
  TTD = 'TTD',
  TWD = 'TWD',
  TZS = 'TZS',
  UAH = 'UAH',
  UGX = 'UGX',
  USD = 'USD',
  UYU = 'UYU',
  UZS = 'UZS',
  VEF = 'VEF',
  VND = 'VND',
  VUV = 'VUV',
  WST = 'WST',
  XAF = 'XAF',
  XAG = 'XAG',
  XAU = 'XAU',
  XCD = 'XCD',
  XDR = 'XDR',
  XOF = 'XOF',
  XPF = 'XPF',
  YER = 'YER',
  ZAR = 'ZAR',
  ZMK = 'ZMK',
  ZMW = 'ZMW',
  ZWL = 'ZWL',
}

// FIXME use a library to get the currency symbol.
export const CURRENCY_SYMBOL_SIGNS: Record<CurrencySymbolsEnum, string> = {
  [CurrencySymbolsEnum.AED]: 'د.إ',
  [CurrencySymbolsEnum.AFN]: '؋',
  [CurrencySymbolsEnum.ALL]: 'L',
  [CurrencySymbolsEnum.AMD]: '֏',
  [CurrencySymbolsEnum.ANG]: 'ƒ',
  [CurrencySymbolsEnum.AOA]: 'Kz',
  [CurrencySymbolsEnum.ARS]: '$',
  [CurrencySymbolsEnum.AUD]: '$',
  [CurrencySymbolsEnum.AWG]: 'ƒ',
  [CurrencySymbolsEnum.AZN]: '₼',
  [CurrencySymbolsEnum.BAM]: 'KM',
  [CurrencySymbolsEnum.BBD]: '$',
  [CurrencySymbolsEnum.BDT]: '৳',
  [CurrencySymbolsEnum.BGN]: 'лв',
  [CurrencySymbolsEnum.BHD]: '.د.ب',
  [CurrencySymbolsEnum.BIF]: 'FBu',
  [CurrencySymbolsEnum.BMD]: '$',
  [CurrencySymbolsEnum.BND]: '$',
  [CurrencySymbolsEnum.BOB]: '$b',
  [CurrencySymbolsEnum.BRL]: 'R$',
  [CurrencySymbolsEnum.BSD]: '$',
  [CurrencySymbolsEnum.BTC]: '₿',
  [CurrencySymbolsEnum.BTN]: 'Nu.',
  [CurrencySymbolsEnum.BWP]: 'P',
  [CurrencySymbolsEnum.BYN]: 'Br',
  [CurrencySymbolsEnum.BYR]: 'Br',
  [CurrencySymbolsEnum.BZD]: 'BZ$',
  [CurrencySymbolsEnum.CAD]: '$',
  [CurrencySymbolsEnum.CDF]: 'FC',
  [CurrencySymbolsEnum.CHF]: 'CHF',
  [CurrencySymbolsEnum.CLF]: 'CLF',
  [CurrencySymbolsEnum.CLP]: '$',
  [CurrencySymbolsEnum.CNY]: '¥',
  [CurrencySymbolsEnum.COP]: '$',
  [CurrencySymbolsEnum.CRC]: '₡',
  [CurrencySymbolsEnum.CUC]: '$',
  [CurrencySymbolsEnum.CUP]: '₱',
  [CurrencySymbolsEnum.CVE]: '$',
  [CurrencySymbolsEnum.CZK]: 'Kč',
  [CurrencySymbolsEnum.DJF]: 'Fdj',
  [CurrencySymbolsEnum.DKK]: 'kr',
  [CurrencySymbolsEnum.DOP]: 'RD$',
  [CurrencySymbolsEnum.DZD]: 'دج',
  [CurrencySymbolsEnum.EGP]: '£',
  [CurrencySymbolsEnum.ERN]: 'Nfk',
  [CurrencySymbolsEnum.ETB]: 'Br',
  [CurrencySymbolsEnum.EUR]: '€',
  [CurrencySymbolsEnum.FJD]: '$',
  [CurrencySymbolsEnum.FKP]: '£',
  [CurrencySymbolsEnum.GBP]: '£',
  [CurrencySymbolsEnum.GEL]: '₾',
  [CurrencySymbolsEnum.GGP]: '£',
  [CurrencySymbolsEnum.GHS]: 'GH₵',
  [CurrencySymbolsEnum.GIP]: '£',
  [CurrencySymbolsEnum.GMD]: 'D',
  [CurrencySymbolsEnum.GNF]: 'FG',
  [CurrencySymbolsEnum.GTQ]: 'Q',
  [CurrencySymbolsEnum.GYD]: '$',
  [CurrencySymbolsEnum.HKD]: '$',
  [CurrencySymbolsEnum.HNL]: 'L',
  [CurrencySymbolsEnum.HRK]: 'kn',
  [CurrencySymbolsEnum.HTG]: 'G',
  [CurrencySymbolsEnum.HUF]: 'Ft',
  [CurrencySymbolsEnum.IDR]: 'Rp',
  [CurrencySymbolsEnum.ILS]: '₪',
  [CurrencySymbolsEnum.IMP]: '£',
  [CurrencySymbolsEnum.INR]: '₹',
  [CurrencySymbolsEnum.IQD]: 'ع.د',
  [CurrencySymbolsEnum.IRR]: '﷼',
  [CurrencySymbolsEnum.ISK]: 'kr',
  [CurrencySymbolsEnum.JEP]: '£',
  [CurrencySymbolsEnum.JMD]: 'J$',
  [CurrencySymbolsEnum.JOD]: 'JD',
  [CurrencySymbolsEnum.JPY]: '¥',
  [CurrencySymbolsEnum.KES]: 'KSh',
  [CurrencySymbolsEnum.KGS]: 'лв',
  [CurrencySymbolsEnum.KHR]: '៛',
  [CurrencySymbolsEnum.KMF]: 'CF',
  [CurrencySymbolsEnum.KPW]: '₩',
  [CurrencySymbolsEnum.KRW]: '₩',
  [CurrencySymbolsEnum.KWD]: 'KD',
  [CurrencySymbolsEnum.KYD]: '$',
  [CurrencySymbolsEnum.KZT]: '₸',
  [CurrencySymbolsEnum.LAK]: '₭',
  [CurrencySymbolsEnum.LBP]: '£',
  [CurrencySymbolsEnum.LKR]: '₨',
  [CurrencySymbolsEnum.LRD]: '$',
  [CurrencySymbolsEnum.LSL]: 'M',
  [CurrencySymbolsEnum.LTL]: 'Lt',
  [CurrencySymbolsEnum.LVL]: 'Ls',
  [CurrencySymbolsEnum.LYD]: 'LD',
  [CurrencySymbolsEnum.MAD]: 'MAD',
  [CurrencySymbolsEnum.MDL]: 'lei',
  [CurrencySymbolsEnum.MGA]: 'Ar',
  [CurrencySymbolsEnum.MKD]: 'ден',
  [CurrencySymbolsEnum.MMK]: 'K',
  [CurrencySymbolsEnum.MNT]: '₮',
  [CurrencySymbolsEnum.MOP]: 'MOP$',
  [CurrencySymbolsEnum.MRO]: 'UM',
  [CurrencySymbolsEnum.MUR]: '₨',
  [CurrencySymbolsEnum.MVR]: 'Rf',
  [CurrencySymbolsEnum.MWK]: 'MK',
  [CurrencySymbolsEnum.MXN]: '$',
  [CurrencySymbolsEnum.MYR]: 'RM',
  [CurrencySymbolsEnum.MZN]: 'MT',
  [CurrencySymbolsEnum.NAD]: '$',
  [CurrencySymbolsEnum.NGN]: '₦',
  [CurrencySymbolsEnum.NIO]: 'C$',
  [CurrencySymbolsEnum.NOK]: 'kr',
  [CurrencySymbolsEnum.NPR]: '₨',
  [CurrencySymbolsEnum.NZD]: '$',
  [CurrencySymbolsEnum.OMR]: '﷼',
  [CurrencySymbolsEnum.PAB]: 'B/.',
  [CurrencySymbolsEnum.PEN]: 'S/.',
  [CurrencySymbolsEnum.PGK]: 'K',
  [CurrencySymbolsEnum.PHP]: '₱',
  [CurrencySymbolsEnum.PKR]: '₨',
  [CurrencySymbolsEnum.PLN]: 'zł',
  [CurrencySymbolsEnum.PYG]: 'Gs',
  [CurrencySymbolsEnum.QAR]: '﷼',
  [CurrencySymbolsEnum.RON]: 'lei',
  [CurrencySymbolsEnum.RSD]: 'Дин.',
  [CurrencySymbolsEnum.RUB]: '₽',
  [CurrencySymbolsEnum.RWF]: 'R₣',
  [CurrencySymbolsEnum.SAR]: '﷼',
  [CurrencySymbolsEnum.SBD]: '$',
  [CurrencySymbolsEnum.SCR]: '₨',
  [CurrencySymbolsEnum.SDG]: 'ج.س.',
  [CurrencySymbolsEnum.SEK]: 'kr',
  [CurrencySymbolsEnum.SGD]: 'S$',
  [CurrencySymbolsEnum.SHP]: '£',
  [CurrencySymbolsEnum.SLL]: 'Le',
  [CurrencySymbolsEnum.SOS]: 'S',
  [CurrencySymbolsEnum.SRD]: '$',
  [CurrencySymbolsEnum.STD]: 'Db',
  [CurrencySymbolsEnum.SVC]: '$',
  [CurrencySymbolsEnum.SYP]: '£',
  [CurrencySymbolsEnum.SZL]: 'E',
  [CurrencySymbolsEnum.THB]: '฿',
  [CurrencySymbolsEnum.TJS]: 'SM',
  [CurrencySymbolsEnum.TMT]: 'T',
  [CurrencySymbolsEnum.TND]: 'د.ت',
  [CurrencySymbolsEnum.TOP]: 'T$',
  [CurrencySymbolsEnum.TRY]: '₺',
  [CurrencySymbolsEnum.TTD]: 'TT$',
  [CurrencySymbolsEnum.TWD]: 'NT$',
  [CurrencySymbolsEnum.TZS]: 'TSh',
  [CurrencySymbolsEnum.UAH]: '₴',
  [CurrencySymbolsEnum.UGX]: 'USh',
  [CurrencySymbolsEnum.USD]: '$',
  [CurrencySymbolsEnum.UYU]: '$U',
  [CurrencySymbolsEnum.UZS]: 'лв',
  [CurrencySymbolsEnum.VEF]: 'Bs',
  [CurrencySymbolsEnum.VND]: '₫',
  [CurrencySymbolsEnum.VUV]: 'VT',
  [CurrencySymbolsEnum.WST]: 'WS$',
  [CurrencySymbolsEnum.XAF]: 'FCFA',
  [CurrencySymbolsEnum.XAG]: 'XAG',
  [CurrencySymbolsEnum.XAU]: 'XAU',
  [CurrencySymbolsEnum.XCD]: '$',
  [CurrencySymbolsEnum.XDR]: 'XDR',
  [CurrencySymbolsEnum.XOF]: 'CFA',
  [CurrencySymbolsEnum.XPF]: '₣',
  [CurrencySymbolsEnum.YER]: '﷼',
  [CurrencySymbolsEnum.ZAR]: 'R',
  [CurrencySymbolsEnum.ZMK]: 'ZK',
  [CurrencySymbolsEnum.ZMW]: 'Lek',
  [CurrencySymbolsEnum.ZWL]: '$',
} as const;

export const CURRENCY_SYMBOL_LABELS: Record<CurrencySymbolsEnum, string> = {
  [CurrencySymbolsEnum.AED]: 'United Arab Emirates Dirham',
  [CurrencySymbolsEnum.AFN]: 'Afghan Afghani',
  [CurrencySymbolsEnum.ALL]: 'Albanian Lek',
  [CurrencySymbolsEnum.AMD]: 'Armenian Dram',
  [CurrencySymbolsEnum.ANG]: 'Netherlands Antillean Guilder',
  [CurrencySymbolsEnum.AOA]: 'Angolan Kwanza',
  [CurrencySymbolsEnum.ARS]: 'Argentine Peso',
  [CurrencySymbolsEnum.AUD]: 'Australian Dollar',
  [CurrencySymbolsEnum.AWG]: 'Aruban Florin',
  [CurrencySymbolsEnum.AZN]: 'Azerbaijani Manat',
  [CurrencySymbolsEnum.BAM]: 'Bosnia- Herzegovina Convertible Mark',
  [CurrencySymbolsEnum.BBD]: 'Barbadian Dollar',
  [CurrencySymbolsEnum.BDT]: 'Bangladeshi Taka',
  [CurrencySymbolsEnum.BGN]: 'Bulgarian Lev',
  [CurrencySymbolsEnum.BHD]: 'Bahraini Dinar',
  [CurrencySymbolsEnum.BIF]: 'Burundian Franc',
  [CurrencySymbolsEnum.BMD]: 'Bermudan Dollar',
  [CurrencySymbolsEnum.BND]: 'Brunei Dollar',
  [CurrencySymbolsEnum.BOB]: 'Bolivian Boliviano',
  [CurrencySymbolsEnum.BRL]: 'Brazilian Real',
  [CurrencySymbolsEnum.BSD]: 'Bahamian Dollar',
  [CurrencySymbolsEnum.BTC]: 'Bitcoin',
  [CurrencySymbolsEnum.BTN]: 'Bhutanese Ngultrum',
  [CurrencySymbolsEnum.BWP]: 'Botswanan Pula',
  [CurrencySymbolsEnum.BYN]: 'New Belarusian Ruble',
  [CurrencySymbolsEnum.BYR]: 'Belarusian Ruble',
  [CurrencySymbolsEnum.BZD]: 'Belize Dollar',
  [CurrencySymbolsEnum.CAD]: 'Canadian Dollar',
  [CurrencySymbolsEnum.CDF]: 'Congolese Franc',
  [CurrencySymbolsEnum.CHF]: 'Swiss Franc',
  [CurrencySymbolsEnum.CLF]: 'Chilean Unit of Account (UF)',
  [CurrencySymbolsEnum.CLP]: 'Chilean Peso',
  [CurrencySymbolsEnum.CNY]: 'Chinese Yuan',
  [CurrencySymbolsEnum.COP]: 'Colombian Peso',
  [CurrencySymbolsEnum.CRC]: 'Costa Rican Colón',
  [CurrencySymbolsEnum.CUC]: 'Cuban Convertible Peso',
  [CurrencySymbolsEnum.CUP]: 'Cuban Peso',
  [CurrencySymbolsEnum.CVE]: 'Cape Verdean Escudo',
  [CurrencySymbolsEnum.CZK]: 'Czech Republic Koruna',
  [CurrencySymbolsEnum.DJF]: 'Djiboutian Franc',
  [CurrencySymbolsEnum.DKK]: 'Danish Krone',
  [CurrencySymbolsEnum.DOP]: 'Dominican Peso',
  [CurrencySymbolsEnum.DZD]: 'Algerian Dinar',
  [CurrencySymbolsEnum.EGP]: 'Egyptian Pound',
  [CurrencySymbolsEnum.ERN]: 'Eritrean Nakfa',
  [CurrencySymbolsEnum.ETB]: 'Ethiopian Birr',
  [CurrencySymbolsEnum.EUR]: 'Euro',
  [CurrencySymbolsEnum.FJD]: 'Fijian Dollar',
  [CurrencySymbolsEnum.FKP]: 'Falkland Islands Pound',
  [CurrencySymbolsEnum.GBP]: 'British Pound Sterling',
  [CurrencySymbolsEnum.GEL]: 'Georgian Lari',
  [CurrencySymbolsEnum.GGP]: 'Guernsey Pound',
  [CurrencySymbolsEnum.GHS]: 'Ghanaian Cedi',
  [CurrencySymbolsEnum.GIP]: 'Gibraltar Pound',
  [CurrencySymbolsEnum.GMD]: 'Gambian Dalasi',
  [CurrencySymbolsEnum.GNF]: 'Guinean Franc',
  [CurrencySymbolsEnum.GTQ]: 'Guatemalan Quetzal',
  [CurrencySymbolsEnum.GYD]: 'Guyanaese Dollar',
  [CurrencySymbolsEnum.HKD]: 'Hong Kong Dollar',
  [CurrencySymbolsEnum.HNL]: 'Honduran Lempira',
  [CurrencySymbolsEnum.HRK]: 'Croatian Kuna',
  [CurrencySymbolsEnum.HTG]: 'Haitian Gourde',
  [CurrencySymbolsEnum.HUF]: 'Hungarian Forint',
  [CurrencySymbolsEnum.IDR]: 'Indonesian Rupiah',
  [CurrencySymbolsEnum.ILS]: 'Israeli New Sheqel',
  [CurrencySymbolsEnum.IMP]: 'Manx pound',
  [CurrencySymbolsEnum.INR]: 'Indian Rupee',
  [CurrencySymbolsEnum.IQD]: 'Iraqi Dinar',
  [CurrencySymbolsEnum.IRR]: 'Iranian Rial',
  [CurrencySymbolsEnum.ISK]: 'Icelandic Króna',
  [CurrencySymbolsEnum.JEP]: 'Jersey Pound',
  [CurrencySymbolsEnum.JMD]: 'Jamaican Dollar',
  [CurrencySymbolsEnum.JOD]: 'Jordanian Dinar',
  [CurrencySymbolsEnum.JPY]: 'Japanese Yen',
  [CurrencySymbolsEnum.KES]: 'Kenyan Shilling',
  [CurrencySymbolsEnum.KGS]: 'Kyrgystani Som',
  [CurrencySymbolsEnum.KHR]: 'Cambodian Riel',
  [CurrencySymbolsEnum.KMF]: 'Comorian Franc',
  [CurrencySymbolsEnum.KPW]: 'North Korean Won',
  [CurrencySymbolsEnum.KRW]: 'South Korean Won',
  [CurrencySymbolsEnum.KWD]: 'Kuwaiti Dinar',
  [CurrencySymbolsEnum.KYD]: 'Cayman Islands Dollar',
  [CurrencySymbolsEnum.KZT]: 'Kazakhstani Tenge',
  [CurrencySymbolsEnum.LAK]: 'Laotian Kip',
  [CurrencySymbolsEnum.LBP]: 'Lebanese Pound',
  [CurrencySymbolsEnum.LKR]: 'Sri Lankan Rupee',
  [CurrencySymbolsEnum.LRD]: 'Liberian Dollar',
  [CurrencySymbolsEnum.LSL]: 'Lesotho Loti',
  [CurrencySymbolsEnum.LTL]: 'Lithuanian Litas',
  [CurrencySymbolsEnum.LVL]: 'Latvian Lats',
  [CurrencySymbolsEnum.LYD]: 'Libyan Dinar',
  [CurrencySymbolsEnum.MAD]: 'Moroccan Dirham',
  [CurrencySymbolsEnum.MDL]: 'Moldovan Leu',
  [CurrencySymbolsEnum.MGA]: 'Malagasy Ariary',
  [CurrencySymbolsEnum.MKD]: 'Macedonian Denar',
  [CurrencySymbolsEnum.MMK]: 'Myanma Kyat',
  [CurrencySymbolsEnum.MNT]: 'Mongolian Tugrik',
  [CurrencySymbolsEnum.MOP]: 'Macanese Pataca',
  [CurrencySymbolsEnum.MRO]: 'Mauritanian Ouguiya',
  [CurrencySymbolsEnum.MUR]: 'Mauritian Rupee',
  [CurrencySymbolsEnum.MVR]: 'Maldivian Rufiyaa',
  [CurrencySymbolsEnum.MWK]: 'Malawian Kwacha',
  [CurrencySymbolsEnum.MXN]: 'Mexican Peso',
  [CurrencySymbolsEnum.MYR]: 'Malaysian Ringgit',
  [CurrencySymbolsEnum.MZN]: 'Mozambican Metical',
  [CurrencySymbolsEnum.NAD]: 'Namibian Dollar',
  [CurrencySymbolsEnum.NGN]: 'Nigerian Naira',
  [CurrencySymbolsEnum.NIO]: 'Nicaraguan Córdoba',
  [CurrencySymbolsEnum.NOK]: 'Norwegian Krone',
  [CurrencySymbolsEnum.NPR]: 'Nepalese Rupee',
  [CurrencySymbolsEnum.NZD]: 'New Zealand Dollar',
  [CurrencySymbolsEnum.OMR]: 'Omani Rial',
  [CurrencySymbolsEnum.PAB]: 'Panamanian Balboa',
  [CurrencySymbolsEnum.PEN]: 'Peruvian Nuevo Sol',
  [CurrencySymbolsEnum.PGK]: 'Papua New Guinean Kina',
  [CurrencySymbolsEnum.PHP]: 'Philippine Peso',
  [CurrencySymbolsEnum.PKR]: 'Pakistani Rupee',
  [CurrencySymbolsEnum.PLN]: 'Polish Zloty',
  [CurrencySymbolsEnum.PYG]: 'Paraguayan Guarani',
  [CurrencySymbolsEnum.QAR]: 'Qatari Rial',
  [CurrencySymbolsEnum.RON]: 'Romanian Leu',
  [CurrencySymbolsEnum.RSD]: 'Serbian Dinar',
  [CurrencySymbolsEnum.RUB]: 'Russian Ruble',
  [CurrencySymbolsEnum.RWF]: 'Rwandan Franc',
  [CurrencySymbolsEnum.SAR]: 'Saudi Riyal',
  [CurrencySymbolsEnum.SBD]: 'Solomon Islands Dollar',
  [CurrencySymbolsEnum.SCR]: 'Seychellois Rupee',
  [CurrencySymbolsEnum.SDG]: 'Sudanese Pound',
  [CurrencySymbolsEnum.SEK]: 'Swedish Krona',
  [CurrencySymbolsEnum.SGD]: 'Singapore Dollar',
  [CurrencySymbolsEnum.SHP]: 'Saint Helena Pound',
  [CurrencySymbolsEnum.SLL]: 'Sierra Leonean Leone',
  [CurrencySymbolsEnum.SOS]: 'Somali Shilling',
  [CurrencySymbolsEnum.SRD]: 'Surinamese Dollar',
  [CurrencySymbolsEnum.STD]: 'São Tomé and Príncipe Dobra',
  [CurrencySymbolsEnum.SVC]: 'Salvadoran Colón',
  [CurrencySymbolsEnum.SYP]: 'Syrian Pound',
  [CurrencySymbolsEnum.SZL]: 'Swazi Lilangeni',
  [CurrencySymbolsEnum.THB]: 'Thai Baht',
  [CurrencySymbolsEnum.TJS]: 'Tajikistani Somoni',
  [CurrencySymbolsEnum.TMT]: 'Turkmenistani Manat',
  [CurrencySymbolsEnum.TND]: 'Tunisian Dinar',
  [CurrencySymbolsEnum.TOP]: 'Tongan Paʻanga',
  [CurrencySymbolsEnum.TRY]: 'Turkish Lira',
  [CurrencySymbolsEnum.TTD]: 'Trinidad and Tobago Dollar',
  [CurrencySymbolsEnum.TWD]: 'New Taiwan Dollar',
  [CurrencySymbolsEnum.TZS]: 'Tanzanian Shilling',
  [CurrencySymbolsEnum.UAH]: 'Ukrainian Hryvnia',
  [CurrencySymbolsEnum.UGX]: 'Ugandan Shilling',
  [CurrencySymbolsEnum.USD]: 'United States Dollar',
  [CurrencySymbolsEnum.UYU]: 'Uruguayan Peso',
  [CurrencySymbolsEnum.UZS]: 'Uzbekistan Som',
  [CurrencySymbolsEnum.VEF]: 'Venezuelan Bolívar Fuerte',
  [CurrencySymbolsEnum.VND]: 'Vietnamese Dong',
  [CurrencySymbolsEnum.VUV]: 'Vanuatu Vatu',
  [CurrencySymbolsEnum.WST]: 'Samoan Tala',
  [CurrencySymbolsEnum.XAF]: 'CFA Franc BEAC',
  [CurrencySymbolsEnum.XAG]: 'Silver (troy ounce)',
  [CurrencySymbolsEnum.XAU]: 'Gold (troy ounce)',
  [CurrencySymbolsEnum.XCD]: 'East Caribbean Dollar',
  [CurrencySymbolsEnum.XDR]: 'Special Drawing Rights',
  [CurrencySymbolsEnum.XOF]: 'CFA Franc BCEAO',
  [CurrencySymbolsEnum.XPF]: 'CFP Franc',
  [CurrencySymbolsEnum.YER]: 'Yemeni Rial',
  [CurrencySymbolsEnum.ZAR]: 'South African Rand',
  [CurrencySymbolsEnum.ZMK]: 'Zambian Kwacha (pre- 2013)',
  [CurrencySymbolsEnum.ZMW]: 'Zambian Kwacha',
  [CurrencySymbolsEnum.ZWL]: 'Zimbabwean Dollar',
} as const;

/** @deprecated */
export const getDeprecatedCurrencySymbolLabel = (currencySymbol: CurrencySymbolsEnum): string =>
  `${currencySymbol} - ${CURRENCY_SYMBOL_LABELS[currencySymbol]}`;

/** @deprecated */
export const DEPRECATED_CURRENCY_SYMBOL_LABELS: Record<CurrencySymbolsEnum, string> = Object.values(
  CurrencySymbolsEnum,
).reduce(
  (acc, currencySymbol) => ({
    ...acc,
    [currencySymbol]: getDeprecatedCurrencySymbolLabel(currencySymbol),
  }),
  {} as Record<CurrencySymbolsEnum, string>,
);
