import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  ROLE: { defaultMessage: 'Role' },
  SPECIFIC_USER: { defaultMessage: 'Specific user' },
  ROLE_DROPDOWN_LABEL: { defaultMessage: 'Role - Step {stepIndex, number} - Condition {conditionIndex, number}' },
  USER_DROPDOWN_LABEL: { defaultMessage: 'User - Step {stepIndex, number} - Condition {conditionIndex, number}' },
  CONDITION_TYPE_DROPDOWN: {
    defaultMessage: 'Condition type - Step {stepIndex, number} - Condition {conditionIndex, number}',
  },
  DELETE_CONDITION: {
    defaultMessage: 'Delete condition - Step {stepIndex, number} - Condition {conditionIndex, number}',
  },
  OR: { defaultMessage: 'OR' },
} as const);
