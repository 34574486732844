import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  NEW_VALUE: {
    defaultMessage: 'New value',
  },
  OLD_VALUE: {
    defaultMessage: 'Old value',
  },
  COPY_VALUE: {
    defaultMessage: '{value} (click to copy)',
  },
  CONVERTED_FROM: {
    defaultMessage: 'Converted from {value}',
  },
} as const);
