import { css, type Theme } from '@emotion/react';

export const cellDatePickerAnchor = (theme: Theme) => css`
  width: 100%;

  transition: ${theme.ds.transitions.default('box-shadow', 'outline')};

  &:not(:disabled):hover,
  &:focus-visible,
  &.isCalendarOpen {
    outline: 1px solid ${theme.ds.colors.secondary[200]};
  }

  &:focus-visible,
  &.isCalendarOpen {
    box-shadow: ${theme.ds.shadows.focusMedium(theme.ds.colors.secondary[50])};
    background-color: ${theme.ds.colors.gray[0]};
  }
`;

export const cellDatePickerContent = css`
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  box-sizing: border-box;
`;

export const icon = css`
  flex: none;
`;

export const placeholder = (theme: Theme) => css`
  color: ${theme.ds.colors.gray[400]};

  button:disabled & {
    color: ${theme.ds.colors.gray[300]};
  }
`;
