import { type CalculationScope } from '@amal-ia/amalia-lang/formula/evaluate';
import { http } from '@amal-ia/frontend/kernel/http';

const apiEndpoint = '/scope';

export async function getScope(): Promise<CalculationScope> {
  const { data } = await http.get(`${apiEndpoint}`);
  return data;
}

export default {
  getScope,
};
