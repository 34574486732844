import { FormControlLabel, Radio, type RadioProps } from '@mui/material';
import { memo } from 'react';

import { type OptionType } from '../../../../utils/common.types';
import { TextType, Text } from '../../typography';

interface RadioOptionProps extends OptionType, Pick<RadioProps, 'className' | 'color' | 'disabled'> {}

export const RadioOption = memo(function RadioOption({ label, value, color, className, disabled }: RadioOptionProps) {
  return (
    <FormControlLabel
      className={className}
      control={<Radio color={color || 'primary'} />}
      disabled={disabled}
      label={<Text type={disabled ? TextType.INPUT_NAME_DISABLED : TextType.INPUT_NAME}>{label}</Text>}
      value={value}
    />
  );
});
