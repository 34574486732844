import { type IntlConfig } from '@amal-ia/frontend/kernel/intl/types';
import { LanguagesEnum } from '@amal-ia/kernel/intl/types';

export const intlConfig = {
  locales: Object.values(LanguagesEnum),
  defaultLocale: LanguagesEnum.en,
  localeLabels: {
    [LanguagesEnum.en]: 'English',
    [LanguagesEnum.fr]: 'Français',
    [LanguagesEnum.pt]: 'Português',
    [LanguagesEnum.de]: 'Deutsch',
    [LanguagesEnum.es]: 'Español',
    [LanguagesEnum.it]: 'Italiano',
  },
} as const satisfies IntlConfig<LanguagesEnum>;
