import { lazy, memo } from 'react';

import { Layout } from '@amal-ia/frontend/connected-components/layout';

const Home = lazy(() => import('./Home'));

export const HomeView = memo(function HomeView() {
  return (
    <Layout currentPage="home">
      <Home />
    </Layout>
  );
});
