import { combineReducers } from 'redux';

import { type PlanAgreement } from '@amal-ia/lib-types';

import { PLAN_AGREEMENTS_ACTIONS, type ActionTypes } from './constants';

type Action =
  | {
      type:
        | ActionTypes['ARCHIVE_PLAN_AGREEMENT']
        | ActionTypes['CONFIRM_PLAN_AGREEMENT']
        | ActionTypes['CREATE_PLAN_AGREEMENT']
        | ActionTypes['FINALIZE_PLAN_AGREEMENT']
        | ActionTypes['PATCH_PLAN_AGREEMENT']
        | ActionTypes['REFRESH_TEMPLATE']
        | ActionTypes['REGENERATE_AGREEMENTS']
        | ActionTypes['SEND_AGREEMENT']
        | ActionTypes['SEND_AGREEMENTS_NOT_SEND_YET']
        | ActionTypes['SET_CURRENT_PLAN_AGREEMENT'];
      payload: { planAgreement: PlanAgreement };
    }
  | {
      type: ActionTypes['DELETE_PLAN_AGREEMENT'];
      payload: { planAgreementId: string };
    }
  | {
      type: ActionTypes['ERROR'] | ActionTypes['RESET_CURRENT_PLAN_AGREEMENT'] | ActionTypes['START'];
    }
  | {
      type: ActionTypes['SET_PLAN_AGREEMENTS'];
      payload: { planAgreements: PlanAgreement[] };
    };

export default combineReducers({
  currentPlanAgreement: (state: PlanAgreement | null = null, action: Action) => {
    switch (action.type) {
      case PLAN_AGREEMENTS_ACTIONS.ARCHIVE_PLAN_AGREEMENT:
      case PLAN_AGREEMENTS_ACTIONS.CONFIRM_PLAN_AGREEMENT:
      case PLAN_AGREEMENTS_ACTIONS.CREATE_PLAN_AGREEMENT:
      case PLAN_AGREEMENTS_ACTIONS.FINALIZE_PLAN_AGREEMENT:
      case PLAN_AGREEMENTS_ACTIONS.PATCH_PLAN_AGREEMENT:
      case PLAN_AGREEMENTS_ACTIONS.REFRESH_TEMPLATE:
      case PLAN_AGREEMENTS_ACTIONS.REGENERATE_AGREEMENTS:
      case PLAN_AGREEMENTS_ACTIONS.SEND_AGREEMENT:
      case PLAN_AGREEMENTS_ACTIONS.SEND_AGREEMENTS_NOT_SEND_YET:
      case PLAN_AGREEMENTS_ACTIONS.SET_CURRENT_PLAN_AGREEMENT:
        return action.payload.planAgreement;
      case PLAN_AGREEMENTS_ACTIONS.DELETE_PLAN_AGREEMENT:
      case PLAN_AGREEMENTS_ACTIONS.RESET_CURRENT_PLAN_AGREEMENT:
        return null;
      default:
        return state;
    }
  },
  isLoading: (state: number = 0, action: Action) => {
    switch (action.type) {
      case PLAN_AGREEMENTS_ACTIONS.START:
        return state + 1;
      case PLAN_AGREEMENTS_ACTIONS.ERROR:
      case PLAN_AGREEMENTS_ACTIONS.ARCHIVE_PLAN_AGREEMENT:
      case PLAN_AGREEMENTS_ACTIONS.CONFIRM_PLAN_AGREEMENT:
      case PLAN_AGREEMENTS_ACTIONS.CREATE_PLAN_AGREEMENT:
      case PLAN_AGREEMENTS_ACTIONS.DELETE_PLAN_AGREEMENT:
      case PLAN_AGREEMENTS_ACTIONS.FINALIZE_PLAN_AGREEMENT:
      case PLAN_AGREEMENTS_ACTIONS.PATCH_PLAN_AGREEMENT:
      case PLAN_AGREEMENTS_ACTIONS.REFRESH_TEMPLATE:
      case PLAN_AGREEMENTS_ACTIONS.REGENERATE_AGREEMENTS:
      case PLAN_AGREEMENTS_ACTIONS.SEND_AGREEMENT:
      case PLAN_AGREEMENTS_ACTIONS.SEND_AGREEMENTS_NOT_SEND_YET:
      case PLAN_AGREEMENTS_ACTIONS.SET_CURRENT_PLAN_AGREEMENT:
      case PLAN_AGREEMENTS_ACTIONS.SET_PLAN_AGREEMENTS:
        return state - 1;
      default:
        return state;
    }
  },
  list: (state: PlanAgreement[] = [], action: Action) => {
    switch (action.type) {
      case PLAN_AGREEMENTS_ACTIONS.SET_PLAN_AGREEMENTS:
        return action.payload.planAgreements;
      case PLAN_AGREEMENTS_ACTIONS.CREATE_PLAN_AGREEMENT:
        return [...state, action.payload.planAgreement];
      case PLAN_AGREEMENTS_ACTIONS.DELETE_PLAN_AGREEMENT:
        return state.filter((planAgreement) => planAgreement.id !== action.payload.planAgreementId);
      case PLAN_AGREEMENTS_ACTIONS.ARCHIVE_PLAN_AGREEMENT:
      case PLAN_AGREEMENTS_ACTIONS.CONFIRM_PLAN_AGREEMENT:
      case PLAN_AGREEMENTS_ACTIONS.FINALIZE_PLAN_AGREEMENT:
      case PLAN_AGREEMENTS_ACTIONS.PATCH_PLAN_AGREEMENT:
      case PLAN_AGREEMENTS_ACTIONS.REFRESH_TEMPLATE:
      case PLAN_AGREEMENTS_ACTIONS.REGENERATE_AGREEMENTS:
      case PLAN_AGREEMENTS_ACTIONS.SEND_AGREEMENT:
      case PLAN_AGREEMENTS_ACTIONS.SEND_AGREEMENTS_NOT_SEND_YET:
      case PLAN_AGREEMENTS_ACTIONS.SET_CURRENT_PLAN_AGREEMENT:
        return state.map((planAgreement) => {
          if (planAgreement.id === action.payload.planAgreement.id) {
            return action.payload.planAgreement;
          }
          return planAgreement;
        });
      default:
        return state;
    }
  },
});
