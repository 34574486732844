import { createContext } from 'react';

import { type FormatsEnum } from '@amal-ia/data-capture/fields/types';

interface TableBuilderContextInterface {
  isReadonly: boolean;
  shouldFormatCellValue: boolean;
  onAddRow: (referenceRowIndex: number, diff: number) => void;
  onChangeColumnFormat: (columnName: string, newFormat: FormatsEnum) => void;
  onAddColumn: (referenceColumnName: string, diff: number) => void;
  onDeleteRow: (referenceRowIndex: number) => void;
  onRenameColumn: (columnMachineName: any) => void;
  onDeleteColumn: (referenceColumnName: string) => void;
  nbRows: number;
  nbColumns: number;
}

export const TableBuilderContext = createContext<TableBuilderContextInterface>({} as TableBuilderContextInterface);
