export enum ModalVariant {
  DEFAULT = 'DEFAULT',
  DANGER = 'DANGER',
}

export enum ModalSize {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}
