// TODO: Move it?

export enum CompanyFeatureFlags {
  BADGES = 'BADGES',
  CALCULATE_STATEMENT_TWICE = 'CALCULATE_STATEMENT_TWICE',
  CR_CALCULATED_FIELDS = 'CR_CALCULATED_FIELDS',
  CSM_CHAT = 'CSM_CHAT',
  DASHBOARDS = 'DASHBOARDS',
  FINANCE_USER_ROLE = 'FINANCE_USER_ROLE',
  FORECAST = 'FORECAST',
  FORMULA_AUTOCOMPLETE = 'FORMULA_AUTOCOMPLETE',
  PAYMENTS_LOCK = 'PAYMENTS_LOCK',
  STATEMENT_PDF_EXPORT = 'STATEMENT_PDF_EXPORT',
  FLOWS = 'FLOWS',
  LIVE_DATA_SYNC = 'LIVE_DATA_SYNC',
  DATA_CONNECTOR_POWER_BI = 'DATA_CONNECTOR_POWER_BI',
  USER_CONNECTOR_ADP = 'USER_CONNECTOR_ADP',
  USER_CONNECTOR_BAMBOO = 'USER_CONNECTOR_BAMBOO',
  USER_CONNECTOR_PAYFIT = 'USER_CONNECTOR_PAYFIT',
  USER_CONNECTOR_PERSONIO = 'USER_CONNECTOR_PERSONIO',
  USER_CONNECTOR_SAGE = 'USER_CONNECTOR_SAGE',
  USER_CONNECTOR_SAP = 'USER_CONNECTOR_SAP',
  USER_CONNECTOR_WORKDAY = 'USER_CONNECTOR_WORKDAY',
  TARGET_LETTER = 'TARGET_LETTER',
  PLAN_AGREEMENTS = 'PLAN_AGREEMENTS',
  REP_HOME = 'REP_HOME',
  SFDC_TEAM_SYNC = 'SFDC_TEAM_SYNC',
  FLOWS_ENV_ONLY = 'FLOWS_ENV_ONLY',
  STATEMENT_EVOLUTION = 'STATEMENT_EVOLUTION',
  HUBSPOT_TEAM_SYNC = 'HUBSPOT_TEAM_SYNC',
  SALESFORCE_SECOND_ACCOUNT = 'SALESFORCE_SECOND_ACCOUNT',
}
