import { useTheme } from '@emotion/react';
import { IconCheck, IconMinus } from '@tabler/icons-react';
import clsx from 'clsx';
import { memo, type ComponentPropsWithoutRef } from 'react';

import { type MergeAll } from '@amal-ia/ext/typescript';

import * as styles from './CheckboxIcon.styles';
import { checkboxIconTestIds } from './CheckboxIcon.testIds';
import { CheckboxIconSize } from './CheckboxIcon.types';

const ICON_SIZE_MAPPING: Record<CheckboxIconSize, number> = {
  [CheckboxIconSize.SMALL]: 8,
  [CheckboxIconSize.MEDIUM]: 9,
  [CheckboxIconSize.LARGE]: 10,
};

export type CheckboxIconProps = MergeAll<
  [
    ComponentPropsWithoutRef<'div'>,
    {
      /** Size of the checkbox icon. */
      size?: CheckboxIconSize;
      /** Is the checkbox checked. */
      checked?: boolean;
      /** Is the checkbox indeterminate. Takes precedence over checked. */
      indeterminate?: boolean;
      /** Is the checkbox disabled. */
      disabled?: boolean;
    },
  ]
>;

const CheckboxIconBase = memo(function CheckboxIcon({
  checked = undefined,
  indeterminate = undefined,
  disabled = undefined,
  size = CheckboxIconSize.MEDIUM,
  ...props
}: CheckboxIconProps) {
  const theme = useTheme();
  const Icon = indeterminate ? IconMinus : checked ? IconCheck : null;

  return (
    <div
      data-testid={props.id ? checkboxIconTestIds.icon(props.id) : undefined} // Make data-testid overridable by passing props.
      {...props}
      css={styles.checkboxIcon}
      className={clsx(size, {
        [styles.DISABLED_CLASSNAME]: disabled,
        [styles.CHECKED_CLASSNAME]: checked,
        [styles.INDETERMINATE_CLASSNAME]: indeterminate,
      })}
    >
      {!!Icon && (
        <Icon
          color={theme.ds.colors.gray[0]}
          size={ICON_SIZE_MAPPING[size]}
          strokeWidth={4}
        />
      )}
    </div>
  );
});

export const CheckboxIcon = Object.assign(CheckboxIconBase, {
  Size: CheckboxIconSize,
});
