import { type Dispatch, type SetStateAction, createContext, useContext } from 'react';

export type LayoutContextValue = {
  portalElement?: HTMLElement | null;
  setIsPortalActive?: Dispatch<SetStateAction<boolean>>;
};

export const LayoutContext = createContext<LayoutContextValue>({});

export const useLayoutContext = () => useContext(LayoutContext);
