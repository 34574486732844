import { Global, type Theme, css } from '@emotion/react';

export const DEFAULT_THEME_GLOBAL_STYLES: Theme['ds']['globalStyles'] = (
  <Global
    styles={(theme) => css`
      @import url('https://fonts.googleapis.com/css2?family=Nunito:wght@700&family=Roboto+Mono:wght@400&family=Roboto:wght@400;500;700&display=swap');

      /* Reset gutters. */
      *,
      *::after,
      *::before {
        padding: 0;
        margin: 0;
        box-sizing: border-box;
      }

      html,
      body,
      #root {
        font-family: ${theme.ds.typographies.bodyBaseRegular.fontFamily};
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        height: 100%;
        width: 100%;
      }
    `}
  />
);
