import { type Theme } from '@emotion/react';

import { ShadowVariant } from '@amal-ia/frontend/design-system/meta';

export const DEFAULT_THEME_SHADOWS = {
  [ShadowVariant.SOFT]: '0 1px 4px 0 rgba(0, 0, 0, .05)',
  [ShadowVariant.HARD]: '0 1px 4px 0 rgba(0, 0, 0, .15)',
  [ShadowVariant.FOCUS_XSMALL]: (color) => `0 0 0 2px ${color}`,
  [ShadowVariant.FOCUS_SMALL]: (color) => `0 0 0 3px ${color}`,
  [ShadowVariant.FOCUS_MEDIUM]: (color) => `0 0 0 4px ${color}`,
  [ShadowVariant.FOCUS_LARGE]: (color) => `0 0 0 5px ${color}`,
} as const satisfies Theme['ds']['shadows'];
