import styled from '@emotion/styled';

import { ModalSize } from '../Modal.types';

export const ModalBody = styled.div`
  ${({ theme }) => theme.ds.typographies.bodyBaseRegular};
  transition: ${({ theme }) => theme.ds.transitions.default('padding')};

  .${ModalSize.SMALL} & {
    padding: 0 24px;
  }

  .${ModalSize.MEDIUM} & {
    padding: 0 32px;
  }

  .${ModalSize.LARGE} & {
    padding: 0 40px;
  }
`;
