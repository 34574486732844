import { type ComponentPropsWithoutRef, type ForwardedRef, forwardRef, memo } from 'react';

import { useShallowObjectMemo } from '@amal-ia/ext/react/hooks';
import { type MergeAll } from '@amal-ia/ext/typescript';

import { FormLayoutCheckboxGroup } from './form-layout-checkbox-group/FormLayoutCheckboxGroup';
import { FormLayoutGroup } from './form-layout-group/FormLayoutGroup';
import { FormLayoutRow } from './form-layout-row/FormLayoutRow';
import { FormLayoutTitle } from './form-layout-title/FormLayoutTitle';
import { FormLayoutContext, type FormLayoutContextValue } from './FormLayout.context';
import * as styles from './FormLayout.styles';
import { FormLayoutDirection, FormLayoutSize } from './FormLayout.types';

export type FormLayoutProps = MergeAll<
  [
    ComponentPropsWithoutRef<'div'>,
    {
      size?: FormLayoutSize;
      direction?: FormLayoutDirection;
    },
  ]
>;

const FormLayoutForwardRef = forwardRef(function FormLayout(
  { size = FormLayoutSize.MEDIUM, direction = FormLayoutDirection.VERTICAL, children, ...props }: FormLayoutProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const contextValue = useShallowObjectMemo<FormLayoutContextValue>({
    size,
    direction,
  });

  return (
    <FormLayoutContext.Provider value={contextValue}>
      <div
        {...props}
        ref={ref}
        css={styles.formLayout}
        data-form-layout-direction={direction}
        data-form-layout-size={size}
      >
        {children}
      </div>
    </FormLayoutContext.Provider>
  );
});

export const FormLayout = Object.assign(memo(FormLayoutForwardRef), {
  Size: FormLayoutSize,
  Direction: FormLayoutDirection,
  Title: FormLayoutTitle,
  Row: FormLayoutRow,
  Group: FormLayoutGroup,
  CheckboxGroup: FormLayoutCheckboxGroup,
});
