import { type ActionCreator } from 'redux';

import { FiltersApiClient } from '@amal-ia/compensation-definition/api-client';
import { type ReduxAction, type ThunkResult } from '@amal-ia/lib-types';

import { addSnackbar } from '../snackbars/actions';

import { FILTERS_ACTIONS } from './constants';

const filtersStart: ActionCreator<ReduxAction> = () => ({
  type: FILTERS_ACTIONS.START,
});

const filtersError: ActionCreator<ReduxAction> = (error: Error) => ({
  type: FILTERS_ACTIONS.ERROR,
  error,
});

const setFilters: ActionCreator<ReduxAction> = (filters: any) => ({
  type: FILTERS_ACTIONS.SET_FILTERS,
  payload: { filters },
});

export const fetchFiltersAction = (): ThunkResult<Promise<ReduxAction>> => async (dispatch) => {
  dispatch(filtersStart());
  try {
    const filters = await FiltersApiClient.list();
    return dispatch(setFilters(filters));
  } catch (error) {
    return dispatch(filtersError(error));
  }
};

export const duplicateFiltersInNewContextAction =
  (filtersId: string[], planId: string, ruleId: string): ThunkResult<Promise<ReduxAction>> =>
  async (dispatch) => {
    dispatch(filtersStart());
    try {
      const { isErrorWhileDuplicating } = await FiltersApiClient.duplicateInNewContext(filtersId, {
        planId,
        ruleId,
      });
      if (isErrorWhileDuplicating) {
        dispatch(
          addSnackbar({
            message: 'Error while importing filter(s). Filter(s) with the same name already exist.',
            options: { variant: 'error' },
          }),
        );
      }
      const filtersUpdated = await FiltersApiClient.list();
      return dispatch(setFilters(filtersUpdated));
    } catch (error) {
      return dispatch(filtersError(error));
    }
  };
