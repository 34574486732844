import { type TablerIconsProps, IconSlash } from '@tabler/icons-react';
import { type ElementType, memo } from 'react';

import { type MergeAll } from '@amal-ia/ext/typescript';

import { IconOverlap } from '../icon-overlap/IconOverlap';

export type IconNotProps = MergeAll<
  [
    TablerIconsProps,
    {
      /** Icon on the background that'll be "slashed". */
      icon: ElementType<TablerIconsProps>;
    },
  ]
>;

export const IconNot = memo(function IconNot({ icon: Icon, ...props }: IconNotProps) {
  return (
    <IconOverlap
      iconBackground={Icon}
      iconForeground={IconSlash}
      {...props}
    />
  );
});
