import { FormatsEnum } from '@amal-ia/data-capture/fields/types';
import { type CurrencySymbolsEnum } from '@amal-ia/ext/iso-4217';
import { formatValue } from '@amal-ia/lib-types';

/**
 * Format currency amount to short format (1.2k, 1.2m, 1.2b, 1.2t)
 * @param amount
 * @param currency
 */
const formatCurrencyAmountShortFormat = (amount: number, currency: CurrencySymbolsEnum) =>
  new Intl.NumberFormat(undefined, {
    notation: 'compact',
    compactDisplay: 'short',
    style: FormatsEnum.currency,
    currency,
  }).format(amount);

/**
 * Format value based on the format type.
 * @param value
 * @param format
 * @param currency
 */
export const formatChartValue = (value: number, format: FormatsEnum, currency?: CurrencySymbolsEnum): string => {
  switch (format) {
    case FormatsEnum.currency:
      return formatCurrencyAmountShortFormat(value, currency);
    case FormatsEnum.percent:
      return formatValue(value, FormatsEnum.percent);
    default:
      return `${value}`;
  }
};
