import { useCallback, useMemo } from 'react';

import { type SelectDropdownStateProps, type SelectDropdownOption } from '../SelectDropdown.types';

export type UseStateWithOptionsOptions<
  TOption extends SelectDropdownOption = SelectDropdownOption,
  TIsMultiple extends boolean | undefined = undefined,
  TUseOptionAsValue extends boolean | undefined = undefined,
  TIsClearable extends boolean | undefined = undefined,
> = SelectDropdownStateProps<TOption, TIsMultiple, TUseOptionAsValue, TIsClearable> & {
  /** Flat list of options. */
  options: TOption[];
};

export type UseStateWithOptionsValue<
  TOption extends SelectDropdownOption = SelectDropdownOption,
  TIsMultiple extends boolean | undefined = undefined,
  TIsClearable extends boolean | undefined = undefined,
> = {
  /** Option or list of options or null. */
  value: Required<SelectDropdownStateProps<TOption, TIsMultiple, true, TIsClearable>>['value'];
  /** Value change handler. */
  onChange: Required<SelectDropdownStateProps<TOption, TIsMultiple, true, TIsClearable>>['onChange'];
};

/**
 * This hook transforms simplified state props into the full state props.
 * I.e. this returns the state and onChange handler as if useOptionAsValue was true, so we don't need to handle it everywhere.
 */
export const useStateWithOptions = <
  TOption extends SelectDropdownOption = SelectDropdownOption,
  TIsMultiple extends boolean | undefined = undefined,
  TUseOptionAsValue extends boolean | undefined = undefined,
  TIsClearable extends boolean | undefined = undefined,
>({
  isMultiple,
  useOptionAsValue,
  value,
  onChange,
  options,
}: UseStateWithOptionsOptions<TOption, TIsMultiple, TUseOptionAsValue, TIsClearable>): UseStateWithOptionsValue<
  TOption,
  TIsMultiple,
  TIsClearable
> => {
  const onChangeProxy: UseStateWithOptionsValue<TOption, TIsMultiple, TIsClearable>['onChange'] = useCallback(
    (newValue: TOption | TOption[] | null) => {
      if (useOptionAsValue) {
        const onChangeUseOptionAsValue = onChange as SelectDropdownStateProps<
          TOption,
          TIsMultiple,
          true,
          TIsClearable
        >['onChange'];

        onChangeUseOptionAsValue?.(newValue as Parameters<typeof onChangeUseOptionAsValue>[0]);
      } else if (isMultiple) {
        (onChange as SelectDropdownStateProps<TOption, true, false, TIsClearable>['onChange'])?.(
          (newValue as TOption[]).map((option) => option.value),
        );
      } else {
        (onChange as SelectDropdownStateProps<TOption, false, false, true>['onChange'])?.(
          (newValue as TOption | null)?.value ?? null,
        );
      }
    },
    [onChange, isMultiple, useOptionAsValue],
  );

  const valueProxy: UseStateWithOptionsValue<TOption, TIsMultiple, TIsClearable>['value'] = useMemo(() => {
    if (useOptionAsValue) {
      return (isMultiple ? value ?? [] : value ?? null) as UseStateWithOptionsValue<
        TOption,
        TIsMultiple,
        TIsClearable
      >['value'];
    }

    return (
      isMultiple
        ? ((value || []) as Required<SelectDropdownStateProps<TOption, true, false, TIsClearable>>['value'])
            .map((optionValue) => options.find((option) => option.value === optionValue))
            .filter(Boolean)
        : options.find((option) => option.value === value) ?? null
    ) as UseStateWithOptionsValue<TOption, TIsMultiple, TIsClearable>['value'];
  }, [isMultiple, options, useOptionAsValue, value]);

  return {
    onChange: onChangeProxy,
    value: valueProxy,
  };
};
