import { http } from '@amal-ia/frontend/kernel/http';
import { type Statement } from '@amal-ia/lib-types';

export async function saGetStatementsOnError(companiesId: string[]): Promise<Statement[]> {
  const { data } = await http.get<Statement[]>('/sa_statements/with_errors', {
    params: {
      companiesId,
    },
  });
  return data;
}
