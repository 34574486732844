import { defineMessages } from 'react-intl';

export const adjustmentModalMessages = defineMessages({
  AMOUNT: { defaultMessage: 'Amount (in {currency})' },
  APPLIES_TO_RECORD: { defaultMessage: 'Apply to a record' },
  APPLIES_TO_RECORD_HELP: {
    defaultMessage: 'You can link this adjustment to a record, which will be helpful later when generating reports.',
  },
  DESCRIPTION: { defaultMessage: 'Description' },
  PAYMENT_PERIOD_HELP: {
    defaultMessage:
      'The adjustment will be achieved on the period of the statement, but can be paid on another period.',
  },
  PAYMENT_PERIOD: { defaultMessage: 'Payment period' },
  RECORD_EXTERNAL_ID: { defaultMessage: 'Record external ID' },
  RECORD_TYPE: { defaultMessage: 'Record type' },
  NONE: { defaultMessage: 'None' },
} as const);
