import { noop } from 'lodash';
import {
  type ForwardedRef,
  forwardRef,
  memo,
  type ChangeEventHandler,
  useCallback,
  useState,
  type ComponentPropsWithoutRef,
} from 'react';

import { type MergeAll } from '@amal-ia/ext/typescript';

import { CheckboxIcon, type CheckboxIconProps } from '../../general/checkbox-icon/CheckboxIcon';
import { CheckboxIconSize } from '../../general/checkbox-icon/CheckboxIcon.types';
import { useFormLayoutContext } from '../../layout/form-layout/FormLayout.context';
import { FormLayoutSize } from '../../layout/form-layout/FormLayout.types';
import { FormField } from '../meta/form-field/FormField';
import { useFormFieldProps, type UseFormFieldPropsOptions } from '../meta/form-field/hooks/useFormFieldProps';
import { FieldSize, LabelPosition } from '../meta/types';

import { checkboxFormFieldType } from './Checkbox.constants';
import * as styles from './Checkbox.styles';

const CHECKBOX_SIZE_FIELD_SIZE_MAPPING: Record<CheckboxIconSize, FieldSize> = {
  [CheckboxIconSize.SMALL]: FieldSize.SMALL,
  [CheckboxIconSize.MEDIUM]: FieldSize.MEDIUM,
  [CheckboxIconSize.LARGE]: FieldSize.LARGE,
};

const FORM_LAYOUT_SIZE_CHECKBOX_SIZE_MAPPING: Record<FormLayoutSize, CheckboxIconSize> = {
  [FormLayoutSize.SMALL]: CheckboxIconSize.SMALL,
  [FormLayoutSize.MEDIUM]: CheckboxIconSize.MEDIUM,
};

export type CheckboxProps = MergeAll<
  [
    Omit<ComponentPropsWithoutRef<'input'>, 'type'>,
    Omit<UseFormFieldPropsOptions, 'labelPosition'>,
    {
      /** Field size. */
      size?: CheckboxIconSize;
      /** Checkbox indeterminate state. */
      indeterminate?: CheckboxIconProps['indeterminate'];
      /** Change handler. Called with the new value. */
      onChange?: (checked: boolean) => void;
    },
  ]
>;

const CheckboxForwardRef = forwardRef(function Checkbox(props: CheckboxProps, ref: ForwardedRef<HTMLInputElement>) {
  const { size: formLayoutSize } = useFormLayoutContext() || {};

  const {
    formFieldProps,
    otherProps: {
      size = formLayoutSize ? FORM_LAYOUT_SIZE_CHECKBOX_SIZE_MAPPING[formLayoutSize] : CheckboxIconSize.MEDIUM,
      onChange = noop,
      indeterminate = false,
      ...otherProps
    },
  } = useFormFieldProps(props);

  /**
   * I wanted to keep the checkbox uncontrollable, so the only way to do that is to track the state myself here.
   * It will change when the user clicks on the checkbox, and the parent component will be notified of the change.
   */
  const [checked, setChecked] = useState(otherProps.checked ?? otherProps.defaultChecked ?? false);

  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      const newChecked = event.currentTarget.checked;
      onChange(newChecked);
      setChecked(newChecked);
    },
    [onChange],
  );

  return (
    <FormField
      {...formFieldProps}
      formFieldType={checkboxFormFieldType}
      labelPosition={LabelPosition.RIGHT}
      size={CHECKBOX_SIZE_FIELD_SIZE_MAPPING[size]}
    >
      <label
        className={size}
        css={styles.label}
      >
        <input
          {...otherProps}
          ref={ref}
          css={styles.hidden}
          type="checkbox"
          onChange={handleChange}
        />

        <CheckboxIcon
          // If the checkbox is controlled, use the value from the props, otherwise use the state.
          checked={otherProps.checked ?? checked}
          disabled={otherProps.disabled}
          indeterminate={indeterminate}
          size={size}
        />
      </label>
    </FormField>
  );
});

export const Checkbox = Object.assign(memo(CheckboxForwardRef), {
  Size: CheckboxIconSize,
});
