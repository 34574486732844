import { TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import { Box } from '@mui/material';
import clsx from 'clsx';
import { memo, useContext } from 'react';

import { InfoWithTooltip, useDatagridStyles, Text, TextType } from '@amal-ia/lib-ui';

import RowsTableContext from './RowsTableContext';
import useRowsTableStyles from './useRowsTableStyles';

const RowsTableHeaderCell = memo(function RowsTableHeaderCell({
  column,
  children,
  ...restProps
}: TableHeaderRow.CellProps) {
  const classes = useDatagridStyles();
  const classesRowsTable = useRowsTableStyles();

  const { columnDescriptions } = useContext(RowsTableContext);

  const helpLabel = columnDescriptions[column.name];

  return (
    <TableHeaderRow.Cell
      column={column}
      {...restProps}
      className={clsx(
        (restProps as any).className,
        classes.header,
        column.name === 'actions' ? classesRowsTable.headerActionsCell : undefined,
      )}
    >
      <Text type={TextType.TABLE_HEADER}>
        {children}

        {helpLabel ? (
          <InfoWithTooltip>
            <Box whiteSpace="pre-wrap">{helpLabel}</Box>
          </InfoWithTooltip>
        ) : null}
      </Text>
    </TableHeaderRow.Cell>
  );
});

export default RowsTableHeaderCell;
