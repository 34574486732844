import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { AlertBanner } from '@amal-ia/frontend/design-system/components';
import { DrawingStatementNotFound } from '@amal-ia/lib-ui';

import { messages } from './StatementSummaryWidgetQuotaError.messages';
import * as styles from './StatementSummaryWidgetQuotaError.styles';

export const StatementSummaryWidgetQuotaError = memo(function StatementSummaryWidgetQuotaError() {
  return (
    <div css={styles.container}>
      <div css={styles.alert}>
        <AlertBanner variant={AlertBanner.Variant.WARNING}>
          <FormattedMessage {...messages.STATEMENT_QUOTA_ERROR} />
        </AlertBanner>
      </div>

      <DrawingStatementNotFound css={styles.illustration} />
    </div>
  );
});
