import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  EXPORT: { defaultMessage: 'Export' },
  NEW_ADJUSTMENT: { defaultMessage: 'Make an adjustment' },
  STATEMENT_COMMENTS: { defaultMessage: 'Comments' },
  PLAN_DOCUMENTATION: { defaultMessage: 'Plan documentation' },
  EDIT_IN_DESIGNER: { defaultMessage: 'Edit in Designer' },
  GO_TO_QUOTA: { defaultMessage: 'User quotas' },
  MORE_ACTIONS: { defaultMessage: 'More actions' },
  ACHIEVED: { defaultMessage: 'Achieved' },
  FORECASTED: { defaultMessage: 'Forecasted' },
} as const);
