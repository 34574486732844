export enum QuickEditVariant {
  HEADING_1_MEDIUM = 'heading1Medium',
  HEADING_1_BOLD = 'heading1Bold',
  HEADING_2_MEDIUM = 'heading2Medium',
  HEADING_2_BOLD = 'heading2Bold',
  HEADING_3_MEDIUM = 'heading3Medium',
  HEADING_3_BOLD = 'heading3Bold',
  HEADING_4_MEDIUM = 'heading4Medium',
  HEADING_4_BOLD = 'heading4Bold',
  BODY_LARGE_MEDIUM = 'bodyLargeMedium',
  BODY_LARGE_BOLD = 'bodyLargeBold',
  BODY_BASE_MEDIUM = 'bodyBaseMedium',
  BODY_BASE_BOLD = 'bodyBaseBold',
  BODY_SMALL_MEDIUM = 'bodySmallMedium',
  BODY_SMALL_BOLD = 'bodySmallBold',
  BODY_XSMALL_MEDIUM = 'bodyXsmallMedium',
  BODY_XSMALL_BOLD = 'bodyXsmallBold',
}
