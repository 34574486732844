import { http } from '@amal-ia/frontend/kernel/http';
import { type ChangelogEntryResponse } from '@amal-ia/lib-types';

export const getChangelogEntries = async (
  newEntries: boolean,
  cursor?: string | null,
  from?: Date | null,
): Promise<ChangelogEntryResponse> => {
  const { data } = await http.get(
    `/changelog-entries?cursor=${cursor || ''}&from=${from || ''}&new=${newEntries ? 'true' : 'false'}`,
  );
  return data;
};
