import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { AlertBanner } from '@amal-ia/frontend/design-system/components';
import { DrawingSandglass } from '@amal-ia/lib-ui';

import { messages } from './StatementSummaryWidgetNotAvailable.messages';
import * as styles from './StatementSummaryWidgetNotAvailable.styles';

export const StatementSummaryWidgetNotAvailable = memo(function StatementSummaryWidgetNotAvailable() {
  return (
    <div css={styles.container}>
      <div css={styles.alert}>
        <AlertBanner variant={AlertBanner.Variant.INFO}>
          <FormattedMessage {...messages.STATEMENT_NOT_AVAILABLE} />
        </AlertBanner>
      </div>

      <DrawingSandglass />
    </div>
  );
});
