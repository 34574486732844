import { keyBy } from 'lodash';
import { combineReducers } from 'redux';

import { type ReduxAction } from '@amal-ia/lib-types';
import { type UserContract } from '@amal-ia/tenants/users/shared/types';

import { USERS_ACTIONS } from './constants';

export type UsersActionPayload = {
  users?: UserContract[];
  allUsers?: boolean;
  activeUsers?: boolean;
};

export default combineReducers({
  isLoading: (state: number = 0, action: ReduxAction) => {
    switch (action.type) {
      case USERS_ACTIONS.START:
        return state + 1;
      case USERS_ACTIONS.ERROR:
      case USERS_ACTIONS.SET_USERS:
        return state - 1;
      default:
        return state;
    }
  },
  map: (state: Record<string, UserContract> = {}, action: ReduxAction<string, UsersActionPayload>) => {
    switch (action.type) {
      case USERS_ACTIONS.FLUSH_USERS:
        return {};
      case USERS_ACTIONS.SET_USERS:
        return {
          // Destructuring state to clone it.
          ...state,
          // Make a keyBy object with new users, then destructure it to
          // merge it with the previous state.
          ...keyBy(action.payload.users, 'id'),
        };
      default:
        return state;
    }
  },
  allUsersLoaded: (state: boolean = false, action: ReduxAction<string, UsersActionPayload>) => {
    switch (action.type) {
      case USERS_ACTIONS.SET_USERS:
        return action.payload.allUsers || state;
      case USERS_ACTIONS.FLUSH_USERS:
        return false;
      default:
        return state;
    }
  },
  allActiveUsersLoaded: (state: boolean = false, action: ReduxAction<string, UsersActionPayload>) => {
    switch (action.type) {
      case USERS_ACTIONS.SET_USERS:
        return action.payload.activeUsers || state;
      case USERS_ACTIONS.FLUSH_USERS:
        return false;
      default:
        return state;
    }
  },
});
