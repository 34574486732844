import { makeStyles } from '@mui/styles';

import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';

const styles = makeStyles((theme: AmaliaThemeType) => ({
  editContainer: {
    display: 'flex',
    marginTop: theme.spacing(2),
    gap: '1rem',
    width: '100%',
  },

  editContentFullWidth: {},

  editContent: {
    flex: '0 0 calc(100% - 1rem - 250px)',
    maxWidth: 'calc(100% - 1rem - 250px)',
    height: '100%',
    transition: 'max-width .2s, flex .2s',

    '&$editContentFullWidth': {
      flex: '0 0 calc(100% - 1rem - 50px)',
      maxWidth: 'calc(100% - 1rem - 50px)',
    },
  },

  drawerCollapsed: {},
  drawer: {
    width: '250px',
    boxSizing: 'border-box',
    maxHeight: '100vh',
    overflowY: 'auto',
    overflowX: 'visible',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.common.white,
    transition: 'width .2s',

    '&$drawerCollapsed': {
      width: '50px',
    },
  },
  drawerSection: {
    margin: theme.spacing(3, 0, 1, 0),
  },

  drawerItemAlwaysVisible: {},
  drawerItemActions: {
    position: 'absolute',
    display: 'flex',
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: 'center',
    opacity: 0,
    transition: 'opacity .2s',
    backgroundColor: theme.palette.grey['100'],

    '&$drawerItemAlwaysVisible': {
      opacity: 1,
    },
  },
  drawerItemAction: {
    opacity: 0,
    transition: 'opacity .2s',

    '&$drawerItemAlwaysVisible': {
      opacity: 1,
    },
  },
  drawerItem: {
    position: 'relative',
    borderRadius: '4px',

    '&, &$listElement': {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: '#f9f9fb !important',
      padding: theme.spacing(1, 1.5),
      margin: theme.spacing(0.5, 0),

      '&:hover $drawerItemActions, &:hover $drawerItemAction': {
        opacity: 1,
      },
    },
  },

  joinManifestItem: {
    borderRadius: '4px',
    border: `2px solid ${theme.palette.grey['200']}`,
    padding: theme.spacing(0.5),
    margin: theme.spacing(1),
  },

  fieldSection: {
    borderRadius: '4px',
    border: `2px solid ${theme.palette.grey['200']}`,
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    display: 'block',
  },
  fieldFormat: {
    height: '1.5rem',
    width: '1.5rem',
    borderRadius: '4px',
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(0, 1),
    color: theme.palette.tertiary.main,
  },
  fieldFormatDownCasted: {
    color: theme.palette.error.main,
  },
  fieldSelectGroupBy: {
    width: 'min-content',
    height: '1.2rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textTransform: 'uppercase',
    color: theme.palette.tertiary.main,
    cursor: 'pointer',
    whiteSpace: 'nowrap',
  },
  filterSelectedField: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 2),
    backgroundColor: theme.palette.grey['100'],
  },
  orSeparator: {
    height: '1px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.grey['200'],
    width: '100%',
    margin: theme.spacing(2, 0),

    '& i': {
      fontStyle: 'normal',
      backgroundColor: theme.palette.common.white,
      padding: theme.spacing(0, 1),
    },
  },
  filterBox: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
    transition: 'background-color .2s',
  },
  filterBoxItem: {
    display: 'flex',
    borderRadius: '4px',
    padding: theme.spacing(1),
    border: `1px solid ${theme.palette.grey['200']}`,
    height: '2rem',
    cursor: 'pointer',
    alignItems: 'center',
  },
  filterBoxItemActive: {
    backgroundColor: theme.palette.grey['100'],
  },
  reportFilterBoxValue: {
    display: 'inline',
  },
}));

export default styles;
