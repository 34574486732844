import { lazy, memo } from 'react';

import { Layout } from '@amal-ia/frontend/connected-components/layout';

const Changelog = lazy(() => import('./Changelog'));

export const ChangelogView = memo(function ChangelogView() {
  return (
    <Layout>
      <Changelog />
    </Layout>
  );
});
