import { makeStyles } from '@mui/styles';
import { Fragment } from 'react';

import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';
import { IconAmaliaLogoColoured } from '@amal-ia/frontend/ui-icons';

import { Text, TextType } from '../generic/typography';

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  title: {
    verticalAlign: 'middle',
    marginLeft: '10px',

    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
}));

const BRAND_NAME = 'Amalia';

export const NavbarBrandColoured = function NavbarBrandColoured() {
  const classes = useStyles();
  return (
    <Fragment>
      <IconAmaliaLogoColoured style={{ width: 48 }} />
      <Text
        className={classes.title}
        type={TextType.NAVBAR_TITLE}
      >
        {BRAND_NAME}
      </Text>
    </Fragment>
  );
};
