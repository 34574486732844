import {
  type AttributeValue,
  type FormulaBuilderFunctionBaseBlock,
  type FormulaBuilderFunctionCategory,
  type ValueOrAttribute,
} from '@amal-ia/amalia-lang/formula/shared/types';

export enum FormulaBuilderUserOperatorNoArgs {
  IS_SELECTED = 'IS_SELECTED',
  IS_BLANK = 'IS_BLANK',
  IS_NOT_BLANK = 'IS_NOT_BLANK',
}

export enum FormulaBuilderUserOperatorOneArg {
  MATCHES = 'MATCHES',
  NOT_MATCHES = 'NOT_MATCHES',
}

export type FormulaBuilderFunctionBlockUserBase = FormulaBuilderFunctionBaseBlock & {
  category: FormulaBuilderFunctionCategory.USER;
  caseSensitive: boolean;
};

export type FormulaBuilderFunctionBlockUser =
  | FormulaBuilderFunctionBlockUserNoArgs
  | FormulaBuilderFunctionBlockUserOneArg;

export type FormulaBuilderFunctionBlockUserNoArgs = FormulaBuilderFunctionBlockUserBase & {
  operator: FormulaBuilderUserOperatorNoArgs;
  args: [AttributeValue];
};

export type FormulaBuilderFunctionBlockUserOneArg = FormulaBuilderFunctionBlockUserBase & {
  operator: FormulaBuilderUserOperatorOneArg;
  args: [AttributeValue, ValueOrAttribute];
};
