import clsx from 'clsx';
import { noop } from 'lodash';
import { memo, useCallback } from 'react';

import { type MergeAll } from '@amal-ia/ext/typescript';

import { Typography } from '../../general/typography/Typography';
import { Tooltip } from '../../overlays/tooltip/Tooltip';
import { FormField } from '../meta/form-field/FormField';
import { useFormFieldProps, type UseFormFieldPropsOptions } from '../meta/form-field/hooks/useFormFieldProps';
import { type RadioOptionValue } from '../radio/Radio.types';

import * as styles from './RadioButtonSingle.styles';
import { type RadioButtonSingleOption } from './RadioButtonSingle.types';

export type RadioButtonSingleProps<TValue extends RadioOptionValue> = MergeAll<
  [
    UseFormFieldPropsOptions,
    {
      /** HTML field name. Used for grouping radio elements. */
      name: string;
      /** Current selected value. */
      value: TValue;
      /** Radio options. */
      options: [RadioButtonSingleOption<TValue>, RadioButtonSingleOption<TValue>];
      /** Change handler. */
      onChange?: (newValue: TValue) => void;
      /** Is the control disabled. */
      disabled?: boolean;
      /** Is the radio button active */
      isActive?: boolean;
    },
  ]
>;

const RadioButtonSingleBase = function RadioButtonSingle<TValue extends RadioOptionValue>(
  props: RadioButtonSingleProps<TValue>,
) {
  const {
    formFieldProps,
    otherProps: { name, value, options, onChange = noop, disabled = false, isActive = false },
  } = useFormFieldProps(props);

  const optionSelected = options.find((option) => option.value === value);

  const handleChange = useCallback(() => {
    const otherOption = options.find((option) => option.value !== value);
    onChange(otherOption?.value);
  }, [onChange, options, value]);

  if (!optionSelected) {
    return null;
  }

  return (
    <FormField {...formFieldProps}>
      <Tooltip content={!disabled && optionSelected.help}>
        <button
          className={clsx({ disabled, isActive })}
          css={styles.radioButtonSingle}
          disabled={disabled}
          id={name}
          onClick={handleChange}
        >
          <Typography variant={Typography.Variant.BODY_SMALL_REGULAR}>{optionSelected.value}</Typography>
        </button>
      </Tooltip>
    </FormField>
  );
};

export const RadioButtonSingle = memo(RadioButtonSingleBase) as typeof RadioButtonSingleBase;
