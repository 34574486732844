import { ComputeEngineError } from '@amal-ia/lib-types';

export type MathJsError = Error & { data: any };

export class CalculationError extends ComputeEngineError {
  public constructor(e: MathJsError) {
    const message = e.data ? `In ${e.data.fn}, got ${e.data.actual}` : e.message;
    super(message);
  }
}
