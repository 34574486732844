import { http } from '@amal-ia/frontend/kernel/http';
import { isSplit, type PaymentContract, type PaymentSplitPart } from '@amal-ia/lib-types';

// Taking its master. If no master, this is the master
const getMasterPaymentId = (payment: PaymentContract) => payment.masterPaymentId || payment.id;

export async function getPaymentsDetails(payment: PaymentContract): Promise<PaymentContract[]> {
  if (!isSplit(payment)) {
    return [payment];
  }
  const { data } = await http.get(`/payments/split/${getMasterPaymentId(payment)}`);
  return data;
}

export async function removeSplit(payment: PaymentContract): Promise<void> {
  if (!isSplit(payment)) {
    return;
  }
  await http.delete(`/payments/split/${getMasterPaymentId(payment)}`);
}

export async function addSplit(payment: PaymentContract, splitParts: PaymentSplitPart[]): Promise<void> {
  if (isSplit(payment)) {
    return;
  }
  await http.post(`/payments/split/${getMasterPaymentId(payment)}`, { splitParts });
}
