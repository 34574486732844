import { defineMessages } from 'react-intl';

export const COMMON_MESSAGES = defineMessages({
  APPLY: { defaultMessage: 'Apply' },
  BACK: { defaultMessage: 'Back' },
  CANCEL: { defaultMessage: 'Cancel' },
  CLOSE: { defaultMessage: 'Close' },
  CONFIGURATION: { defaultMessage: 'Configuration' },
  DELETE: { defaultMessage: 'Delete' },
  GO_BACK: { defaultMessage: 'Go back' },
  LOGOUT: { defaultMessage: 'Logout' },
  LOADING: { defaultMessage: 'Loading' },
  LOAD_MORE: { defaultMessage: 'Load more' },
  LOAD_MORE_PROGRESS: { defaultMessage: 'Load more ({count, number}/{total, number})' },
  NAME: { defaultMessage: 'Name' },
  NEXT: { defaultMessage: 'Next' },
  SAVE: { defaultMessage: 'Save' },
  SEARCH: { defaultMessage: 'Search' },
  SELECT_ALL: { defaultMessage: 'Select all' },
  SETTINGS: { defaultMessage: 'Settings' },
  SUBMIT: { defaultMessage: 'Submit' },
  UPDATE: { defaultMessage: 'Update' },
  RESET: { defaultMessage: 'Reset' },
  TOTAL: { defaultMessage: 'Total' },
} as const);
