import { combineReducers } from 'redux';

import { type Flow, type ReduxAction } from '@amal-ia/lib-types';

import { FLOWS_ACTIONS } from './constants';

export default combineReducers({
  isLoading: (state: number = 0, action: ReduxAction) => {
    switch (action.type) {
      case FLOWS_ACTIONS.START:
        return state + 1;
      case FLOWS_ACTIONS.ERROR:
      case FLOWS_ACTIONS.SET_FLOWS:
      case FLOWS_ACTIONS.SET_FLOW:
        return state - 1;
      default:
        return state;
    }
  },
  flows: (state: Flow[] = [], action: ReduxAction) => {
    switch (action.type) {
      case FLOWS_ACTIONS.SET_FLOWS:
        return action.payload.flows;
      default:
        return state;
    }
  },
});
