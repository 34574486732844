import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { AlertBanner } from '@amal-ia/frontend/design-system/components';
import { DrawingStatementCalculationError } from '@amal-ia/lib-ui';

import { messages } from './StatementSummaryWidgetCalculationError.messages';
import * as styles from './StatementSummaryWidgetCalculationError.styles';

export const StatementSummaryWidgetCalculationError = memo(function StatementSummaryWidgetCalculationError() {
  return (
    <div css={styles.container}>
      <div css={styles.alert}>
        <AlertBanner variant={AlertBanner.Variant.ERROR}>
          <FormattedMessage {...messages.STATEMENT_CALCULATION_ERROR} />
        </AlertBanner>
      </div>

      <DrawingStatementCalculationError css={styles.illustration} />
    </div>
  );
});
