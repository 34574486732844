import { ArrowDropDownCircleRounded } from '@mui/icons-material';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';
import { memo } from 'react';

const iconStyles = {
  selectIcon: {
    right: 12,
  },
};

export const FilterBarSelectExpandIcon = memo(
  withStyles(iconStyles)(({ className, classes, ...rest }: any) => (
    <ArrowDropDownCircleRounded
      {...rest}
      className={clsx(className, classes.selectIcon)}
    />
  )),
);
