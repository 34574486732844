import { useShallowObjectMemo } from '@amal-ia/ext/react/hooks';

import { type AvatarProps } from '../Avatar';

export const useAvatarUser = ({ firstName, lastName, pictureURL }: Required<AvatarProps>['user']) =>
  useShallowObjectMemo({
    firstName,
    lastName,
    pictureURL,
  });
