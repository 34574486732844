import { http } from '@amal-ia/frontend/kernel/http';
import { type SaPlanSumup } from '@amal-ia/lib-types';

export async function saGetPlans(companiesId: string[]): Promise<SaPlanSumup[]> {
  const { data } = await http.get<SaPlanSumup[]>('/sa_plans', {
    params: {
      companiesId,
    },
  });
  return data;
}

type PlanTemplateFrontend = {
  queryContainers: any;
};

// The return type here is Partial<PlanTemplate> but we do not really need this type in the front.
export async function saGetPlanTemplate(
  planId: string,
  companyId: string,
  withQueryContainers: boolean,
): Promise<{ planTemplate: PlanTemplateFrontend; error: { message: string; context: string } }> {
  const { data } = await http.get<{ planTemplate: PlanTemplateFrontend; error: { message: string; context: string } }>(
    `/plans/${planId}/analyze`,
    {
      params: {
        companyId,
        'query-containers': withQueryContainers,
      },
    },
  );

  return data;
}
