import {
  type FormulaBuilderFunctionCategory,
  type AttributeValue,
  type FormulaBuilderFunctionBaseBlock,
  type ManualValue,
} from '@amal-ia/amalia-lang/formula/shared/types';

export type NumberValue = ManualValue<number>;

export type NumberValueOrAttribute = AttributeValue | NumberValue;

export enum FormulaBuilderNumberOperatorNoArgs {
  IS_BLANK = 'IS_BLANK',
  IS_NOT_BLANK = 'IS_NOT_BLANK',
}

export enum FormulaBuilderNumberOperatorOneArg {
  EQUALS = 'EQUALS',
  NOT_EQUALS = 'NOT_EQUALS',
  GREATER_THAN = 'GREATER_THAN',
  GREATER_THAN_OR_EQUAL = 'GREATER_THAN_OR_EQUAL',
  LESS_THAN = 'LESS_THAN',
  LESS_THAN_OR_EQUAL = 'LESS_THAN_OR_EQUAL',
}

export type FormulaBuilderFunctionBlockNumber =
  | FormulaBuilderFunctionBlockNumberNoArgs
  | FormulaBuilderFunctionBlockNumberOneArg;

export type FormulaBuilderFunctionBlockNumberBase = FormulaBuilderFunctionBaseBlock & {
  category: FormulaBuilderFunctionCategory.NUMBER;
};

export type FormulaBuilderFunctionBlockNumberNoArgs = FormulaBuilderFunctionBlockNumberBase & {
  operator: FormulaBuilderNumberOperatorNoArgs;
  args: [AttributeValue];
};

export type FormulaBuilderFunctionBlockNumberOneArg = FormulaBuilderFunctionBlockNumberBase & {
  operator: FormulaBuilderNumberOperatorOneArg;
  args: [AttributeValue, NumberValueOrAttribute];
};
