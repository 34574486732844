import AppBar from '@mui/material/AppBar';
import makeStyles from '@mui/styles/makeStyles';
import { memo } from 'react';

import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';

import { NavbarBrandColoured } from '../../../branding/NavbarBrandColoured';

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  navbar: {
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '10px 0',
  },
}));

export const NavbarLandingPage = memo(function NavbarLandingPage() {
  const classes = useStyles();
  return (
    <AppBar
      className={classes.navbar}
      position="static"
    >
      <NavbarBrandColoured />
    </AppBar>
  );
});
