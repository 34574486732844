import { Table } from '@devexpress/dx-react-grid-material-ui';
import { Menu, MenuItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { IconDots } from '@tabler/icons-react';
import clsx from 'clsx';
import { noop } from 'lodash';
import { memo, useState, useCallback, useContext, type MouseEventHandler } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';
import { IconButton } from '@amal-ia/frontend/design-system/components';

import { TableBuilderContext } from '../TableBuilderContext';

import { messages } from './TableBuilderActionCell.messages';

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  cell: {
    padding: `0 ${theme.spacing(2.5)}`,
    backgroundColor: theme.palette.common.white,
    // In readonly mode, action buttons disappear and the cell are really tight.
    height: 50,

    '&:not(:last-child)': {
      borderRight: `2px solid ${theme.palette.divider}`,
    },
  },
  actionCell: {
    '&:first-child': {
      // Style on the datagrid component is so precise it makes this
      // impossible to override.
      padding: `0 !important`,
    },
    '&:not(:last-child)': {
      borderRight: 0,
    },
  },
  actionButton: {
    color: theme.palette.link.main,
  },
}));

export const TableBuilderActionCell = memo(function TableBuilderActionCell(cellProps: Table.DataCellProps) {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const { tableRow } = cellProps;
  const { onAddRow, onDeleteRow, nbRows } = useContext(TableBuilderContext);

  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);

  const handleMenuOpen: MouseEventHandler<HTMLButtonElement> = useCallback((e) => {
    setMenuAnchor(e.currentTarget);
  }, []);

  const handleMenuClose = useCallback(() => {
    setMenuAnchor(null);
  }, []);

  const onAddRowAbove = useCallback(() => {
    // Here diff is actually 0 because the new row will take the place of this one.
    onAddRow(tableRow.rowId as number, 0);
    handleMenuClose();
  }, [onAddRow, tableRow, handleMenuClose]);

  const onAddRowBelow = useCallback(() => {
    onAddRow(tableRow.rowId as number, 1);
    handleMenuClose();
  }, [onAddRow, tableRow, handleMenuClose]);

  const onDeleteRowProxy = useCallback(() => {
    onDeleteRow(tableRow.rowId as number);
    handleMenuClose();
  }, [onDeleteRow, tableRow, handleMenuClose]);

  return (
    <Table.Cell
      name={`${tableRow.rowId}.values[actions]`}
      {...cellProps}
      className={clsx(classes.cell, classes.actionCell)}
      // Avoid the action cell to become editable by all means.
      onClick={noop}
      onFocus={noop}
    >
      <IconButton
        className={classes.actionButton}
        icon={<IconDots />}
        label={formatMessage(messages.ACTIONS_ON_ROW, { rowId: tableRow.rowId })}
        onClick={handleMenuOpen}
      />
      <Menu
        keepMounted
        anchorEl={menuAnchor}
        id="column_actions_menu"
        open={!!menuAnchor}
        onClose={handleMenuClose}
      >
        <MenuItem
          aria-label={formatMessage(messages.INSERT_ROW_ABOVE_ID, { rowId: tableRow.rowId })}
          onClick={onAddRowAbove}
        >
          <FormattedMessage {...messages.INSERT_ROW_ABOVE} />
        </MenuItem>
        <MenuItem
          aria-label={formatMessage(messages.INSERT_ROW_BELOW_ID, { rowId: tableRow.rowId })}
          onClick={onAddRowBelow}
        >
          <FormattedMessage {...messages.INSERT_ROW_BELOW} />
        </MenuItem>
        {nbRows > 1 && (
          <MenuItem
            aria-label={formatMessage(messages.DELETE_ROW_ID, { rowId: tableRow.rowId })}
            onClick={onDeleteRowProxy}
          >
            <FormattedMessage {...messages.DELETE_ROW} />
          </MenuItem>
        )}
      </Menu>
    </Table.Cell>
  );
});
