import { Link } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { forwardRef } from 'react';

import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';

import { TextType, Text } from '../../../typography';

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  link: {
    color: theme.palette.tertiary.main,
  },
}));

export const DatePickerLinkInput = forwardRef(function DatePickerLinkInput({ onClick, value }: any, ref: any) {
  const classes = useStyles();
  return (
    <Link
      ref={ref}
      className={classes.link}
      component="button"
      underline="hover"
      onClick={onClick}
    >
      <Text
        align="center"
        type={TextType.PERIOD_NAME}
      >
        {value}
      </Text>
    </Link>
  );
});
