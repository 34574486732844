import { type FormulaKeyword } from '../formulaKeywords';

// FIXME - move TokenType from lib-types to here and change all imports.
enum TokenType {
  VARIABLE = 'VARIABLE',
  FILTER = 'FILTER',
  RULE = 'RULE',
  LINK = 'LINK',
  FIELD = 'FIELD',
  QUOTA = 'QUOTA',
  FUNCTION = 'FUNCTION',
  KEYWORD = 'KEYWORD',
  PROPERTY = 'PROPERTY',
  VIRTUAL_PROPERTY = 'VIRTUAL_PROPERTY',
  PLAN = 'PLAN',
  OBJECT = 'OBJECT',
}

// FIXME - move VariableObjectsEnum from lib-types to here and change all imports.
enum VariableObjectsEnum {
  statement = 'statement',
  object = 'object',

  user = 'user',
  team = 'team',
  plan = 'plan',
}

export enum ValueOrAttributeType {
  VALUE = 'VALUE',
  ATTRIBUTE = 'ATTRIBUTE',
}

export type ValueOrAttribute = AttributeValue | ManualValue;

export type ManualValue<TValue extends number | string | null = string> = {
  type: ValueOrAttributeType.VALUE;
  value: TValue;
};

export type AttributeValue =
  | AttributeValueField
  | AttributeValueKeyword
  | AttributeValueProperty
  | AttributeValueQuota
  | AttributeValueRelationship
  | AttributeValueVariable;

export type AttributeValueRelationship = {
  type: ValueOrAttributeType.ATTRIBUTE;
  fieldType: TokenType.LINK;
  objectMachineName: string;
  relationshipMachineName: string;
  propertyMachineName: string;
};

export type AttributeValueProperty = {
  type: ValueOrAttributeType.ATTRIBUTE;
  fieldType: TokenType.PROPERTY | TokenType.VIRTUAL_PROPERTY;
  objectMachineName: string;
  propertyMachineName: string;
};

export type AttributeValueField = {
  type: ValueOrAttributeType.ATTRIBUTE;
  fieldType: TokenType.FIELD;
  objectMachineName: string;
  propertyMachineName: string;
};

export type AttributeValueVariable = {
  type: ValueOrAttributeType.ATTRIBUTE;
  fieldType: TokenType.VARIABLE;
  machineName: string;
};

export type AttributeValueQuota = {
  type: ValueOrAttributeType.ATTRIBUTE;
  fieldType: TokenType.QUOTA;
  quotaType: VariableObjectsEnum;
  machineName: string;
};

export type AttributeValueKeyword = {
  type: ValueOrAttributeType.ATTRIBUTE;
  fieldType: TokenType.KEYWORD;
  keyword: FormulaKeyword;
};
