import { type CheckboxProps, FormHelperText, type InputBaseComponentProps } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { uniqueId } from 'lodash';
import { memo, useMemo } from 'react';

import { TextType, Text } from '../../typography';
import { formikToMui } from '../formikToMui';
import { FormElementContainer, InputLabel } from '../inputs/Input/Input';

import { Checkbox } from './Checkbox';

const useStyles = makeStyles({
  inputLabel: {
    cursor: 'pointer',
  },
});

export type CheckboxFieldBaseProps = InputBaseComponentProps & Partial<Omit<CheckboxProps, 'value'>>;

export const CheckboxFieldBase = memo(function CheckboxFieldBase({
  label,
  error,
  onChange,
  value,
  name,
  ariaLabel,
  color,
  ...rest
}: CheckboxFieldBaseProps) {
  const classes = useStyles();
  const uniqueIdentifier = useMemo(() => rest?.id || uniqueId(), [rest]);

  return (
    <FormElementContainer align>
      <Checkbox
        aria-label={ariaLabel}
        checked={value}
        color={(color as 'primary' | 'secondary') || 'secondary'}
        name={name}
        onChange={onChange}
        {...rest}
        id={uniqueIdentifier}
      />
      <InputLabel
        compact
        className={classes.inputLabel}
        htmlFor={uniqueIdentifier}
      >
        <Text type={TextType.INPUT_NAME}>{label}</Text>
      </InputLabel>
      {!!error && <FormHelperText error>{error}</FormHelperText>}
    </FormElementContainer>
  );
});

export const CheckboxField = memo<CheckboxFieldBaseProps>(formikToMui(CheckboxFieldBase));

CheckboxField.displayName = 'CheckboxField';
