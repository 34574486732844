import { extractStatementWorkflowSteps, type Statement, type WorkflowStatementState } from '@amal-ia/lib-types';
import { useWorkflowsMap } from '@amal-ia/payout-collaboration/workflows/state';

export const useWorkflowSteps = (
  statement?: Statement,
): { steps: Partial<WorkflowStatementState>[]; currentStepNumber: number } => {
  const { data: workflowMap, isLoading } = useWorkflowsMap();

  if (isLoading || !statement) {
    return { steps: [], currentStepNumber: 0 };
  }

  return extractStatementWorkflowSteps(statement, workflowMap);
};
