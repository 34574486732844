import { groupBy } from 'lodash';
import { Fragment, memo, useState } from 'react';
import useAsyncEffect from 'use-async-effect';

import { useAuthenticatedContext } from '@amal-ia/frontend/kernel/authz/context';
import { log } from '@amal-ia/frontend/kernel/logger';
import { getChangelogEntries } from '@amal-ia/frontend/web-data-layers';
import { type ChangelogEntry, ChangelogImportance } from '@amal-ia/lib-types';

import ChangelogModal from './ChangelogModal';
import ChangelogPopup from './ChangelogPopup';

type ChangelogsByImportance = Record<ChangelogImportance, ChangelogEntry[]>;

export const ChangelogProvider = memo(function ChangelogProvider() {
  const [entries, setEntries] = useState({} as Partial<ChangelogsByImportance>);
  const { authenticatedContext } = useAuthenticatedContext();

  useAsyncEffect(async () => {
    // Don't load changelogs for users impersonating, because they'll have them each time, since
    // their last connection date doesn't change.
    if (authenticatedContext.meta?.isSuperAdmin) {
      return;
    }

    try {
      const { entries: newEntries } = await getChangelogEntries(
        true,
        undefined,
        authenticatedContext.login?.previousLastConnection,
      );
      setEntries(groupBy(newEntries, 'importance') as Partial<ChangelogsByImportance>);
    } catch (e) {
      log.error(e);
    }
  }, []);

  return (
    <Fragment>
      {entries[ChangelogImportance.MEDIUM]?.length ? (
        <ChangelogPopup changelogEntries={entries[ChangelogImportance.MEDIUM]} />
      ) : null}
      {entries[ChangelogImportance.HIGH]?.length ? (
        <ChangelogModal changelogEntries={entries[ChangelogImportance.HIGH]} />
      ) : null}
    </Fragment>
  );
});
