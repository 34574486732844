import { makeStyles } from '@mui/styles';
import { memo, type ReactElement, type ReactNode } from 'react';

import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';
import { Button } from '@amal-ia/frontend/design-system/components';

import { Text, TextType } from '../typography';

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  container: {
    backgroundColor: theme.palette.common.white,
    borderLeft: `6px solid ${theme.palette.tertiary.main}`,
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(4),
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  },
  textContainer: {
    marginRight: 'auto',
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.tertiary.contrastText,
    borderRadius: '4px',
    padding: theme.spacing(1),
  },
}));

type InformationBannerProps = Readonly<{
  onClickCTA: () => void;
  buttonTitle: ReactNode;
  title: ReactNode;
  description: ReactNode;
  icon?: ReactElement;
}>;

export const InformationBanner = memo(function InformationBanner({
  onClickCTA,
  buttonTitle,
  icon,
  title,
  description,
}: InformationBannerProps) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {icon ? (
        <div
          className={classes.iconContainer}
          data-testid="icon-container"
        >
          {icon}
        </div>
      ) : null}
      <div className={classes.textContainer}>
        <Text type={TextType.INFORMATION_BANNER_TITLE}>{title}</Text>
        <Text type={TextType.INFORMATION_BANNER_DESCRIPTION}>{description}</Text>
      </div>
      <Button
        variant={Button.Variant.SECONDARY}
        onClick={onClickCTA}
      >
        {buttonTitle}
      </Button>
    </div>
  );
});
