import { type UserContract } from '@amal-ia/tenants/users/shared/types';

import { type PlanAgreement } from './planAgreements';

export enum PlanAgreementAssignmentStatus {
  ARCHIVED = 'ARCHIVED',
  DRAFT = 'DRAFT',
  ERROR = 'ERROR',
  AGREEMENT_TO_GENERATE = 'AGREEMENT_TO_GENERATE',
  AGREEMENT_GENERATED = 'AGREEMENT_GENERATED',
  AGREEMENT_SENT = 'AGREEMENT_SENT',
  CONFIRMED = 'CONFIRMED',
  SIGNED_CONFIRMATION_UPLOADED = 'SIGNED_CONFIRMATION_UPLOADED',
}

export enum PlanAgreementAssignmentErrorType {
  GENERATION_ERROR = 'GENERATION_ERROR',
}

export interface PlanAgreementAssignmentError {
  type: PlanAgreementAssignmentErrorType;
  message: string;
}

export interface PlanAgreementAssignment {
  id: string;
  googleDocId?: string | null;
  agreementFilename?: string | null;
  agreementSignedFilename?: string | null;
  planAgreement: PlanAgreement;
  sentDate?: Date | null;
  seenDate?: Date | null;
  confirmationDate?: Date | null;
  signedConfirmationDate?: Date | null;
  status: PlanAgreementAssignmentStatus;
  user: UserContract;
  error?: PlanAgreementAssignmentError | null;
}
