import { memo } from 'react';

import { type FormikFieldProps, useFormikFieldAdapter } from '@amal-ia/ext/formik';

import { type SelectOptionGroup } from '../../../overlays/select-dropdown/SelectDropdown.types';
import { SimpleSelect, type SimpleSelectProps } from '../SimpleSelect';
import { type SimpleSelectOption } from '../SimpleSelect.types';

export type FormikSimpleSelectProps<
  TOption extends SimpleSelectOption = SimpleSelectOption,
  TUseOptionAsValue extends boolean | undefined = undefined,
  TGroup extends SelectOptionGroup<TOption> = SelectOptionGroup<TOption>,
> = FormikFieldProps<SimpleSelectProps<TOption, TUseOptionAsValue, TGroup>>;

export const FormikSimpleSelectBase = function FormikSimpleSelect<
  TOption extends SimpleSelectOption = SimpleSelectOption,
  TUseOptionAsValue extends boolean | undefined = undefined,
  TGroup extends SelectOptionGroup<TOption> = SelectOptionGroup<TOption>,
>({
  validate, // Omit validate and any props not passed to Select.
  ...props
}: FormikSimpleSelectProps<TOption, TUseOptionAsValue, TGroup>) {
  const formikFieldProps = useFormikFieldAdapter<SimpleSelectProps<TOption, TUseOptionAsValue, TGroup>['value']>({
    validate,
    ...props,
  });

  return (
    <SimpleSelect<TOption, TUseOptionAsValue, TGroup>
      {...props}
      {...formikFieldProps}
    />
  );
};

export const FormikSimpleSelect = memo(FormikSimpleSelectBase) as typeof FormikSimpleSelectBase;
