import { Table } from '@devexpress/dx-react-grid-material-ui';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { FormatsEnum } from '@amal-ia/data-capture/fields/types';
import { formatPeriodName, formatTotal, isSplit, type Payment } from '@amal-ia/lib-types';
import { UserTableCell, useOpenInNewTab } from '@amal-ia/lib-ui';

const PaymentTableCell = memo(function PaymentTableCell(cellProps: Table.DataCellProps) {
  const { column, tableRow } = cellProps;
  const openInNewTab = useOpenInNewTab();
  const payment = tableRow.row as Payment;
  if (column.name === 'split') {
    return (
      <Table.Cell
        name={`${tableRow.rowId}.values[${column.name}]`}
        {...cellProps}
      >
        {isSplit(tableRow.row) ? (
          <FormattedMessage defaultMessage="Split" />
        ) : (
          <FormattedMessage defaultMessage="Not split" />
        )}
      </Table.Cell>
    );
  }
  if (column.name === 'salesrep') {
    return (
      <UserTableCell
        contextProps={{ ...cellProps }}
        user={tableRow.row.user}
        onClick={() => openInNewTab(`/statements/${tableRow.row.statementId}`)}
      />
    );
  }
  if (column.name === 'paymentPeriodId') {
    return (
      <Table.Cell
        name={`${tableRow.rowId}.values[${column.name}]`}
        {...cellProps}
      >
        {payment.paymentPeriod
          ? formatPeriodName(payment.paymentPeriod.frequency, payment.paymentPeriod.startDate)
          : null}
      </Table.Cell>
    );
  }

  if (column.name === 'achievementPeriod') {
    return (
      <Table.Cell
        name={`${tableRow.rowId}.values[${column.name}]`}
        {...cellProps}
      >
        {payment.period ? formatPeriodName(payment.period.frequency, payment.period.startDate) : null}
      </Table.Cell>
    );
  }

  if (column.name === 'amount') {
    return (
      <Table.Cell
        name={`${tableRow.rowId}.values[${column.name}]`}
        {...cellProps}
      >
        {payment.currency && payment.value !== undefined
          ? formatTotal(payment.value, FormatsEnum.currency, payment.currency)
          : null}
      </Table.Cell>
    );
  }

  return (
    <Table.Cell
      name={`${tableRow.rowId}.values[${column.name}]`}
      {...cellProps}
      style={{ whiteSpace: 'normal' }}
    />
  );
});

export default PaymentTableCell;
