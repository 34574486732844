import styled from '@emotion/styled';

import { ModalSize } from '../Modal.types';

export const ModalHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  transition: ${({ theme }) => theme.ds.transitions.default('padding')};

  .${ModalSize.SMALL} & {
    padding: 0 16px 0 24px;
  }

  .${ModalSize.MEDIUM} & {
    padding: 0 24px 0 32px;
  }

  .${ModalSize.LARGE} & {
    padding: 0 32px 0 40px;
  }
`;
