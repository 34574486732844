import { toError } from '@amal-ia/ext/typescript';

function promiseIsFulfilled<T>(p: PromiseSettledResult<T>): p is PromiseFulfilledResult<T> {
  return p.status === 'fulfilled';
}

function promisesAreFulfilled<T>(promises: PromiseSettledResult<T>[]): promises is PromiseFulfilledResult<T>[] {
  return promises.every(promiseIsFulfilled);
}

/**
 * Throw if one of the promises contains in Promise.allSettled failed.
 *
 * You can cast the results in PromiseFulfilledResults after that function, because it throws
 * if one of them fails.
 *
 * @param promises
 * @param companyName
 */
export const promiseAllSettledAutoThrow = (promises: PromiseSettledResult<unknown>[], companyName?: string) => {
  const rejectedPromises = promises.filter((p) => p.status === 'rejected');

  if (rejectedPromises.length > 0) {
    rejectedPromises.forEach((p: PromiseRejectedResult) => {
      // eslint-disable-next-line no-console -- TODO(#2481): rework log in common
      console.error(toError(p.reason).message, companyName || '', toError(p.reason).stack);
    });
    throw new Error(`Promise All Settled failed with ${rejectedPromises.length} error(s)`);
  }
};

/**
 * Simple extension of promiseAllSettledAutoThrow that returns the fulfilled promises with the correct type.
 * It throws if one of the promises contains in Promise.allSettled failed. It avoid the cast of the results.
 */
export const promiseAllSettledAutoThrowOrReturnFulfilled = <T extends PromiseSettledResult<unknown>[]>(
  promises: T,
  companyName?: string,
): // @ts-expect-error -- Complains about the code may return nothing sometimes but it's not the case, it's a type guard
T extends PromiseSettledResult<unknown>[]
  ? {
      [K in keyof T]: T[K] extends PromiseSettledResult<infer V> ? PromiseFulfilledResult<V>['value'] : never;
    }
  : // eslint-disable-next-line consistent-return -- same
    never => {
  if (promisesAreFulfilled(promises)) {
    const valuesUnderPromises = [] as T extends PromiseSettledResult<unknown>[]
      ? {
          [K in keyof T]: T[K] extends PromiseSettledResult<infer V> ? PromiseFulfilledResult<V>['value'] : never;
        }
      : never;

    for (const p of promises) valuesUnderPromises.push(p.value);
    return valuesUnderPromises;
  }

  promiseAllSettledAutoThrow(promises, companyName);
};
