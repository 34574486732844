import { type Theme, css } from '@emotion/react';

export const container = css`
  display: flex;
  align-items: center;
  gap: 24px;
`;

export const nameAndTeamContainer = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const avatar = (theme: Theme) => css`
  height: 48px;
  width: 48px;
  border-radius: ${theme.ds.borderRadiuses.round};
  border: 2.4px solid ${theme.ds.colors.primary[500]};
`;
