import { css } from '@emotion/react';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { type MergeAll } from '@amal-ia/ext/typescript';

import { Typography, type TypographyProps } from '../../../general/typography/Typography';

export type DropdownNoOptionsProps = MergeAll<
  [
    Omit<TypographyProps, 'as' | 'children' | 'variant'>,
    {
      searchInputValue?: string;
    },
  ]
>;

export const DropdownNoOptions = memo(function DropdownNoOptions({
  searchInputValue = '',
  ...props
}: DropdownNoOptionsProps) {
  return (
    <Typography
      {...props}
      as="div"
      variant={Typography.Variant.BODY_SMALL_BOLD}
      css={(theme) => css`
        color: ${theme.ds.colors.gray[500]};
        word-break: break-word;
      `}
    >
      {searchInputValue ? (
        <FormattedMessage
          defaultMessage={`No options matching "{value}"`}
          values={{ value: searchInputValue }}
        />
      ) : (
        <FormattedMessage defaultMessage="No options to select" />
      )}
    </Typography>
  );
});
