import { http } from '@amal-ia/frontend/kernel/http';
import { type Adjustment } from '@amal-ia/lib-types';

export const createStatementAdjustment = async (statementId: string, adjustment: Adjustment) => {
  const { data } = await http.post('/statements_adjustments', {
    statementId,
    ...adjustment,
  });

  return data;
};

export const editStatementAdjustment = async (adjustment: Adjustment) => {
  const { data } = await http.patch(`/statements_adjustments/${adjustment.id}`, adjustment);
  return data;
};

export const deleteStatementAdjustment = async (adjustmentId: string) => {
  const { data } = await http.delete(`statements_adjustments/${adjustmentId}`);
  return data;
};
