import { TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import { Box, Menu, MenuItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { IconDots } from '@tabler/icons-react';
import clsx from 'clsx';
import { pick } from 'lodash';
import { memo, useMemo, useContext, useState, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { FormatsEnum, FORMATS_SYMBOLS_AND_LABELS } from '@amal-ia/data-capture/fields/types';
import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';
import { IconButton } from '@amal-ia/frontend/design-system/components';

import { MiniSelectField } from '../../SelectField/MiniSelectField';
import { TableBuilderContext } from '../TableBuilderContext';

import { messages } from './TableBuilderCellHeader.messages';

const TABLE_BUILDER_SUPPORTED_SYMBOLS_AND_LABELS = {
  ...pick(FORMATS_SYMBOLS_AND_LABELS, [
    FormatsEnum.percent,
    FormatsEnum.currency,
    FormatsEnum.number,
    FormatsEnum.date,
    FormatsEnum.text,
    FormatsEnum.table,
    FormatsEnum.boolean,
  ]),
  formula: { symbol: '()', label: 'formula' },
};

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  cell: {
    padding: `0 ${theme.spacing(2.5)}`,
    backgroundColor: theme.palette.common.white,
    // In readonly mode, action buttons disappear and the cell are really tight.
    height: 50,

    '&:not(:last-child)': {
      borderRight: `2px solid ${theme.palette.divider}`,
    },
  },
  cellHeader: {
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.secondary.light,
  },
  actionCell: {
    '&:first-child': {
      padding: 0,
    },
    '&:not(:last-child)': {
      borderRight: 0,
    },
  },
  actionButton: {
    color: theme.palette.link.main,
  },
}));

export const TableBuilderCellHeader = memo(function TableBuilderCellHeader(props: TableHeaderRow.CellProps) {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const FORMAT_OPTIONS = useMemo(
    () =>
      Object.entries(TABLE_BUILDER_SUPPORTED_SYMBOLS_AND_LABELS).map(([value, { symbol, label }]) => ({
        value,
        summary: symbol,
        label,
      })),
    [],
  );

  const { column } = props;
  const { onAddColumn, onDeleteColumn, onChangeColumnFormat, onRenameColumn, nbColumns, isReadonly } =
    useContext(TableBuilderContext);

  const columnName = column?.name || 'defaultName';

  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);

  const handleMenuOpen = useCallback((e) => {
    setMenuAnchor(e.currentTarget);
  }, []);

  const handleMenuClose = useCallback(() => {
    setMenuAnchor(null);
  }, []);

  const onAddColumnLeft = useCallback(() => {
    // Here diff is actually 0 because the new column will take the place of this one.
    onAddColumn(column.name, 0);
    handleMenuClose();
  }, [onAddColumn, column, handleMenuClose]);

  const onAddColumnRight = useCallback(() => {
    onAddColumn(column.name, 1);
    handleMenuClose();
  }, [onAddColumn, column, handleMenuClose]);

  const onDeleteColumnProxy = useCallback(() => {
    onDeleteColumn(column.name);
    handleMenuClose();
  }, [onDeleteColumn, column, handleMenuClose]);

  const onChangeFormat = useCallback(
    (e) => {
      onChangeColumnFormat(column.name, e.target.value);
      handleMenuClose();
    },
    [onChangeColumnFormat, column, handleMenuClose],
  );

  const onRenameColumnProxy = useCallback(() => {
    onRenameColumn(column.name);
    handleMenuClose();
  }, [onRenameColumn, column, handleMenuClose]);

  if (props.column.name === 'actions') {
    return (
      <TableHeaderRow.Cell
        {...props}
        className={clsx(classes.cell, classes.cellHeader, classes.actionCell)}
      />
    );
  }

  // @ts-expect-error -- Too bad DX Grid doesn't let us add values here, but we'll do it anyway.
  const format = column.format as FormatsEnum;

  return (
    <TableHeaderRow.Cell
      {...props}
      className={clsx(classes.cell, classes.cellHeader)}
    >
      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        width="100%"
      >
        {!isReadonly && (
          <MiniSelectField
            data-testid={`column_format_${columnName}`}
            id={`column_format_${columnName}`}
            name={`column_format_${columnName}`}
            options={FORMAT_OPTIONS}
            value={format}
            onChange={onChangeFormat}
          />
        )}
        <span>{column.title}</span>
        {!isReadonly && (
          <IconButton
            className={classes.actionButton}
            icon={<IconDots />}
            label={formatMessage(messages.ACTIONS_ON_COLUMN, { columnName })}
            onClick={handleMenuOpen}
          />
        )}
        <Menu
          keepMounted
          anchorEl={menuAnchor}
          id={`column_actions_${columnName}`}
          open={!!menuAnchor}
          onClose={handleMenuClose}
        >
          <MenuItem
            aria-label={formatMessage(messages.INSERT_COLUMN_RIGHT_NAME, { columnName })}
            onClick={onAddColumnRight}
          >
            <FormattedMessage {...messages.INSERT_COLUMN_RIGHT} />
          </MenuItem>
          <MenuItem
            aria-label={formatMessage(messages.INSERT_COLUMN_LEFT_NAME, { columnName })}
            onClick={onAddColumnLeft}
          >
            <FormattedMessage {...messages.INSERT_COLUMN_LEFT} />
          </MenuItem>
          <MenuItem
            aria-label={formatMessage(messages.RENAME_COLUMN_NAME, { columnName })}
            onClick={onRenameColumnProxy}
          >
            <FormattedMessage {...messages.RENAME_COLUMN} />
          </MenuItem>
          {nbColumns > 2 && (
            <MenuItem
              aria-label={formatMessage(messages.DELETE_COLUMN_NAME, { columnName })}
              onClick={onDeleteColumnProxy}
            >
              <FormattedMessage {...messages.DELETE_COLUMN} />
            </MenuItem>
          )}
        </Menu>
      </Box>
    </TableHeaderRow.Cell>
  );
});
