import { type Property, FormatsEnum } from '@amal-ia/data-capture/fields/types';
import { type CustomObjectDefinition } from '@amal-ia/data-capture/models/types';
import { type UserContract } from '@amal-ia/tenants/users/shared/types';

import { ConditionsEnum, type FiltersType } from './dataFilter';
import { type LinkAction, type PushDateMessageAction } from './flows/actions';

/**
 * Copy/pasted from @tiptap/core
 * It meant the whole @tiptap library became a dependency of our server side apps.
 *
 * TODO: Put this in a separate package and import it from there.
 * This way, only flow apps will have the @tiptap/core dependency.
 */
export type JSONContent = {
  type?: string;
  attrs?: Record<string, any>;
  content?: JSONContent[];
  marks?: {
    type: string;
    attrs?: Record<string, any>;
    [key: string]: any;
  }[];
  text?: string;
  [key: string]: any;
};

export interface MarkDownBlock {
  type: 'section';
  text?: {
    type: 'mrkdwn';
    text: string;
  };
  fields?: {
    type: 'mrkdwn';
    text: string;
  }[];
}

export interface ActionElementBlock {
  type: 'button';
  text: {
    type: 'plain_text';
    text: string;
    emoji: boolean;
  };
  value: string;
  url?: string;

  metadata?: Record<string, string>;
}
export interface ActionBlock {
  type: 'actions';
  elements: ActionElementBlock[];
}

export interface DividerBlock {
  type: 'divider';
}

export type FlowTemplateBlock = ActionBlock | DividerBlock | MarkDownBlock;

// ENTITIES
export interface Flow {
  id?: string;
  companyId?: string;
  creator?: UserContract;
  creatorId?: string;
  createdAt?: Date;
  name?: string;
  isActive?: boolean;
  object?: CustomObjectDefinition;
  objectId?: string;
  frequency?: FlowFrequency;
  receivers?: string[]; // ID CHannel for now
  displayTitle?: JSONContent;
  displayFields?: Property[];
  actions?: (LinkAction | PushDateMessageAction)[];
  template?: PresetsFlowTemplate;
  filters?: FiltersType[];
}

export type FlowResponse = Pick<
  Flow,
  | 'actions'
  | 'creatorId'
  | 'displayFields'
  | 'displayTitle'
  | 'filters'
  | 'frequency'
  | 'id'
  | 'isActive'
  | 'name'
  | 'objectId'
  | 'receivers'
  | 'template'
>;

export enum FlowFrequency {
  INSTANT = 'INSTANT',
  SCHEDULED = 'SCHEDULED',
  WORKING_HOURS = 'WORKING_HOURS',
}

export enum PresetsFlowTemplate {
  BLANK = 'BLANK',
  CLOSE_DATE_OVERDUE = 'CLOSE_DATE_OVERDUE',
  NEXT_STEP_EMPTY = 'NEXT_STEP_EMPTY',
}

export const FlowFilterOperatorsForFormat: Record<FormatsEnum, ConditionsEnum[]> = {
  [FormatsEnum.boolean]: [ConditionsEnum.booleanIsTrue, ConditionsEnum.booleanIsFalse],
  [FormatsEnum.date]: [
    ConditionsEnum.dateTimeAfter,
    ConditionsEnum.dateTimeAfterOrEqual,
    ConditionsEnum.dateTimeBefore,
    ConditionsEnum.dateTimeBeforeOrEqual,
    ConditionsEnum.dateTimeEquals,
    ConditionsEnum.exists,
    ConditionsEnum.doesNotExist,
  ],
  [FormatsEnum['date-time']]: [
    ConditionsEnum.dateTimeAfter,
    ConditionsEnum.dateTimeAfterOrEqual,
    ConditionsEnum.dateTimeBefore,
    ConditionsEnum.dateTimeBeforeOrEqual,
    ConditionsEnum.dateTimeEquals,
    ConditionsEnum.exists,
    ConditionsEnum.doesNotExist,
  ],
  [FormatsEnum.currency]: [
    ConditionsEnum.numberGreaterThan,
    ConditionsEnum.numberGreaterThanOrEqual,
    ConditionsEnum.numberLessThan,
    ConditionsEnum.numberLessThanOrEqual,
    ConditionsEnum.numberEquals,
    ConditionsEnum.exists,
    ConditionsEnum.doesNotExist,
  ],
  [FormatsEnum.number]: [
    ConditionsEnum.numberGreaterThan,
    ConditionsEnum.numberGreaterThanOrEqual,
    ConditionsEnum.numberLessThan,
    ConditionsEnum.numberLessThanOrEqual,
    ConditionsEnum.numberEquals,
    ConditionsEnum.exists,
    ConditionsEnum.doesNotExist,
  ],
  [FormatsEnum.percent]: [
    ConditionsEnum.numberGreaterThan,
    ConditionsEnum.numberGreaterThanOrEqual,
    ConditionsEnum.numberLessThan,
    ConditionsEnum.numberLessThanOrEqual,
    ConditionsEnum.numberEquals,
    ConditionsEnum.exists,
    ConditionsEnum.doesNotExist,
  ],
  [FormatsEnum.text]: [
    ConditionsEnum.textExactlyMatches,
    ConditionsEnum.textDoesNotExactlyMatch,
    ConditionsEnum.textContains,
    ConditionsEnum.textDoesNotContain,
    ConditionsEnum.exists,
    ConditionsEnum.doesNotExist,
    ConditionsEnum.textStartsWith,
    ConditionsEnum.textDoesNotStartWith,
    ConditionsEnum.textEndsWith,
    ConditionsEnum.textDoesNotEndWith,
    ConditionsEnum.textIsIn,
    ConditionsEnum.textIsNotIn,
    // FlowFilterOperator.KEYWORD, // Under certain conditions.
  ],
  [FormatsEnum.table]: [],
};

type FlowFilterOperatorDetailValue = {
  label: string;
  symbol?: string;
  isOrDoes?: 'DOES' | 'IS';
};
export const FlowFilterOperatorDetails: Record<ConditionsEnum, FlowFilterOperatorDetailValue> = {
  [ConditionsEnum.textContains]: { label: 'Contains', symbol: 'contains' },
  [ConditionsEnum.textDoesNotContain]: { label: 'Not Contains', symbol: 'not contains' },
  [ConditionsEnum.textExactlyMatches]: { label: 'Exactly Match', symbol: '=' },
  [ConditionsEnum.textDoesNotExactlyMatch]: { label: 'Not Exactly Match', symbol: '!=' },

  [ConditionsEnum.textIsIn]: { label: 'In the values', symbol: 'in' },
  [ConditionsEnum.textIsNotIn]: { label: 'Not In the values', symbol: 'not in' },

  [ConditionsEnum.textStartsWith]: { label: 'Start With', symbol: 'starts with' },
  [ConditionsEnum.textDoesNotStartWith]: { label: 'Not Start With', symbol: 'not starts with' },

  [ConditionsEnum.textEndsWith]: { label: 'End With', symbol: 'ends with' },
  [ConditionsEnum.textDoesNotEndWith]: { label: 'Not End With', symbol: 'not ends with' },

  [ConditionsEnum.numberGreaterThan]: { label: 'Greater Than', symbol: '>' },
  [ConditionsEnum.numberGreaterThanOrEqual]: { label: 'Greater Than Or Equal', symbol: '>=' },
  [ConditionsEnum.numberLessThan]: { label: 'Lower Than', symbol: '<' },
  [ConditionsEnum.numberLessThanOrEqual]: { label: 'Lower Than Or Equal', symbol: '<' },
  [ConditionsEnum.numberEquals]: { label: 'Equals to', symbol: '=' },

  [ConditionsEnum.dateTimeAfter]: { label: 'After', symbol: '>' },
  [ConditionsEnum.dateTimeAfterOrEqual]: { label: 'After Or Equal', symbol: '>=' },
  [ConditionsEnum.dateTimeBefore]: { label: 'Before', symbol: '<' },
  [ConditionsEnum.dateTimeBeforeOrEqual]: { label: 'Before Or Equal', symbol: '<=' },
  [ConditionsEnum.dateTimeEquals]: { label: 'Equal to', symbol: '=' },

  [ConditionsEnum.booleanIsTrue]: { label: 'True', symbol: 'true' },
  [ConditionsEnum.booleanIsFalse]: { label: 'False', symbol: 'false' },

  [ConditionsEnum.exists]: { label: 'Exists', symbol: 'Does Exists' },
  [ConditionsEnum.doesNotExist]: { label: 'Not Exists', symbol: 'Does Not Exists' },
};

export enum FlowActions {
  TRIGGER = 'TRIGGER',
}

export type FlowMessageActions = LinkAction | PushDateMessageAction;
