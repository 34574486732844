import { IconCopy } from '@tabler/icons-react';
import { memo, type MouseEventHandler, useCallback } from 'react';

import { type MergeAll } from '@amal-ia/ext/typescript';
import { IconButton, type IconButtonProps } from '@amal-ia/frontend/design-system/components';

import { useClipboard } from './useClipboard';

export type CopyToClipboardProps = MergeAll<
  [IconButtonProps, { value?: string | null }, Pick<Partial<IconButtonProps>, 'icon' | 'onClick' | 'size'>]
>;

export const CopyToClipboard = memo(function CopyToClipboardButton({
  value,
  icon = <IconCopy />,
  size = IconButton.Size.MEDIUM,
  onClick,
  ...props
}: CopyToClipboardProps) {
  const { copy } = useClipboard();

  const onClickCopy: MouseEventHandler<HTMLButtonElement> = useCallback(
    (event) => {
      copy(value ?? '');
      onClick?.(event);
    },
    [copy, value, onClick],
  );

  return (
    <IconButton
      icon={icon}
      size={size}
      onClick={onClickCopy}
      {...props}
    />
  );
});
