import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath } from 'react-router-dom';

import { routes } from '@amal-ia/common/routes';
import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';
import { ButtonLink } from '@amal-ia/frontend/design-system/components';
import { DrawingStatementNotFound, Text, TextType } from '@amal-ia/lib-ui';

import { messages } from './NotFoundStatementError.messages';

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  container: {
    '& > div:not(:first-child)': {
      marginTop: theme.spacing(2),
    },
  },
  drawing: {
    width: theme.spacing(20),
    height: 'auto',
  },
  title: {
    fontSize: 20,
    fontWeight: 600,
    color: theme.palette.grey[700],
  },
}));

export const NotFoundStatementError = memo(function NotFoundStatementError() {
  const classes = useStyles();

  return (
    <Box
      alignItems="center"
      className={classes.container}
      display="flex"
      flexDirection="column"
      paddingY={4}
    >
      <Box
        display="flex"
        flex={1}
        flexDirection="column"
        textAlign="center"
      >
        <DrawingStatementNotFound className={classes.drawing} />
        <Text
          className={classes.title}
          type={TextType.STATEMENT_NAME}
        >
          <FormattedMessage {...messages.TITLE} />
        </Text>
      </Box>

      <Box textAlign="center">
        <Text type={TextType.PAGE_SUBTITLE}>
          <FormattedMessage {...messages.SUBTITLE} />
          <br />
          <FormattedMessage {...messages.REASON} />
        </Text>
      </Box>

      <Box textAlign="center">
        <ButtonLink
          to={generatePath(routes.STATEMENTS)}
          variant={ButtonLink.Variant.SECONDARY}
        >
          <FormattedMessage {...messages.HOMEPAGE_CTA} />
        </ButtonLink>
      </Box>
    </Box>
  );
});

export default NotFoundStatementError;
