import { Chip } from '@mui/material';
import { withStyles } from '@mui/styles';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

const InfoChip = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.info.light,
    color: theme.palette.info.dark,
  },
}))(Chip);

export const BetaChip = memo(function BetaChip() {
  return <InfoChip label={<FormattedMessage defaultMessage="beta" />} />;
});
