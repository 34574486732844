import { type Properties } from 'csstype';

export enum TypographyVariant {
  HEADING_1_REGULAR = 'heading1Regular',
  HEADING_1_MEDIUM = 'heading1Medium',
  HEADING_1_BOLD = 'heading1Bold',
  HEADING_2_REGULAR = 'heading2Regular',
  HEADING_2_MEDIUM = 'heading2Medium',
  HEADING_2_BOLD = 'heading2Bold',
  HEADING_3_REGULAR = 'heading3Regular',
  HEADING_3_MEDIUM = 'heading3Medium',
  HEADING_3_BOLD = 'heading3Bold',
  HEADING_4_REGULAR = 'heading4Regular',
  HEADING_4_MEDIUM = 'heading4Medium',
  HEADING_4_BOLD = 'heading4Bold',
  BODY_LARGE_REGULAR = 'bodyLargeRegular',
  BODY_LARGE_MEDIUM = 'bodyLargeMedium',
  BODY_LARGE_BOLD = 'bodyLargeBold',
  BODY_BASE_REGULAR = 'bodyBaseRegular',
  BODY_BASE_MEDIUM = 'bodyBaseMedium',
  BODY_BASE_BOLD = 'bodyBaseBold',
  BODY_SMALL_REGULAR = 'bodySmallRegular',
  BODY_SMALL_MEDIUM = 'bodySmallMedium',
  BODY_SMALL_BOLD = 'bodySmallBold',
  BODY_XSMALL_REGULAR = 'bodyXsmallRegular',
  BODY_XSMALL_MEDIUM = 'bodyXsmallMedium',
  BODY_XSMALL_BOLD = 'bodyXsmallBold',
  MONOSPACE_LARGE = 'monospaceLarge',
  MONOSPACE_BASE = 'monospaceBase',
  MONOSPACE_SMALL = 'monospaceSmall',
  BUTTON_LARGE = 'buttonLarge',
  BUTTON_BASE = 'buttonBase',
  BUTTON_SMALL = 'buttonSmall',
}

export type TypographyDefinition = {
  fontSize: Properties['fontSize'];
  lineHeight: Properties['lineHeight'];
  fontWeight: Properties['fontWeight'];
  fontFamily: Properties['fontFamily'];
  fontStyle: Properties['fontStyle'];
  letterSpacing: Properties['letterSpacing'];
  textTransform?: Properties['textTransform'];
};

export type ThemeTypographies = Record<TypographyVariant, TypographyDefinition>;
