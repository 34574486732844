import { combineReducers } from 'redux';

import { type ReduxAction } from '@amal-ia/lib-types';

import { type PlanAssignmentsByPlan, type PlanAssignmentWithUser } from '../../types/planAssignments';

import { PLAN_ASSIGNMENTS_ACTIONS } from './constants';

export default combineReducers({
  isLoading: (state: number = 0, action: ReduxAction) => {
    switch (action.type) {
      case PLAN_ASSIGNMENTS_ACTIONS.START:
        return state + 1;
      case PLAN_ASSIGNMENTS_ACTIONS.SET_PLAN_ASSIGNMENTS:
      case PLAN_ASSIGNMENTS_ACTIONS.DELETE_PLAN_ASSIGNMENT:
      case PLAN_ASSIGNMENTS_ACTIONS.ERROR:
        return state - 1;
      default:
        return state;
    }
  },
  map: (state: PlanAssignmentsByPlan = {}, action: ReduxAction) => {
    switch (action.type) {
      case PLAN_ASSIGNMENTS_ACTIONS.SET_PLAN_ASSIGNMENTS:
        return {
          ...state,
          ...action.payload.planAssignments.reduce(
            (acc: PlanAssignmentsByPlan, p: PlanAssignmentWithUser) => ({
              ...acc,
              [p.planId]: [...(acc[p.planId] || []), p],
            }),
            {},
          ),
        };
      case PLAN_ASSIGNMENTS_ACTIONS.DELETE_PLAN_ASSIGNMENT:
        return {
          ...state,
          [action.payload.planAssignment.planId]: state[action.payload.planAssignment.planId].filter(
            (pa) => pa.id !== action.payload.planAssignment.id,
          ),
        };
      default:
        return state;
    }
  },
});
