import { type ActionCreator } from 'redux';

import { type TodoDefinition, type ReduxAction, type ThunkResult } from '@amal-ia/lib-types';

import * as TodosRepository from '../../services/todos/todos.repository';

import { TODOS_ACTIONS } from './constants';

const todosStart: ActionCreator<ReduxAction> = () => ({
  type: TODOS_ACTIONS.START,
});

const todosError: ActionCreator<ReduxAction> = (error: Error) => ({
  type: TODOS_ACTIONS.ERROR,
  error,
});

const setCount: ActionCreator<ReduxAction> = (todosCount: number) => ({
  type: TODOS_ACTIONS.SET_TODOS_COUNT,
  payload: { todosCount },
});

const setTodos: ActionCreator<ReduxAction> = (todos: TodoDefinition[]) => ({
  type: TODOS_ACTIONS.SET_TODOS,
  payload: { todos },
});

export const refreshTodosCount = (): ThunkResult<Promise<ReduxAction>> => async (dispatch) => {
  dispatch(todosStart());
  try {
    const todosCount = await TodosRepository.fetchTodosCount();
    return dispatch(setCount(todosCount));
  } catch (error) {
    return dispatch(todosError(error));
  }
};

export const fetchTodos =
  (
    filters: any,
    searchText?: string,
    page = 0,
    limit = 10,
    sortColumn?: string,
    sortOrder = 'asc',
  ): ThunkResult<Promise<ReduxAction>> =>
  async (dispatch) => {
    dispatch(todosStart());
    try {
      const todos = await TodosRepository.fetchTodos(filters, searchText, page, limit, sortColumn, sortOrder);
      return dispatch(setTodos(todos));
    } catch (e) {
      return dispatch(todosError(e));
    }
  };
