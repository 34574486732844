import { IconReload } from '@tabler/icons-react';
import moment from 'moment';
import { Fragment, memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { IconButton, IconLoading, Typography } from '@amal-ia/frontend/design-system/components';
import { ActionsEnum, SubjectsEnum } from '@amal-ia/lib-rbac';
import { fromNow } from '@amal-ia/lib-types';

import { computeMessages } from '../../../common/compute-header-action/compute.messages';
import * as computeStyles from '../../../common/compute-header-action/compute.styles';

import * as styles from './StatementDetailHeaderActions.styles';
import {
  type StatementDetailHeaderActionsProps,
  useStatementDetailHeaderActions,
} from './useStatementDetailHeaderActions';

export const StatementDetailHeaderFirstRow = memo(function StatementDetailHeaderFirstRow(
  props: StatementDetailHeaderActionsProps,
) {
  const { statement, ability, isWorkflowBegun, formatMessage } = useStatementDetailHeaderActions(props);
  return (
    <div css={styles.actionContainer}>
      <Typography variant={Typography.Variant.BODY_SMALL_REGULAR}>
        <FormattedMessage
          {...computeMessages.LAST_COMPUTED}
          values={{ time: fromNow(statement.lastCalculationDate) }}
        />
      </Typography>
      {ability.can(ActionsEnum.calculate, SubjectsEnum.Statement) &&
        (!statement.isCalculationRunning ? (
          <IconButton
            withBackground
            disabled={isWorkflowBegun ? !statement.isForecastedView : null}
            icon={<IconReload />}
            id="newCalculation"
            label={
              <div css={computeStyles.computeButtonTooltip}>
                <div>
                  <FormattedMessage {...computeMessages.COMPUTE} />
                </div>
                <div>
                  <FormattedMessage
                    {...computeMessages.CALCULATION_TOOLTIP}
                    values={{
                      day: moment(statement.lastCalculationDate).format('YYYY-MM-DD'),
                      hour: moment(statement.lastCalculationDate).format('HH:mm A'),
                    }}
                  />
                </div>
              </div>
            }
            onClick={statement.launchCalculation}
          />
        ) : (
          <Fragment>
            <p css={computeStyles.calculationProgress}>{statement.calculationProgress}</p>
            <IconButton
              isActive
              withBackground
              disabled={isWorkflowBegun ? !statement.isForecastedView : null}
              icon={<IconLoading icon={IconReload} />}
              id="stopCalculation"
              label={formatMessage(computeMessages.STOP)}
              onClick={statement.stopCalculation}
            />
          </Fragment>
        ))}
    </div>
  );
});
