import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  ADJUSTMENTS: {
    defaultMessage: 'Adjustments',
  },
  VIEW_STATEMENT: {
    defaultMessage: 'View statement',
  },
} as const);
