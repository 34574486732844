export enum FormatsEnum {
  number = 'number',
  currency = 'currency',
  percent = 'percent',
  date = 'date',
  'date-time' = 'date-time',
  table = 'table',
  text = 'text',
  boolean = 'boolean',
}

export const FORMATS_SYMBOLS_AND_LABELS: Record<FormatsEnum, { symbol: string; label: string }> = {
  [FormatsEnum.percent]: { symbol: '%', label: 'percent' },
  [FormatsEnum.currency]: { symbol: '$', label: 'currency' },
  [FormatsEnum.number]: { symbol: '1', label: 'number' },
  [FormatsEnum.date]: { symbol: '22', label: 'date' },
  [FormatsEnum['date-time']]: { symbol: 'AM', label: 'date-time' },
  [FormatsEnum.text]: { symbol: 'Aa', label: 'text' },
  [FormatsEnum.table]: { symbol: '[]', label: 'table' },
  [FormatsEnum.boolean]: { symbol: '✓', label: 'boolean' },
};
