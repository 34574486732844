import { css, type Theme } from '@emotion/react';

export const overwritten = (theme: Theme) => css`
  color: ${theme.ds.colors.secondary[500]};
`;

export const forecasted = (theme: Theme) => css`
  background-color: ${theme.ds.hues.blue[50]};
`;

export const actionIcon = (active?: boolean) => css`
  visibility: ${active ? 'inherit' : 'hidden'};
`;
