export const COMPANIES_ACTIONS = {
  START: 'CURRENCIES/START',
  ERROR: 'CURRENCIES/ERROR',

  SET_CURRENCY_RATE: 'CURRENCIES/SET_CURRENCY_RATE',

  SET_CURRENCIES_WITH_COMPANY_CURRENCIES: 'CURRENCIES/SET_CURRENCIES_WITH_COMPANY_CURRENCIES',

  COMPANY_CURRENCY_CREATE: 'CURRENCIES/COMPANY_CURRENCY_CREATE',
  COMPANY_CURRENCY_UPDATE: 'CURRENCIES/COMPANY_CURRENCY_UPDATE',
  COMPANY_CURRENCY_DELETE: 'CURRENCIES/COMPANY_CURRENCY_DELETE',
} as const;
