import { useTheme } from '@emotion/react';
import { IconCalendar } from '@tabler/icons-react';
import clsx from 'clsx';
import { type ComponentPropsWithoutRef, memo, forwardRef, type ForwardedRef, type ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { type MergeAll } from '@amal-ia/ext/typescript';
import { TypographyVariant } from '@amal-ia/frontend/design-system/meta';

import { Typography } from '../../../../../general/typography/Typography';
import { UnstyledButton } from '../../../../../general/unstyled-button/UnstyledButton';
import { TableDataCellContent } from '../../../layout/table-data-cell-content/TableDataCellContent';

import * as styles from './CellDatePickerAnchor.styles';

export type CellDatePickerAnchorProps = MergeAll<
  [
    ComponentPropsWithoutRef<'button'>,
    {
      isCalendarOpen?: boolean;
      value?: string;
      placeholder?: ReactNode;
    },
  ]
>;

const CellDatePickerAnchorForwardRef = forwardRef(function CellDatePickerAnchor(
  { value, isCalendarOpen, placeholder, ...props }: CellDatePickerAnchorProps,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  const theme = useTheme();

  return (
    <UnstyledButton
      {...props}
      ref={ref}
      className={clsx(props.className, { isCalendarOpen })}
      css={styles.cellDatePickerAnchor}
    >
      <TableDataCellContent css={styles.cellDatePickerContent}>
        <Typography
          css={!value && styles.placeholder}
          variant={TypographyVariant.BODY_BASE_REGULAR}
        >
          {value || placeholder || <FormattedMessage defaultMessage="Pick date" />}
        </Typography>

        <IconCalendar
          color={props.disabled ? theme.ds.colors.gray[300] : 'currentColor'}
          css={styles.icon}
          size={19.2}
        />
      </TableDataCellContent>
    </UnstyledButton>
  );
});

export const CellDatePickerAnchor = memo(CellDatePickerAnchorForwardRef);
