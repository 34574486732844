import { css, type Theme } from '@emotion/react';

import { CheckboxIconSize } from './CheckboxIcon.types';

export const DISABLED_CLASSNAME = 'disabled';
export const CHECKED_CLASSNAME = 'checked';
export const INDETERMINATE_CLASSNAME = 'indeterminate';

export const checkboxIcon = (theme: Theme) => css`
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  flex: none;

  transition: ${theme.ds.transitions.default(
    'background-color',
    'border-color',
    'box-shadow',
    'width',
    'height',
    'border-radius',
  )};

  border: 2px solid ${theme.ds.colors.gray[400]};

  &.${DISABLED_CLASSNAME} {
    cursor: not-allowed;
    border-color: ${theme.ds.colors.gray[100]};

    &.${CHECKED_CLASSNAME}, &.${INDETERMINATE_CLASSNAME} {
      border-color: ${theme.ds.colors.gray[300]};
      background-color: ${theme.ds.colors.gray[300]};
    }
  }

  &:not(.${DISABLED_CLASSNAME}) {
    &.${INDETERMINATE_CLASSNAME}, &.${CHECKED_CLASSNAME} {
      border-color: ${theme.ds.colors.secondary[400]};
      background-color: ${theme.ds.colors.secondary[400]};
    }

    /* There is no way to determine if focus was done via keyboard so we have to keep it in CSS. */
    input:focus-visible ~ & {
      border-color: ${theme.ds.colors.secondary[200]};

      &.${INDETERMINATE_CLASSNAME}, &.${CHECKED_CLASSNAME} {
        border-width: 1px;
      }

      &.${CheckboxIconSize.SMALL} {
        box-shadow: ${theme.ds.shadows.focusSmall(theme.ds.colors.secondary[50])};
      }

      &.${CheckboxIconSize.MEDIUM} {
        box-shadow: ${theme.ds.shadows.focusMedium(theme.ds.colors.secondary[50])};
      }

      &.${CheckboxIconSize.LARGE} {
        box-shadow: ${theme.ds.shadows.focusLarge(theme.ds.colors.secondary[50])};
      }
    }
  }

  &.${CheckboxIconSize.SMALL} {
    width: 16px;
    height: 16px;

    border-radius: 3px;
  }

  &.${CheckboxIconSize.MEDIUM} {
    width: 18px;
    height: 18px;

    border-radius: 3.375px;
  }

  &.${CheckboxIconSize.LARGE} {
    width: 20px;
    height: 20px;

    border-radius: 3.75px;
  }
`;
