import { type ForwardedRef, forwardRef } from 'react';

import { type FormikFieldProps, useFormikFieldAdapter } from '@amal-ia/ext/formik';

import { Switch, type SwitchProps } from '../Switch';

export type FormikSwitchProps = FormikFieldProps<SwitchProps>;

const FormikSwitchBase = forwardRef(function FormikSwitch(
  {
    validate, // Omit validate and any props not passed to Switch.
    ...props
  }: FormikSwitchProps,
  ref: ForwardedRef<HTMLInputElement>,
) {
  const formikFieldProps = useFormikFieldAdapter<SwitchProps['value'], 'checkbox'>({
    ...props,
    validate,
    type: 'checkbox',
  });

  return (
    <Switch
      {...props}
      {...formikFieldProps}
      ref={ref}
    />
  );
});

export const FormikSwitch = Object.assign(FormikSwitchBase, {
  Size: Switch.Size,
});
