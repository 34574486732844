import { type TablerIconsProps } from '@tabler/icons-react';
import { type ElementType, memo } from 'react';

import { type MergeAll } from '@amal-ia/ext/typescript';

import * as styles from './IconOverlap.styles';

export type IconOverlapProps = MergeAll<
  [
    TablerIconsProps,
    {
      /** Icon on the background that'll be overlaped. */
      iconBackground: ElementType<TablerIconsProps>;
      /** Icon on the foreground. */
      iconForeground: ElementType<TablerIconsProps>;
    },
  ]
>;

const DEFAULT_ICON_SIZE = 24;

export const IconOverlap = memo(function IconOverlap({
  iconBackground: IconBackground,
  iconForeground: IconForeground,
  ...props
}: IconOverlapProps) {
  return (
    <div css={styles.overlapContainer(props.size || props.width || DEFAULT_ICON_SIZE)}>
      <IconBackground {...props} />
      <IconForeground {...props} />
    </div>
  );
});
