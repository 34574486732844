import { defineMessages } from 'react-intl';

export const AuthorizationProtectorMessages = defineMessages({
  MAINTENANCE: {
    defaultMessage:
      'Your app is currently under maintenance, come back later to access your account. Follow our <link>status page</link> for more information.',
  },

  PLANNED_MAINTENANCE_DATES: {
    defaultMessage: 'Planned maintenance from around {startDate} to around {endDate}.',
  },

  PLANNED_MAINTENANCE_FROM_DATE: {
    defaultMessage: 'Planned maintenance from around {startDate}.',
  },

  PLANNED_MAINTENANCE_UNTIL_DATE: {
    defaultMessage: 'Planned maintenance until around {endDate}.',
  },
} as const);
