import { memo } from 'react';

import { FieldValuePrettyFormat, getPrettyFormatProps, LabelVariant } from '@amal-ia/data-capture/fields/components';
import { type Property } from '@amal-ia/data-capture/fields/types';
import { Table } from '@amal-ia/frontend/design-system/components';
import { type CustomObject, type Overwrite } from '@amal-ia/lib-types';

import { TableCellActions } from './TableCellActions';

type TableCellProps = Readonly<{
  columnName: string;
  overwrite?: Overwrite;
  property: Property;
  row: CustomObject;
  rowIndex: number;
}>;

export const TableCell = memo(function TableCell({ columnName, overwrite, property, row, rowIndex }: TableCellProps) {
  const { format, ref: valueRef } = property;

  const { content } = row;

  const columnValue = content[columnName];

  const variant = overwrite ? LabelVariant.ACCENTUATED : LabelVariant.DEFAULT;

  return (
    <Table.Cell.WithActions
      actions={
        <TableCellActions
          columnName={columnName}
          property={property}
          row={row}
          rowIndex={rowIndex}
        />
      }
    >
      <FieldValuePrettyFormat
        {...getPrettyFormatProps(format, columnValue)}
        valueRef={valueRef}
        variant={variant}
      />
    </Table.Cell.WithActions>
  );
});
