import { useAuth0 } from '@auth0/auth0-react';
import { memo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useAsyncEffect from 'use-async-effect';

import { toError } from '@amal-ia/ext/typescript';
import { AuthenticationError } from '@amal-ia/frontend/connected-components/auth';
import { useLoadingScreen } from '@amal-ia/lib-ui';

/**
 * Init Auth0 authentication on a given connection. This is used for SSO as URL for Initiate SignIn flow.
 */
export const InitiatedSSOAuth = memo(function InitiatedSSOAuth() {
  const { loginWithRedirect, isLoading } = useAuth0();
  const { hideLoading } = useLoadingScreen();
  const [error, setError] = useState<string>();
  const { search } = useLocation();

  useAsyncEffect(async () => {
    if (!isLoading) {
      const params = new URLSearchParams(search);
      const tenant = params.get('tenant');
      try {
        await loginWithRedirect({
          authorizationParams: {
            connection: tenant,
          },
        });
      } catch (e) {
        hideLoading();
        setError(toError(e).message);
      }
    }
  }, [isLoading, hideLoading, search, loginWithRedirect]);

  return <AuthenticationError message={error} />;
});
