/*
 * The code in this file is related to test suites.
 *
 * Because we can't use the Authorization Code Oauth2 workflow during Cypress tests
 * (because it would mean going out of the site and back, causing various issues), we
 * need another way to authenticate on our backend.
 *
 * This is made using the Password Grant workflow, before Cypress tests start. The app is then
 * loaded with the access token in the query params, like so : http://localhost:3000/?token=ey....
 *
 * We need to put this token into the local storage because we can't put the token into each Cypress
 * request, that would be too much work to maintain. So, when the app starts, we try to grab a token from
 * the local storage, then authenticate on the API using it. Logout in the test scenario would be to
 * simply clear that key.
 *
 * More information on Notion: https://www.notion.so/amaliaio/Auth0-bff8bdb60321440f951e9caa89ffc118
 */

import { log } from '@amal-ia/frontend/kernel/logger';

export const LOCAL_STORAGE_MOCK_TOKEN_KEY = 'test-token';

export const getMockedAccessToken = (token: string | null) => {
  // Try to fetch the token from the local storage.
  if (localStorage.getItem(LOCAL_STORAGE_MOCK_TOKEN_KEY) && !token) {
    const tokenFromStorage = localStorage.getItem(LOCAL_STORAGE_MOCK_TOKEN_KEY);
    log.info(`Connect user with access token ${tokenFromStorage}`);
    return tokenFromStorage;
  }
  // Else, try to find it in the route param because cypress would call the site with the token at test start.
  if (token) {
    // Put it into the local storage for the next call.
    log.info(`Setting token ${token}`);
    // Putting it into the local storage for next calls.
    localStorage.setItem(LOCAL_STORAGE_MOCK_TOKEN_KEY, token);
    return token;
  }

  return null;
};

export const mockedLogout = () => {
  // Clear the local storage to start fresh.
  localStorage.removeItem(LOCAL_STORAGE_MOCK_TOKEN_KEY);
};
