import { omit, pick } from 'lodash';

import { useUniqueId } from '@amal-ia/ext/react/hooks';

import { type FormFieldProps } from '../FormField';

// List of fields to extract from props using pick and omit.
const extractedPropNames = [
  'label',
  'error',
  'showError',
  'required',
  'tooltip',
  'labelPosition',
  'direction',
] as const;

type ExtractedPropNames = (typeof extractedPropNames)[number];
type ExtractedProps = Pick<FormFieldProps, ExtractedPropNames>;

// List of fields to share between FormField and current field.
const sharedPropNames = ['disabled'] as const;
type SharedPropNames = (typeof sharedPropNames)[number];
type SharedProps = Pick<FormFieldProps, SharedPropNames>;

// Props passed to useFormFieldProps.
export type UseFormFieldPropsOptions = ExtractedProps & SharedProps & { id?: FormFieldProps['id'] };

export type UseFormFieldPropsValue<TProps extends ExtractedProps & SharedProps> = {
  formFieldProps: Pick<TProps, ExtractedPropNames> & Pick<TProps, SharedPropNames> & { id: FormFieldProps['id'] };
  otherProps: Omit<TProps, ExtractedPropNames> & Pick<TProps, SharedPropNames> & { id: FormFieldProps['id'] };
};

/**
 * Split your component props with props to pass to Field.
 *
 * @param props - Your component props.
 * @returns Tuple with [Field props, your component props].
 */
export const useFormFieldProps = <TProps extends UseFormFieldPropsOptions>(
  props: TProps,
): UseFormFieldPropsValue<TProps> => {
  const id = useUniqueId({ id: props.id, prefix: 'field' });

  return {
    formFieldProps: {
      ...pick(props, extractedPropNames),
      ...pick(props, sharedPropNames),
      id,
    },
    otherProps: {
      ...omit(props, extractedPropNames),
      ...pick(props, sharedPropNames),
      id,
    },
  };
};
