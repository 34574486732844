import { type ForwardedRef, memo, forwardRef, type ReactElement, useCallback } from 'react';

import { type MergeAll } from '@amal-ia/ext/typescript';

import { DatePickerBase, type DatePickerBaseProps } from '../date-picker-base/DatePickerBase';
import { type InputProps } from '../input/Input';
import { InputSize } from '../input/Input.types';
import { useFormFieldProps, type UseFormFieldPropsOptions } from '../meta/form-field/hooks/useFormFieldProps';

import { DatePickerInput } from './date-picker-input/DatePickerInput';
import { datePickerTestIds } from './DatePicker.testIds';
import { type DatePickerValueProps } from './DatePicker.types';
import { useMapDatePickerProps } from './hooks/useMapDatePickerProps';

export type DatePickerProps<TWithRange extends boolean | undefined = undefined> = MergeAll<
  [
    DatePickerBaseProps<TWithRange>,
    UseFormFieldPropsOptions,
    Pick<InputProps, 'size'>,
    DatePickerValueProps<TWithRange>,
  ]
>;

const DatePickerForwardRef = forwardRef(function DatePicker<TWithRange extends boolean | undefined = undefined>(
  { size, ...props }: DatePickerProps<TWithRange>,
  ref: ForwardedRef<HTMLInputElement>,
) {
  const {
    formFieldProps,
    otherProps: { value, placeholder, endDate, startDate, onChange, selectsRange, ...otherProps },
  } = useFormFieldProps(props);

  const onClear = useCallback(() => {
    onChange((selectsRange ? [null, null] : null) as Parameters<typeof onChange>[0], undefined);
  }, [onChange, selectsRange]);

  const mappedDatePickerProps = useMapDatePickerProps({
    value,
    placeholder,
    endDate,
    startDate,
  });

  return (
    <DatePickerBase<TWithRange>
      {...otherProps}
      {...mappedDatePickerProps}
      required={formFieldProps.required} // ReactDatePicker overrides Input's required prop so we need to pass it here.
      selectsRange={selectsRange}
      customInput={
        <DatePickerInput
          {...formFieldProps}
          data-testid={datePickerTestIds.input(formFieldProps.id)}
          innerRef={ref}
          selectsRange={selectsRange}
          size={size}
          onClear={onClear}
        />
      }
      onChange={onChange}
    />
  );
});

export const DatePicker = Object.assign(
  memo(DatePickerForwardRef) as <TWithRange extends boolean | undefined = undefined>(
    props: DatePickerProps<TWithRange> & { ref?: ForwardedRef<HTMLInputElement> },
  ) => ReactElement | null,
  {
    Size: InputSize,
  },
);
