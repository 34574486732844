import { keyBy } from 'lodash';
import { createSelector } from 'reselect';

import { type Flow, type RootState } from '@amal-ia/lib-types';

export const selectFlows = (state: RootState): Flow[] => state.flows.flows;

export const selectFlowsMapById = createSelector([selectFlows], (flowList) => keyBy(flowList, 'id'));

export const selectFlowsIsLoading = (state: RootState): boolean => !!state.flows.isLoading;
