import { type CurrencyValue } from '@amal-ia/kernel/monetary/types';

export const alphabeticalSorter = (a: number | string, b: number | string): number => {
  // We compute the type of both items. If it's null, we parse it to a string
  const typeOfA = a ? (['string', 'number'].includes(typeof a) ? typeof a : 'string') : 'string';
  const typeOfB = b ? (['string', 'number'].includes(typeof b) ? typeof b : 'string') : 'string';

  // Here, if value is null, put ''. If it's a string, its value is transformed to lowercase, otherwise it's taken as it
  const valueOfA = typeOfA === 'string' ? ((a || '') as string).toLowerCase() : a;
  const valueOfB = typeOfB === 'string' ? ((b || '') as string).toLowerCase() : b;

  // If both values are number, compare them naturally
  if (typeOfA === 'number' && typeOfB === 'number') {
    return (valueOfA as number) - (valueOfB as number);
  }

  // If at least one of them is not, convert both to string and use a string comparator
  return valueOfA.toString().localeCompare(valueOfB.toString());
};

export const alphabeticalSorterWithAccessor = (accessor: string) => (a: object, b: object) => {
  if (!a?.[accessor] || !b?.[accessor]) {
    return 0;
  }

  return alphabeticalSorter(a[accessor], b[accessor]);
};

export const currencySorter = (a: CurrencyValue, b: CurrencyValue) => {
  // If we are on the same currency we can sort the values.
  if (a.symbol === b.symbol) {
    return a.value - b.value;
  }

  // otherwise we sort by symbol to group same currencies.
  return a.symbol.localeCompare(b.symbol);
};
