import { css } from '@emotion/react';
import { type ReactNode, memo } from 'react';

import { type MergeAll } from '@amal-ia/ext/typescript';
import { Modal, type ModalProps } from '@amal-ia/frontend/design-system/components';

import { Spinner } from './loaders/Spinner/Spinner';
import { SpinnerBoundary } from './loaders/Spinner/SpinnerBoundary';

export type LoadingModalProps = MergeAll<
  [
    Omit<ModalProps, 'children'>,
    {
      title?: ReactNode;
    },
  ]
>;

export const LoadingModal = memo(function LoadingModal({ title, ...props }: LoadingModalProps) {
  return (
    <Modal {...props}>
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div
            css={css`
              height: 40px;
            `}
          >
            <SpinnerBoundary>
              <Spinner />
            </SpinnerBoundary>
          </div>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
});
