export enum ButtonVariant {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  SECONDARY_LIGHT = 'SECONDARY_LIGHT',
  SECONDARY_TEXT = 'SECONDARY_TEXT',
  LIGHT = 'LIGHT',
  LIGHT_TEXT = 'LIGHT_TEXT',
  DANGER = 'DANGER',
  SUCCESS = 'SUCCESS',
}

export enum ButtonSize {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}

export enum ButtonIconPosition {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}
